import { Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import MainCard from "ui-component/cards/MainCard";
import RecommendationList from "./List";
import EditRecommendations from "./Edit";
import langString from "utils/langString";

export type RecommendationType = {
  id: number;
  message: string;
  view_in_cv: boolean;
  view_in_profile: boolean;
  name: string;
  position: string;
  company: string;
  year: string;
};

type RecommendationsProps = {
  recommendations: RecommendationType[];
  userUpdate: () => void;
};

export default function Recommendations({
  recommendations,
  userUpdate,
}: RecommendationsProps) {
  const [enableEdit, setEnableEdit] = useState(false);

  return (
    <>
      <MainCard>
        <Grid container>
          <Grid item xs={9}>
            <Typography variant="h4" sx={{ marginBottom: "10px" }}>
              {langString("recommendations")}
            </Typography>
          </Grid>
          <Grid item xs={3} textAlign="right">
            {!enableEdit && (
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={() => setEnableEdit(true)}
              >
                {langString("edit")}
              </Button>
            )}
          </Grid>
        </Grid>
        {enableEdit ? (
          <EditRecommendations
            userUpdate={userUpdate}
            recommendations={recommendations}
          />
        ) : (
          <RecommendationList recommendations={recommendations} />
        )}
        <Grid container>
          <Grid item xs={12} textAlign="right">
            {enableEdit && (
              <Button
                size="small"
                color="success"
                variant="contained"
                onClick={() => setEnableEdit(false)}
              >
                {langString("done")}
              </Button>
            )}
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
}
