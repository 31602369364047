import { Grid, Paper, Typography } from "@mui/material";
import MainCard from "ui-component/cards/MainCard";
import langString from "utils/langString";

export type RecommendationType = {
  id: number;
  message: string;
  view_in_cv: boolean;
  view_in_profile: boolean;
  name: string;
  position: string;
  company: string;
  year: string;
};

type RecommendationsProps = {
  recommendations: RecommendationType[];
};

export default function Recommendations({
  recommendations,
}: RecommendationsProps) {
  return (
    <>
      <MainCard>
        <Typography variant="h4" sx={{ marginBottom: "10px" }}>
          {langString("Recommendations")}
        </Typography>
        {recommendations.map((recommendation) => {
          return (
            recommendation.view_in_profile && (
              <Paper
                key={recommendation.id}
                sx={{ backgroundColor: "#eeeeee", margin: "10px 0px" }}
              >
                <Grid container xs={12} sx={{ padding: "10px" }}>
                  <Grid item xs={12} sm={9}>
                    <Typography variant="h5" className="textCap">
                      {recommendation.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Typography variant="body1" className="textCap">
                      {langString("name")}:  {recommendation.company}
                    </Typography>
                    <Typography variant="body1" className="textCap">
                      {langString("Position")}: {recommendation.position}
                    </Typography>
                    <Typography variant="body1" className="textCap">
                      {langString("Company")}: {recommendation.name}
                    </Typography>
                    <Typography variant="body1" className="textCap">
                      {langString("yearofestablishment")}: {recommendation.year}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            )
          );
        })}
      </MainCard>
    </>
  );
}
