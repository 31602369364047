export type getUrlForRedirectArgT =
  | "new-message"
  | "calendar-event"
  | "new-job-post"
  | "application-status"
  | "new-recommendation"
  | "job-deadline";

export default function getUrlForRedirect(
  type: getUrlForRedirectArgT,
  redirect_id: number
) {
  switch (type) {
    case "new-message":
      return `/messages/chat/?windowId=${redirect_id}`;

    case "calendar-event":
      return `/events/${redirect_id}`;

    case "new-job-post":
      return `/job-market/${redirect_id}`;

    case "application-status":
      return `job-market/${redirect_id}`;

    case "new-recommendation":
      return `/job-seeker/profile`;

    case "job-deadline":
      return `/employer/post-jobs/${redirect_id}`;

    default:
      return null;
  }
}

export const setNotificationTitle = (title: getUrlForRedirectArgT) => {
  switch (title) {
    case "new-message":
      return `message`;

    case "calendar-event":
      return `event`;

    case "new-job-post":
      return `new job post`;

    case "application-status":
      return `application status`;
    case "new-recommendation":
      return `new Recommendation`;

    case "job-deadline":
      return `job deadline`;

    default:
      return "";
  }
};
