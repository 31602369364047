import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

type SelectFieldProps = {
  name: string;
  defaultValue: string;
  handleChange: any;
  value: { value: string | number; name: string }[];
  labelName?: string;
};

const DisabledSelectField = ({
  name,
  value,
  defaultValue,
  handleChange,
  labelName
}: SelectFieldProps) => {
  return (
    <>
      <FormControl fullWidth>
        <InputLabel id={`${name}-select`} sx={{ m: -0.5 }}>
          {labelName}
        </InputLabel>
        <Select
          disabled
          size="small"
          labelId={`${name}-select`}
          id={name}
          name={name}
          defaultValue={defaultValue}
          onChange={handleChange}
          label={labelName}
        >
          {value.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {!item.value ? <em>{item.name}</em> : item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default DisabledSelectField;
