import dayjs from "dayjs";
import * as yup from "yup";

const validationSchema = yup.object({
  name: yup
    .string()
    .min(3, "Too Short")
    .max(50, "Too Long!")
    .required("Name is required"),
  dob: yup
    .date()
    .required("Date of birth is required")
    .test("dob", "Please choose a valid date of birth", (value) => {
      return dayjs().diff(dayjs(value), "years") >= 14;
    }),
  phone: yup
    .string()
    .required("Phone number is required")
    .matches(
      /(^([+]{1}[8]{2}|0088)?(01){1}[3-9]{1}\d{8})$/,
      "Phone number is not valid"
    ),
  division: yup.string().required(),
  district: yup.string().required(),
  thana: yup.string().required(),
  username: yup
    .string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Username is required"),

  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup
    .string()
    .min(6, "Password must be 6 characters at minimum")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export default validationSchema;
