import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ExperienceType } from "./index";
import langString from "utils/langString";

type ExperienceListPropsType = {
  experiences: ExperienceType[];
};

export default function ExperienceList({
  experiences,
}: ExperienceListPropsType) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              {langString("organization")} {langString("name")}
            </TableCell>
            <TableCell>{langString("position")}</TableCell>
            <TableCell>{langString("startdate")}</TableCell>
            <TableCell>{langString("enddate")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {experiences.map((row: ExperienceType) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.organization_name}
              </TableCell>
              <TableCell>{row.position}</TableCell>
              <TableCell>{row.start_date}</TableCell>
              <TableCell>{row.end_date}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
