import { Chip, Typography } from "@mui/material";
import MainCard from "ui-component/cards/MainCard";
import langString from "utils/langString";

export type SkillType = {
  id: number;
  name: string;
};
type SkillsPropsType = {
  title: string;
  technicalSkills: SkillType[];
  personalSkills: SkillType[];
};
export default function Skills({
  title,
  personalSkills,
  technicalSkills,
}: SkillsPropsType) {
  return (
    <>
      <MainCard>
        <Typography variant="h4" sx={{ marginBottom: "10px" }}>
          {title}
        </Typography>
        <Typography variant="body1" sx={{ margin: "10px 0px" }}>
          {langString("TechnicalSkill")}
        </Typography>
        {technicalSkills.length
          ? technicalSkills.map((skill) => (
              <Chip
                key={skill.id}
                label={skill.name}
                color="primary"
                className="textCap"
                sx={{ margin: "5px 10px" }}
              />
            ))
          : langString("NoSkillsAvailable")}
        <Typography variant="body1" sx={{ margin: "10px 0px" }}>
          {langString("PersonalSkill")}
        </Typography>
        {personalSkills.length
          ? personalSkills.map((skill) => (
              <Chip
                key={skill.id}
                label={skill.name}
                color="primary"
                className="textCap"
                sx={{ margin: "5px 10px" }}
              />
            ))
          : langString("NoSkillsAvailable")}
      </MainCard>
    </>
  );
}
