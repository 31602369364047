class Storage {
    set = (key: string, data: any) => {
        localStorage.setItem(key, JSON.stringify(data));
    }
    get = (key: string) => {
        let token = localStorage.getItem(key);
        if (token) {
            token = JSON.parse( token);
            return token;
        } else {
            return null;
        }

    }
    remove = (key: string) => localStorage.removeItem(key);
}

export default new Storage();