import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { Theme, ThemeProvider } from "@mui/material/styles";
// import GuestGuard from "utils/route-guard/GuestGuard";
// import MinimalLayout from "layout/MinimalLayout";
import MainLayout from "layout/MainLayout/";
import NavigationScroll from "layout/NavigationScroll";
import NavMotion from "layout/NavMotion";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "store/createAction/auth";
import { config } from "store/createAction/config";
// defaultTheme
import themes from "themes";
// routing
// import Routes from 'routes';
// store
import { DefaultRootStateProps } from "types";
// import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from "ui-component/extended/Snackbar";
// project imports
import Locales from "ui-component/Locales";
import getTokenFromFirebase from "utils/getTokenFromFirebase";
import storage from "utils/storage";




// import firebase from "firebase";
// import { JWTProvider } from 'contexts/JWTContext';
// import { Auth0Provider } from 'contexts/Auth0Context';

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// App props type

type appProps = {
  children: JSX.Element;
};

// ==============================|| APP ||============================== //

const App = ({ children }: appProps) => {
  const dispatch = useDispatch();

  const customization = useSelector(
    (state: DefaultRootStateProps) => state.customization
  );

  useEffect(() => {
    dispatch(config());
    getTokenFromFirebase();
    const token = storage.get("token");
    if (token) {
      dispatch(loadUser());
    }
  }, [dispatch]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        {/* RTL layout */}
        {/* <RTLLayout> */}
        <Locales>
          <NavigationScroll>
            <>
              {/* <Routes /> */}
              <NavMotion>
                <MainLayout>{children}</MainLayout>
              </NavMotion>
              <Snackbar />
            </>
          </NavigationScroll>
        </Locales>
        {/* </RTLLayout> */}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
