import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PersonalDetails from "components/PersonalDetails";
import Recommendations from "components/Card/Recommendations";
import Skills from "components/Card/Skills";
import Table, { dataT } from "components/Table";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { personalDetail } from "../../EmployerSeeJobSeekerProfile/defaultData";
import coreAxios from "utils/axios";
import {
  formattingDataEducations,
  formattingDataExperience,
  formattingDataOtherCertifications,
  formattingDataRecommendations,
  formattingDataYesCertification,
} from "../../EmployerSeeJobSeekerProfile/formattingData";
import { useNavigate } from "react-router-dom";

import {
  personalDetailsT,
  recommendationsT,
} from "views/EmployerSeeJobSeekerProfile";
import langString from "utils/langString";

type skillT = {
  id: number;
  name: string;
};

export default function JobSeekerPublicProfile() {
  const [personalDetails, setPersonalDetails] = useState<personalDetailsT>(
    personalDetail
  );
  const [recommendations, setRecommendations] = useState<recommendationsT[]>(
    []
  );
  const [personalSkills, setPersonalSkills] = useState<skillT[]>([]);
  const [technicalSkills, setTechnicalSkills] = useState<skillT[]>([]);
  const [educationsRaw, setEducationsRaw] = useState<dataT[]>([]);
  const [yesCertificationRaw, setYesCertificationRaw] = useState<dataT[]>([]);
  const [otherCertificationsRaw, setOtherCertificationsRaw] = useState<dataT[]>(
    []
  );
  const [experienceRaw, setExperienceRaw] = useState<dataT[]>([]);
  const { user } = useSelector((status: RootState) => status.account);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      coreAxios
        .get(`/users/job-seekers/${user.id}/`)
        .then((res) => {
          const { job_seeker_profile } = res.data.data;
          // data formate
          const recommendations = formattingDataRecommendations(
            job_seeker_profile?.recommendations!
          );
          const educations = formattingDataEducations(
            job_seeker_profile?.degrees!
          );
          const yesCertification = formattingDataYesCertification(
            job_seeker_profile?.yes_courses!,
            job_seeker_profile?.yes_trainings!
          );
          const otherCertifications = formattingDataOtherCertifications(
            job_seeker_profile?.other_certifications!
          );
          const experience = formattingDataExperience(
            job_seeker_profile?.experiences!
          );
          // set state
          setPersonalDetails((prevStatus) => ({
            ...prevStatus,
            picture: job_seeker_profile?.picture!,
            name: job_seeker_profile?.name,
            gender: job_seeker_profile?.gender,
            age: job_seeker_profile.dob,
            phone: job_seeker_profile?.phone,
            email: job_seeker_profile?.email,
          }));
          setRecommendations(recommendations);
          setPersonalSkills(job_seeker_profile?.personal_skills!);
          setTechnicalSkills(job_seeker_profile?.technical_skills!);
          setEducationsRaw(educations.filter((item) => item.view_in_profile));
          setYesCertificationRaw(
            yesCertification.filter((item) => item.view_in_profile)
          );
          setOtherCertificationsRaw(
            otherCertifications.filter((item) => item.view_in_profile)
          );
          setExperienceRaw(experience.filter((item) => item.view_in_profile));
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [user]);

  // columns table
  const educationsColumns = React.useMemo(
    () => [
      {
        Header: langString("degree"),
        accessor: "degree",
      },
      {
        Header: langString("subject"),
        accessor: "subject",
      },
      {
        Header: langString("institute"),
        accessor: "institute",
      },
      {
        Header: langString("result"),
        accessor: "result",
      },
      {
        Header: langString("yearofcompletion"),
        accessor: "yearOfCompletion",
      },
    ],
    []
  );
  const yesCertificationColumns = React.useMemo(
    () => [
      {
        Header: langString("course") + " " + langString("name"),
        accessor: "courseName",
      },
      {
        Header: langString("program"),
        accessor: "program",
      },
      {
        Header: langString("date"),
        accessor: "date",
      },
    ],
    []
  );
  const otherCertificationsColumns = React.useMemo(
    () => [
      {
        Header: langString("course") + " " + langString("name"),
        accessor: "courseName",
      },
      {
        Header: langString("organization"),
        accessor: "organization",
      },
      {
        Header: langString("date"),
        accessor: "date",
      },
    ],
    []
  );
  const experienceColumns = React.useMemo(
    () => [
      {
        Header: langString("company"),
        accessor: "company",
      },
      {
        Header: langString("position"),
        accessor: "position",
      },
      {
        Header: langString("duration"),
        accessor: "duration",
      },
      {
        Header: langString("year"),
        accessor: "year",
      },
    ],
    []
  );

  // raw table
  const educationsData = React.useMemo(() => educationsRaw, [educationsRaw]);
  const yesCertificationData = React.useMemo(() => yesCertificationRaw, [
    yesCertificationRaw,
  ]);
  const otherCertificationsData = React.useMemo(() => otherCertificationsRaw, [
    otherCertificationsRaw,
  ]);
  const experienceData = React.useMemo(() => experienceRaw, [experienceRaw]);

  return (
    <>
      <Typography variant="h2" sx={{ marginBottom: 1 }}>
        {langString("publicprofile")}
      </Typography>
      <Grid container xs={12} spacing={3}>
        <Grid item xs={12}>
          <PersonalDetails personalDetails={personalDetails} />
        </Grid>
        <Grid item xs={12}>
          <Recommendations
            recommendations={recommendations.filter(
              (item) => item.view_in_profile
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Table
            columns={educationsColumns}
            data={educationsData}
            title={langString("education")}
          />
        </Grid>
        <Grid item xs={12}>
          <Skills
            title={langString("skills")}
            personalSkills={personalSkills}
            technicalSkills={technicalSkills}
          />
        </Grid>
        <Grid item xs={12}>
          <Table
            columns={yesCertificationColumns}
            data={yesCertificationData}
            title={langString("yescertifications")}
          />
        </Grid>
        <Grid item xs={12}>
          <Table
            columns={otherCertificationsColumns}
            data={otherCertificationsData}
            title={langString("othercertifications")}
          />
        </Grid>
        <Grid item xs={12}>
          <Table
            columns={experienceColumns}
            data={experienceData}
            title={langString("experience")}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: 2 }}>
        <Button
          color="info"
          variant="contained"
          onClick={() => navigate(`/job-seeker/profile`)}
        >
          {langString("backtoprofile")}
        </Button>
      </Grid>
    </>
  );
}
