import { Grid, Typography } from "@mui/material";
import dateFormat from "utils/dateFormate";
import MainCard from "ui-component/cards/MainCard";
import { makeStyles } from "@mui/styles";
import langString from "utils/langString";

type PropTypes = {
  job: any;
};

export default function JobDescriptionCard({ job }: PropTypes) {
  const classes = useStyle();
  return (
    <MainCard className={classes.p20}>
      <Typography variant="h4" gutterBottom>
        {langString("job")} {langString("description")}
      </Typography>
      <p>{job?.description}</p>
      <Typography variant="h4" gutterBottom>
        {langString("job")} {langString("category")}
      </Typography>
      <p>{job?.category.name}</p>
      <Typography variant="h4" gutterBottom>
        {langString("vacancy")}: {job?.vacancy || 0}
      </Typography>
      <Grid className={classes.dFlex}>
        <Typography variant="h4" gutterBottom>
          {langString("postedon")}: {dateFormat(job?.created_at || "")}
        </Typography>
        <Typography variant="h4" gutterBottom>
          {langString("deadline")}: {dateFormat(job?.deadline || "")}
        </Typography>
      </Grid>
    </MainCard>
  );
}

const useStyle = makeStyles({
  p20: {
    padding: "20px",
  },
  dFlex: {
    display: "flex",
    justifyContent: "space-between",
  },
  center: {
    height: "80px",
    textAlign: "center",
    marginTop: "80px",
  },
});
