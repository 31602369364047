import getIdInArrayOfObject from "utils/getIdInArrayOfObject";

type target_groupT = {
  is_yes_certified_required?: boolean;
  degrees?: any[];
  gender?: string;
  locations?: any;
  age_min?:any;
  age_max?: any;
  experience?: any;

}

const onSubmitDataFormat = (values: any) => {
    // const target_group = {
    //     is_yes_certified_required: values.is_yes_certified_required,
    //     gender: values.gender,
    //     age_max: values.age_max,
    //     age_min: values.age_min,
    //     experience: values.experience,
    //     locations: values.locations !== "" ? [values.locations] : [],
    //     degrees: values.degrees !== "" ? [values.degrees] : [],
    //   };

    const target_group : target_groupT= {};

    if(values.is_yes_certified_required){
      target_group.is_yes_certified_required = values.is_yes_certified_required;
    }

    if(values.is_degree){
      target_group.degrees = [values.degrees];
    }

    if(values.is_gender){
      target_group.gender = values.gender;
    }

    if(values.is_locations){
      target_group.locations = [values.locations];
    }

    if(values.is_age){
      target_group.age_min = values.age_min;
      target_group.age_max = values.age_max;
    }
    
    if(values.is_experience){
      target_group.experience = values.experience;
    }
  
    return {
        target_group,
        category: values.category,
        type: values.type,
        description: values.description,
        vacancy: values.vacancy,
        deadline: values.deadline,
        salary_range_min: values.salary_range_min,
        salary_range_max: values.salary_range_max,
        technical_skills: getIdInArrayOfObject(values.technical_skills),
        personal_skills: getIdInArrayOfObject(values.personal_skills),
        yes_trainings: getIdInArrayOfObject(values.yes_trainings),
        yes_courses: getIdInArrayOfObject(values.yes_courses),
      };
}

export default onSubmitDataFormat;