import { Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import MainCard from "ui-component/cards/MainCard";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AddExperience from "./AddExperience";
import EditExperience from "./Edit";
import ExperienceList from "./List";
import langString from "utils/langString";

export type ExperienceType = {
  id: number;
  organization_name: string;
  position: string;
  start_date: Date | string | null;
  end_date: Date | string | null;
  view_in_profile?: boolean;
  view_in_cv?: boolean;
};

type ExperiencesPropsType = {
  experiences: ExperienceType[];
  userUpdate: () => void;
};

export default function Experiences({
  experiences,
  userUpdate,
}: ExperiencesPropsType) {
  const [enableEdit, setEnableEdit] = useState(false);
  const [addForm, setAddForm] = useState(false);

  return (
    <>
      <MainCard>
        <Grid container>
          <Grid item xs={9}>
            <Typography variant="h4" sx={{ marginBottom: "10px" }}>
              {langString("experiences")}
            </Typography>
          </Grid>
          <Grid item xs={3} textAlign="right">
            {!enableEdit && (
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={() => setEnableEdit(true)}
              >
                {langString("edit")}
              </Button>
            )}
          </Grid>
        </Grid>
        {enableEdit ? (
          <EditExperience userUpdate={userUpdate} experiences={experiences} />
        ) : (
          <ExperienceList experiences={experiences} />
        )}
        {addForm && (
          <Grid container>
            <Grid item xs={12}>
              <AddExperience
                experiences={experiences}
                closeAddForm={() => setAddForm(false)}
                userUpdate={() => userUpdate()}
              />
            </Grid>
          </Grid>
        )}

        <Grid container>
          <Grid item xs={12} textAlign="right">
            {enableEdit && (
              <Button
                size="small"
                color="success"
                variant="contained"
                onClick={() => setEnableEdit(false)}
              >
                {langString("done")}
              </Button>
            )}
            {!addForm && !enableEdit && (
              <Button
                size="small"
                color="info"
                variant="outlined"
                onClick={() => setAddForm(true)}
              >
                <AddBoxIcon />
              </Button>
            )}
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
}
