import {
  Button, Grid,
  Typography
} from "@mui/material";
import { useState } from "react";
import MainCard from "ui-component/cards/MainCard";
import langString from "utils/langString";
import EditPersonalInfo from "./EditPersonalInfo";
import PersonalInfo, { PersonalInfoType } from "./PersonalInfo";

export type UserPersonalDetailsProps = {
  personalDetails: PersonalInfoType;
  editable?: boolean;
  badges: any[];
  userUpdate: () => void;
};

export default function UserPersonalDetails({
  personalDetails,
  editable = false,
  userUpdate,
  badges,
}: UserPersonalDetailsProps) {
  const [editMode, setEditMode] = useState(false);
  console.log(personalDetails)
  return (
    <>
      <MainCard>
        <Grid container>
          <Grid item xs={9}>
            <Grid container>
              <Grid item xs={9}>
                <Typography variant="h4" sx={{ marginBottom: "10px" }}>
                  {langString("personaldetails")}
                </Typography>
              </Grid>
              <Grid item xs={3} textAlign="right">
                {editable && !editMode && (
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={() => setEditMode(true)}
                    sx={{ marginRight: 10 }}
                  >
                    {langString("edit")}
                  </Button>
                )}
              </Grid>
            </Grid>
            <Grid container>
              {editMode && (
                <EditPersonalInfo
                  cancelEdit={() => setEditMode(false)}
                  userUpdate={() => {
                    userUpdate();
                    setEditMode(false);
                  }}
                  info={personalDetails}
                />
              )}
              {!editMode && <PersonalInfo info={personalDetails} />}
            </Grid>
          </Grid>
          {/* <Grid item xs={3}>
            <Typography variant="h4" sx={{ marginLeft: 5 }}>
              {langString("badges")}
            </Typography>
            <List>
              {badges && badges.length
                ? badges.map((badge: any) => (
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <img
                            width="30"
                            src={badge.badgeimage}
                            alt={badge.description}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={badge.name}
                          secondary={badge.description}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))
                : langString("nobadge")}
            </List>
          </Grid> */}
        </Grid>
      </MainCard>
    </>
  );
}
