import { Button } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "store";
import coreAxios from "utils/axios";
import dateFormate from "utils/dateFormate";
import langString from "utils/langString";
import FilterForm from "./FilterForm";
import Table from "./Table";


const PostedJobs = () => {
  const [postedJob, setPostedJob] = useState<any>(null);
  const { user } = useSelector((state: RootState) => state.account);
  // server request

  useEffect(() => {
    coreAxios
      .get(`/users/employers/${user?.id}/job-posts/`)
      .then((res) => {
        setPostedJob(res?.data?.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [user]);

  // data formatting
  const rows = postedJob?.results.map((job: any, index: number) => {
    const posting_date = dateFormate(job.created_at);
    const deadline = dateFormate(job.deadline);
    return createData(
      index + 1,
      job.id,
      job?.category?.name,
      posting_date,
      deadline,
      job.status,
      job.vacancy || 0,
      job.applications
    );
  });
  const data = useMemo(() => rows, [rows]);
  const columns = useMemo(
    () => [
      {
        Header: langString("sl"),
        accessor: "si",
      },
      {
        Header: langString("jobID"),
        accessor: "job_id",
      },
      {
        Header: langString("jobCategory"),
        accessor: "job_category",
      },
      {
        Header: langString("postingDate"),
        accessor: "posting_date",
      },
      {
        Header: langString("deadline"),
        accessor: "deadline",
      },
      {
        Header: langString("status"),
        accessor: "status",
      },
      {
        Header: langString("vacancy"),
        accessor: "vacancy",
      },
      {
        Header: langString("applications"),
        accessor: "applications",
      },
      {
        Header: langString("actions") ,
        accessor: "actions",
        Cell: ({cell:{row}}:any) => {
            return (
                <>
                  <Button component={Link} to={`/employer/post-jobs/${row?.values?.job_id}`} size="small">
                    View
                  </Button>
                </>
            );
        }
      }
    ],
    []
  );

  return (
    <>
      <FilterForm setPostedJob={setPostedJob} userId={user?.id}/>
      {rows ? <Table columns={columns} data={data} /> : "Loading..."}
    </>
  );
};

export default PostedJobs;

// table data
function createData(
  si: number,
  job_id: string,
  job_category: string,
  posting_date: string | number,
  deadline: string | number,
  status: string | number,
  vacancy: string | number,
  applications: string
) {
  return {
    si,
    job_id,
    job_category,
    posting_date,
    deadline,
    status,
    vacancy,
    applications,
  };
}
