// action - state management
export const CREATE_JOB = "CREATE_JOB";
export const CLEAR_JOB = "CLEAR_JOB";

type createJob = typeof CREATE_JOB;
type clearJob = typeof CLEAR_JOB;

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
  job: null,
};

export interface createJobReducerActionProps {
  type: createJob | clearJob;
  payload?: any;
}

const createJobReducer = (
  state = initialState,
  action: createJobReducerActionProps
) => {
  switch (action.type) {
    case CREATE_JOB: {
      const { job } = action.payload!;
      return {
        ...state,
        job,
      };
    }
    case CLEAR_JOB: {
      return {
        job: null,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default createJobReducer;
