import SelectField from "components/FormFields/SelectField";
import { Checkbox, Grid, TextField, Typography } from "@mui/material";
import MainCard from "ui-component/cards/MainCard";
import DisabledSelectField from "components/FormFields/DisabledSelectForm";
import useStyle from "./style";
import { useSelector } from "react-redux";
import { RootState } from "store";
import formateSelectFieldValues from "utils/formateSelectFieldValues";
import langString from "utils/langString";

const TargetGroup = ({ formik }: any) => {
  const { degreeType, location } = useSelector(
    (state: RootState) => state.config
  );

  const degreeTypes = formateSelectFieldValues("Degree Type All", degreeType!);
  let locations: any = location?.filter(
    (value: { geo_definition: { node_name: string } }) =>
      value.geo_definition.node_name === "thana"
  );
  locations = formateSelectFieldValues("Thana All", locations!);

  const classes = useStyle();

  return (
    <>
      <Grid item xs={12} sm={6}>
        <MainCard className={classes.h}>
          <Typography variant="h4" gutterBottom>
            {langString("TargetGroup")}
          </Typography>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              md={12}
              alignItems="center"
              sx={{ display: "flex" }}
            >
              <Checkbox
                name="is_yes_certified_required"
                checked={
                  formik.values.is_yes_certified_required
                    ? formik.values.is_yes_certified_required
                    : false
                }
                onChange={(e, v) => {
                  formik.handleChange(e);
                }}
              />
              {formik.values.is_yes_certified_required ? (
                <Typography variant="h4" gutterBottom>
                  {langString("YesCertified")}
                </Typography>
              ) : (
                <Typography variant="h4" gutterBottom color="gray">
                  {langString("YesCertified")}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              alignItems="center"
              sx={{ display: "flex" }}
            >
              <Checkbox
                name="is_degree_required"
                checked={
                  formik?.values?.is_degree_required
                    ? formik?.values?.is_degree_required
                    : false
                }
                onChange={(e, v) => {
                  formik.handleChange(e);
                  formik?.setFieldValue("degrees", "");
                }}
              />
              {formik?.values?.is_degree_required ? (
                <SelectField
                  labelName={langString("degrees")}
                  name="degrees"
                  defaultValue={formik.values.degrees}
                  handleChange={formik.handleChange}
                  menuItems={degreeTypes}
                />
              ) : (
                <DisabledSelectField
                  labelName={langString("degrees")}
                  name="degrees"
                  defaultValue={formik.values.degrees}
                  handleChange={formik.handleChange}
                  value={[
                    { value: "", name: "None" },
                    { value: "1", name: "Job1" },
                  ]}
                />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              alignItems="center"
              sx={{ display: "flex" }}
            >
              <Checkbox
                name="is_gender_required"
                checked={
                  formik?.values?.is_gender_required
                    ? formik?.values?.is_gender_required
                    : false
                }
                onChange={(e, v) => {
                  formik.handleChange(e);
                  formik?.setFieldValue("gender", "");
                }}
              />
              {formik?.values?.is_gender_required ? (
                <SelectField
                  labelName="Gender"
                  name="gender"
                  defaultValue={formik.values.gender}
                  handleChange={formik.handleChange}
                  menuItems={[
                    { value: "", name: "Select" },
                    { value: "male", name: langString("male") },
                    { value: "female", name: langString("female") },
                    { value: "other", name: langString("other") },
                  ]}
                />
              ) : (
                <DisabledSelectField
                  labelName="Gender"
                  name="gender"
                  defaultValue={formik.values.gender}
                  handleChange={formik.handleChange}
                  value={[
                    { value: "", name: "Select" },
                    { value: "male", name: "male" },
                    { value: "female", name: "female" },
                    { value: "other", name: "other" },
                  ]}
                />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              alignItems="center"
              sx={{ display: "flex" }}
            >
              <Checkbox
                name="is_location_required"
                checked={
                  formik?.values?.is_location_required
                    ? formik?.values?.is_location_required
                    : false
                }
                onChange={(e, v) => {
                  formik.handleChange(e);
                  formik?.setFieldValue("locations", "");
                }}
              />
              {formik?.values?.is_location_required ? (
                <SelectField
                  labelName={langString("locations")}
                  name="locations"
                  defaultValue={formik.values.locations}
                  handleChange={formik.handleChange}
                  menuItems={locations!}
                />
              ) : (
                <DisabledSelectField
                  labelName={langString("locations")}
                  name="locations"
                  defaultValue={formik.values.locations}
                  handleChange={formik.handleChange}
                  value={[
                    { value: "", name: "Select locations" },
                    { value: "1", name: "Job1" },
                  ]}
                />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              alignItems="center"
              sx={{ display: "flex" }}
            >
              <Checkbox
                name="is_age_required"
                checked={
                  formik?.values?.is_age_required
                    ? formik?.values?.is_age_required
                    : false
                }
                onChange={(e, v) => {
                  formik.handleChange(e);
                  formik?.setFieldValue("age_min", 0);
                  formik?.setFieldValue("age_max", 0);
                }}
              />
              {formik?.values?.is_age_required ? (
                <>
                  <TextField
                    type="number"
                    fullWidth
                    size="small"
                    id="outlined-basic"
                    label={langString("minAge")}
                    variant="outlined"
                    onChange={formik.handleChange}
                    name="age_min"
                    value={
                      formik?.values?.age_min === 0
                        ? null
                        : formik?.values?.age_min
                    }
                    InputLabelProps={{ shrink: true }}
                  />
                  <Typography
                    variant="caption"
                    gutterBottom
                    className={classes.mx}
                  >
                    To
                  </Typography>
                  <TextField
                    type="number"
                    fullWidth
                    size="small"
                    id="outlined-basic"
                    label={langString("maxAge")}
                    variant="outlined"
                    onChange={formik.handleChange}
                    name="age_max"
                    value={
                      formik?.values?.age_max === 0
                        ? null
                        : formik?.values?.age_max
                    }
                    InputLabelProps={{ shrink: true }}
                  />
                </>
              ) : (
                <>
                  <TextField
                    disabled
                    type="number"
                    fullWidth
                    size="small"
                    id="outlined-basic"
                    label={langString("minAge")}
                    variant="outlined"
                    onChange={formik.handleChange}
                    name="age_min"
                    value={formik?.values?.age_min}
                  />
                  <Typography
                    variant="caption"
                    gutterBottom
                    className={classes.mx}
                  >
                    To
                  </Typography>
                  <TextField
                    disabled
                    type="number"
                    fullWidth
                    size="small"
                    id="outlined-basic"
                    label={langString("maxAge")}
                    variant="outlined"
                    onChange={formik.handleChange}
                    name="age_max"
                    value={formik?.values?.age_max}
                  />
                </>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              alignItems="center"
              sx={{ display: "flex" }}
            >
              <Checkbox
                name="is_experience_required"
                checked={
                  formik?.values?.is_experience_required
                    ? formik?.values?.is_experience_required
                    : false
                }
                onChange={(e, v) => {
                  formik.handleChange(e);
                  formik?.setFieldValue("experience", 0);
                }}
              />
              {formik.values.is_experience_required ? (
                <TextField
                  type="number"
                  fullWidth
                  size="small"
                  id="outlined-basic"
                  label={langString("experience")}
                  variant="outlined"
                  onChange={formik.handleChange}
                  name="experience"
                  InputLabelProps={{ shrink: true }}
                  value={
                    formik?.values?.experience
                      ? formik?.values?.experience
                      : null
                  }
                  error={
                    formik.touched.experience &&
                    Boolean(formik.errors.experience)
                  }
                  helperText={
                    formik.touched.experience && formik.errors.experience
                  }
                />
              ) : (
                <TextField
                  disabled
                  type="number"
                  fullWidth
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  label={langString("experience")}
                  onChange={formik.handleChange}
                  name="experience"
                  value={formik?.values?.experience}
                />
              )}
            </Grid>
          </Grid>
        </MainCard>
      </Grid>
    </>
  );
};

export default TargetGroup;
