// material-ui
import { Grid, TextField } from "@mui/material";
// third-party
import { makeStyles } from "@mui/styles";
import SingleSelect from "components/FormFields/SingleSelect";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { gridSpacing } from "store/constant";
// project imports
import MainCard from "ui-component/cards/MainCard";
import langString from "utils/langString";


// ==============================|| FORM VALIDATION - LOGIN FORMIK  ||============================== //

type CompaniesFilterProps = {
  industry: number;
  location: string;
  search: string;
  setIndustry: (value: number) => void;
  setLocation: (value: string) => void;
  setSearch: (value: string) => void;
};

const CompaniesFilter = ({
  industry,
  location,
  search,
  setLocation,
  setIndustry,
  setSearch,
}: CompaniesFilterProps) => {
  const { industries, location: allLocations } = useSelector(
    (status: RootState) => status.config
  );

  const classes = useStyle();
  const [locations, setLocations] = useState<any[]>([]);
  useEffect(() => {
    const thanas: any[] = [];
    allLocations?.forEach((item: any) => {
      if (item.geo_definition.node_name === "thana") {
        thanas.push({ id: item.id, name: item.name });
      }
    });
    setLocations(thanas);
  }, [allLocations]);

  return (
    <MainCard className={classes.pt}>
      <Grid
        container
        spacing={gridSpacing}
        direction="row"
        alignItems="flex-end"
      >
        <Grid item xs={12}>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12} sm={4}>
              <SingleSelect
                name="industry"
                label={langString("industry")}
                handleChange={(value: any) => setIndustry(value)}
                values={industries ? industries : []}
                value={industry}
                valueKey="id"
                nameKey="name"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SingleSelect
                name="location"
                label={langString("location")}
                value={location}
                handleChange={(value: any) => setLocation(value)}
                values={locations}
                valueKey="id"
                nameKey="name"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="outlined-basic"
                size="small"
                label={langString("search")}
                variant="outlined"
                value={search}
                onChange={(event: any) => setSearch(event.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default CompaniesFilter;

const useStyle = makeStyles({
  pt: {
    paddingTop: "10px",
  },
  mt: {
    marginTop: "65px",
  },
  searchForm: {
    position: "absolute",
    top: "255px",
    right: "16px",
  },
});
