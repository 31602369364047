import { Dispatch } from "redux";
import coreAxios from "utils/axios";
import { getUrlForRedirectArgT } from "utils/getUrlForRedirect";

export const MESSAGING = "MESSAGING";
export const SET_MESSAGING = "SET_MESSAGING";
export const MORE_MESSAGING = "MORE_MESSAGING";
export const ALERT_MESSAGING = "ALERT_MESSAGING";
export const SEEN_ALERT_MESSAGING = "SEEN_ALERT_MESSAGING";
export const All_MESSAGING = "All_MESSAGING";

type messageT = typeof MESSAGING;
type setMessageT = typeof SET_MESSAGING;
type moreMessageT = typeof MORE_MESSAGING;
type alertMessageT = typeof ALERT_MESSAGING;
type seenAlertMessageT = typeof SEEN_ALERT_MESSAGING;
type allMessageT = typeof All_MESSAGING;

type notificationT = {
  id: number;
  type: getUrlForRedirectArgT;
  message: string;
  created_at: string;
  redirect_id: number;
  seen_time: string | null;
};

export type notificationPayloadI = {
  alert: boolean;
  count: number;
  next: string;
  results: notificationT[];
  show: boolean;
};

export interface GetNotificationsI {
  type: messageT;
  payload: notificationPayloadI;
}

export interface SetNotificationsI {
  type: setMessageT;
  payload: notificationT;
}
export interface MoreNotificationsI {
  type: moreMessageT;
  payload: notificationT[];
}
export interface AlertNotificationsI {
  type: alertMessageT;
  payload: boolean;
}
export interface AllNotificationsI {
  type: allMessageT;
  payload: notificationPayloadI;
}
export interface SeenNotificationsI {
  type: seenAlertMessageT;
}
type notificationReducerActionProps =
  | GetNotificationsI
  | SetNotificationsI
  | MoreNotificationsI
  | AlertNotificationsI
  | SeenNotificationsI
  | AllNotificationsI;

const initialState: notificationPayloadI = {
  alert: false,
  count: 0,
  next: "",
  results: [],
  show: false,
};

const notificationReducer = (
  state = initialState,
  action: notificationReducerActionProps
) => {
  switch (action.type) {
    case MESSAGING: {
      const { count, next, results } = action.payload;
      return {
        ...state,
        count: count,
        next: next,
        results: results,
        show: false,
      };
    }
    case SET_MESSAGING: {
      return {
        ...state,
        results: [action.payload, ...state.results],
        show: false,
      };
    }
    case MORE_MESSAGING: {
      return {
        ...state,
        results: [...state.results, ...action.payload],
        show: false,
      };
    }
    case ALERT_MESSAGING: {
      return {
        ...state,
        alert: action.payload,
        show: false,
      };
    }
    case All_MESSAGING: {
      const { show, results } = action.payload;
      return {
        ...state,
        results,
        show
      };
    }
    case SEEN_ALERT_MESSAGING: {
      return {
        ...state,
        alert: false,
        show: false,
      };
    }

    default: {
      return { ...state };
    }
  }
};

export const getNotifications = () => async (dispatch: Dispatch) => {
  const res = await coreAxios.get("/message-management/notifications/");
  if (res.status === 200) {
    const seenNotificationBadge = res.data.data.results.filter(
      (notification: notificationT) =>
        typeof notification.seen_time !== "string"
    );
    if (seenNotificationBadge.length !== 0) {
      dispatch({
        type: ALERT_MESSAGING,
        payload: true,
      });
    } else {
      dispatch({
        type: SEEN_ALERT_MESSAGING,
      });
    }

    dispatch({
      type: MESSAGING,
      payload: {
        next: res.data.data.next,
        results: res.data.data.results,
        count: res.data.data.count,
        alert: false,
      },
    });
  }
};

export const setNotification = (notification: notificationT) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_MESSAGING,
    payload: {
      id: notification.id,
      type: notification.type,
      message: notification.message,
      created_at: notification.created_at,
      redirect_id: notification.redirect_id,
    },
  });
};
export const moreNotification = (notifications: notificationT[]) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: MORE_MESSAGING,
    payload: notifications,
  });
};
export const allNotification = (notifications: notificationT[]) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: All_MESSAGING,
    payload: {
      results: notifications,
      show: true,
    },
  });
};

export const alertNotification = () => (dispatch: Dispatch) => {
  dispatch({
    type: ALERT_MESSAGING,
  });
};
export const seenAlertNotification = () => (dispatch: Dispatch) => {
  dispatch({
    type: SEEN_ALERT_MESSAGING,
  });
};

export default notificationReducer;
