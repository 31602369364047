import { ExperienceType } from "./index";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import coreAxios from "utils/axios";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Button } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useSelector } from "react-redux";
import { RootState } from "store";
import langString from "utils/langString";

type EditExperiencePropsType = {
  experiences: ExperienceType[];
  userUpdate: () => void;
};

export default function EditExperience({
  experiences,
  userUpdate,
}: EditExperiencePropsType) {
  const { user } = useSelector((status: RootState) => status.account);

  const updateExperience = (id: number, key: string) => {
    let allExperiences: ExperienceType[] = [...experiences];
    if (key === "view_in_cv") {
      allExperiences.forEach((item: ExperienceType, index: number) => {
        if (item.id === id) {
          allExperiences[index].view_in_cv = !allExperiences[index].view_in_cv;
        }
      });
    }
    if (key === "view_in_profile") {
      allExperiences.forEach((item: ExperienceType, index: number) => {
        if (item.id === id) {
          allExperiences[index].view_in_profile = !allExperiences[index]
            .view_in_profile;
        }
      });
    }
    const formData = new FormData();
    formData.append(
      "job_seeker_profile",
      JSON.stringify({ experiences: allExperiences })
    );
    coreAxios
      .patch(`/users/job-seekers/${user?.id}/`, formData)
      .then((res) => {
        if (res.status === 200) {
          userUpdate();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteExperience = (id: number) => {
    let allExperiences: ExperienceType[] = [...experiences];

    const filteredExperiences = allExperiences.filter(
      (item: ExperienceType) => item.id !== id
    );

    if (user) {
      const formData = new FormData();
      formData.append(
        "job_seeker_profile",
        JSON.stringify({ experiences: filteredExperiences })
      );
      coreAxios
        .patch(`/users/job-seekers/${user.id}/`, formData)
        .then((res) => {
          if (res.status === 200) {
            userUpdate();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              {langString("organization")} {langString("name")}
            </TableCell>
            <TableCell>{langString("position")}</TableCell>
            <TableCell>{langString("startdate")}</TableCell>
            <TableCell>{langString("enddate")}</TableCell>
            <TableCell align="right">{langString("update")}</TableCell>
            <TableCell align="right">{langString("delete")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {experiences.map((row: ExperienceType) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.organization_name}
              </TableCell>
              <TableCell>{row.position}</TableCell>
              <TableCell>{row.start_date}</TableCell>
              <TableCell>{row.end_date}</TableCell>
              <TableCell align="right">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={row.view_in_cv}
                        onChange={() => updateExperience(row.id, "view_in_cv")}
                      />
                    }
                    label={langString("viewincv")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={row.view_in_profile}
                        onChange={() =>
                          updateExperience(row.id, "view_in_profile")
                        }
                      />
                    }
                    label={langString("viewinprofile")}
                  />
                </FormGroup>
              </TableCell>
              <TableCell align="right">
                <Button
                  size="small"
                  color="error"
                  variant="outlined"
                  onClick={() => deleteExperience(row.id)}
                >
                  <DeleteForeverIcon />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
