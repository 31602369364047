import { Button, Grid, Stack, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { SNACKBAR_OPEN } from "store/actions";
import { gridSpacing } from "store/constant";
import AnimateButton from "ui-component/extended/AnimateButton";
import coreAxios from "utils/axios";
import langString from "utils/langString";
import BasicSkill from "./ BasicSkill";
import { dataFormat } from "./dataFormat";
import initialData from "./initialData";
import JobDescription from "./JobDescription";
import onUpdateDataFormat from "./onUpdateDataFormat";
import TargetGroup from "./TargetGroup";
import TrainingOrCourse from "./TrainingOrCourse";
import validationSchema from "./validationSchema";



const UpdateJob = () => {
  const [formValue, setFormValue] = useState(initialData);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { jobId } = useParams();

  const onSubmitHandle = async (values: any) => {
    const data = onUpdateDataFormat(values);
    try {
      const res = await coreAxios.patch(`/jobs/job-posts/${jobId}/`, data);
      if (res.status === 200) {
        navigate(`/employer/post-jobs/${jobId}/`);
        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: "Job Updated successful",
          variant: "alert",
          alertSeverity: "success",
        });
      }
    } catch (error: any) {
      if (error.response) {
        const message = JSON.parse(error.response.data.data).errorMessage;
        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message,
          variant: "alert",
          alertSeverity: "error",
        });
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...formValue },
    validationSchema: validationSchema,
    onSubmit: onSubmitHandle,
  });

  useEffect(() => {
    coreAxios
      .get(`/jobs/job-posts/${jobId}`)
      .then((res) => {
        if (res.data.status === 200) {
          dataFormat(res.data.data);
          const data = dataFormat(res.data.data);
          setFormValue((previousState) => ({
            ...previousState,
            ...data,
          }));
        }
      })
      .catch((e: any) => {
        console.log(e);
      });
  }, [jobId]);

  const onDeleteHandle = async () => {
    const res = await coreAxios.delete(`/jobs/job-posts/${jobId}/`);
    if (res.status === 200) {
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: "Delete successful",
        variant: "alert",
        alertSeverity: "error",
      });
      navigate(`/employer/post-jobs/`);
    }
  };

  return (
    <>
      <Typography variant="h3" gutterBottom>
        {langString("UPDATEJOB")}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={gridSpacing}>
          <JobDescription formik={formik} />
          <TargetGroup formik={formik} />
          <BasicSkill formik={formik} />
          <TrainingOrCourse formik={formik} />
          <Grid container item xs={12} justifyContent="center" spacing={3}>
            <Grid item xs={4} sm={2} md={2}>
              <Stack>
                <AnimateButton>
                  <Button
                    variant="contained"
                    color="error"
                    type="button"
                    onClick={onDeleteHandle}
                  >
                    {langString("delete")}
                  </Button>
                </AnimateButton>
              </Stack>
            </Grid>
            <Grid item xs={4} sm={2} md={2}>
              <Stack>
                <AnimateButton>
                  <Button variant="contained" type="submit">
                    {langString("Update")}
                  </Button>
                </AnimateButton>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default UpdateJob;
