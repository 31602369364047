import { Button, Grid, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CardLanding from "components/Card";
import { useNavigate } from "react-router-dom";
import AnimateButton from "ui-component/extended/AnimateButton";
import langString from "utils/langString";
import Footer from "../Landing/Footer";
import Header from "../Landing/Header";

const SignUp = () => {
  const navigate = useNavigate();
  const classes = useStyle();
  return (
    <>
      <Header />
      <>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={4}>
            <CardLanding
              title={langString(
                "ifyoulookingforajobyoushouldclickbelowandsignupasajob-Seeker"
              )}
            >
              <Stack className={classes.mt}>
                <AnimateButton>
                  <Button
                    variant="contained"
                    onClick={() => navigate("/sign-up/job-seeker")}
                  >
                    {langString("clickheretosignupasjob-seeker")}
                  </Button>
                </AnimateButton>
              </Stack>
            </CardLanding>
          </Grid>
          <Grid item xs={12} sm={4}>
            <CardLanding
              title={langString(
                "ifyoulookingforemployeesyoushouldclickbelowandsignupasaemployer"
              )}
            >
              <Stack className={classes.mt}>
                <AnimateButton>
                  <Button
                    variant="contained"
                    onClick={() => navigate("/sign-up/employer")}
                  >
                    {langString("clickheretosignupasemployer")}
                  </Button>
                </AnimateButton>
              </Stack>
            </CardLanding>
          </Grid>
        </Grid>
      </>
      <Footer />
    </>
  );
};

export default SignUp;

const useStyle = makeStyles({
  mt: {
    marginTop: "76px",
  },
});
