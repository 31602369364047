const initialValues = {
    name: "",
    dob: "",
    email: "",
    phone: "",
    division: "",
    district: "",
    subDistrict: "",
    thana: "",
    picture: null,
    username: "",
    password: "",
    confirmPassword: "",
    address: 0,
  };

  export default initialValues;