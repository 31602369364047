import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import MainCard from "ui-component/cards/MainCard";
import langString from "utils/langString";
import useStyle from "./style";

const TrainingOrCourse = ({ formik }: any) => {
  const [yesCourse, setYesCourse] = useState<selectFieldValues[]>();
  const [yesTraining, setYesTraining] = useState<selectFieldValues[]>();

  const { yesCourses, yesTrainings } = useSelector(
    (state: RootState) => state.config
  );

  const classes = useStyle();
  useEffect(() => {
    const yesCoursesList = values(yesCourses!);
    const yesTrainingsList = values(yesTrainings!);

    setYesCourse(yesCoursesList);
    setYesTraining(yesTrainingsList);
  }, [yesCourses, yesTrainings]);
  return (
    <>
      <Grid item xs={12} sm={6}>
        <MainCard className={classes.h}>
          <Typography variant="h4" gutterBottom>
            {langString("YESTraining/CourseRequirement")}
          </Typography>

          <Grid item>
            <Autocomplete
              multiple
              id="yes_trainings"
              size="small"
              options={yesTraining ? yesTraining : []}
              getOptionLabel={(option) => option.title}
              onChange={(e, values) =>
                formik?.setFieldValue("yes_trainings", values)
              }
              value={formik?.values?.yes_trainings as selectFieldValues[]}
              renderInput={(params) => (
                <TextField
                  sx={{ m: 1 }}
                  {...params}
                  variant="outlined"
                  label={langString("Trainings")}
                  placeholder={langString("Trainings")}
                  name="yes_trainings"
                />
              )}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              multiple
              id="yes_courses"
              size="small"
              options={yesCourse ? yesCourse : []}
              getOptionLabel={(option) => option.title}
              onChange={(e, values) =>
                formik?.setFieldValue("yes_courses", values)
              }
              value={formik?.values?.yes_courses as selectFieldValues[]}
              renderInput={(params) => (
                <TextField
                  sx={{ m: 1 }}
                  {...params}
                  variant="outlined"
                  label={langString("Courses")}
                  placeholder={langString("Courses")}
                  name="yes_courses"
                />
              )}
            />
          </Grid>
        </MainCard>
      </Grid>
    </>
  );
};

export default TrainingOrCourse;

type selectFieldValues = {
  id: number;
  title: string;
};

const values = (values: any[]): selectFieldValues[] => {
  return values?.map((item: any) => ({ id: item.id, title: item.name }));
};
