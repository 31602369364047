import { Grid, Paper, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import coreAxios from "utils/axios";
import langString from "utils/langString";
import { RecommendationType } from "./index";

type EditRecommPropsType = {
  recommendations: RecommendationType[];
  userUpdate: () => void;
};

export default function EditRecommendations({
  recommendations,
  userUpdate,
}: EditRecommPropsType) {
  const updateRecomendation = (id: number, key: string, value: boolean) => {
    let payload: any = null;
    if (key === "view_in_cv") {
      payload = { view_in_cv: value };
    }
    if (key === "view_in_profile") {
      payload = { view_in_profile: value };
    }
    coreAxios
      .patch(`/users/job-seekers/recommendations/${id}/`, payload)
      .then((res) => {
        if (res.status === 200) {
          userUpdate();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      {recommendations.map((recommendation) => (
        <Paper
          key={recommendation.id}
          sx={{ backgroundColor: "#eeeeee", margin: "10px 0px" }}
        >
          <Grid container xs={12} sx={{ padding: "10px" }}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h4" className="textCap">
                {recommendation.message}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" className="textCap">
                {langString("name")}: {recommendation.name}
              </Typography>
              <Typography variant="body1" className="textCap">
                {langString("position")}: {recommendation.position}
              </Typography>
              <Typography variant="body1" className="textCap">
                {langString("company")}: {recommendation.company}
              </Typography>
              <Typography variant="body1" className="textCap">
              {langString("yearofestablishment")}: {recommendation.year}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={recommendation.view_in_cv}
                      onChange={(event: any) =>
                        updateRecomendation(
                          recommendation.id,
                          "view_in_cv",
                          event.target.checked
                        )
                      }
                    />
                  }
                  label={langString("viewincv")}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={recommendation.view_in_profile}
                      onChange={(event: any) =>
                        updateRecomendation(
                          recommendation.id,
                          "view_in_profile",
                          event.target.checked
                        )
                      }
                    />
                  }
                  label={langString("viewinprofile")}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Paper>
      ))}
    </>
  );
}
