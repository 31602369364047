import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";

// project imports
import MainCard from "ui-component/cards/MainCard";
import { gridSpacing } from "store/constant";
import { checkNullData } from "utils/dataHelper";

// Props type
interface ColumnType {
  Header: string;
  accessor: string;
  content?: any;
}

type TableProps = {
  columns: ColumnType[];
  rows: any[];
  count: number;
  page: number;
  rowsPerPage: number;
  link?: string;
  linkID?: string;
  setPage: (value: number) => void;
  setRowsPerPage: (value: number) => void;
};

// ==============================|| TABLE - BASIC ||============================== //

export default function BasicDataTable({
  columns,
  rows,
  count,
  page,
  rowsPerPage,
  link,
  linkID,
  setPage,
  setRowsPerPage,
}: TableProps) {
  const navigate = useNavigate();
  const classes = useStyle();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderCell = (item: any, column: any) => {
    if (column.content) {
      return column.content(item);
    }
    return checkNullData(item[column.accessor]);
  };

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <MainCard content={false}>
          <TableContainer>
            <Table sx={{ minWidth: 350 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {columns.map((column: any) => (
                    <TableCell align="center">{column.Header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length ? (
                  rows.map((row: any, i: number) => (
                    <TableRow
                      className={classes.rowsHover}
                      onClick={() =>
                        linkID ? navigate(`${link + row[linkID]}/`) : ""
                      }
                    >
                      {columns.map((column: ColumnType, k: number) => (
                        <TableCell align="center" key={k}>
                          {renderCell(row, column)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow className={classes.rowsHover}>
                    <TableCell align="center" colSpan={columns.length}>
                      <h3>No Data Found</h3>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </MainCard>
      </Grid>
    </Grid>
  );
}

const useStyle = makeStyles({
  rowsHover: {
    cursor: "pointer",
    borderBottom: "10px solid white",
    backgroundColor: "#eeeeee",
    "&:hover": {
      backgroundColor: "#bdbdbd",
    },
  },
});
