import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import langString from "utils/langString";

type SingleSelectProps = {
  name: string;
  label: string;
  value?: string | number;
  handleChange: (value: any) => void;
  values: any[];
  valueKey: string;
  nameKey: string;
  required?: boolean;
};

const SingleSelect = ({
  name,
  label,
  values,
  value,
  handleChange,
  valueKey,
  nameKey,
  required=false
}: SingleSelectProps) => {
  return (
    <>
      <FormControl fullWidth>
        <InputLabel id={`${name}-select`} sx={{ m: -0.5 }}>
          {label}
        </InputLabel>
        <Select
          size="small"
          labelId={`${name}-select`}
          id={name}
          name={name}
          onChange={(event: any) => handleChange(event.target.value)}
          label={label}
          value={value}
          required
        >
          <MenuItem value="0">{langString('all')} {label}</MenuItem>
          {values.map((item: any) => (
            <MenuItem key={item[valueKey]} value={item[valueKey]}>
              {item[nameKey]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default SingleSelect;
