// material-ui
import { Button, Grid, Stack, TextField, Typography } from "@mui/material";
import SelectField from "components/FormFields/SelectField";
// third-party
import { useFormik } from "formik";
import { gridSpacing } from "store/constant";
// project imports
import MainCard from "ui-component/cards/MainCard";
import AnimateButton from "ui-component/extended/AnimateButton";
import coreAxios from "utils/axios";
import langString from "utils/langString";
import SearchForm from "./SearchForm";



// ==============================|| FORM VALIDATION - LOGIN FORMIK  ||============================== //

const AllJobSeekerFilter = ({ setJobSeekers, setIsFilter, setCount }: any) => {
  const formik = useFormik({
    initialValues: {
      age_from: "",
      age_to: "",
      gender: "",
      toggle: "",
    },
    onSubmit: async (values) => {
      try {
        const result = await coreAxios.get(
          `/users/job-seekers/?age_from=${values.age_from}&age_to=${values.age_to}&gender=${values.gender}&toggle=${values.toggle}`
        );
        if (result?.status === 200) {
          setCount(result?.data?.data.count)
          setJobSeekers(result?.data?.data.results);
        } else {
          setIsFilter(false);
        }
      } catch (e) {
        console.log(e);
      }
    },
  });

  return (
    <MainCard>
      <Grid container spacing={1} direction="row" alignItems="center">
        <Grid item xs={12} sm={8} alignItems="center">
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={gridSpacing} alignItems="center">
              <Grid item xs={5} sm={2}>
                <TextField
                  fullWidth
                  label={langString("minAge")}
                  size="small"
                  name="age_from"
                  type="text"
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={1} sm={1}>
                <Typography variant="body1" textAlign="center">
                  {langString("To")}
                </Typography>
              </Grid>
              <Grid item xs={5} sm={2}>
                <TextField
                  fullWidth
                  label={langString("maxAge")}
                  size="small"
                  name="age_to"
                  type="text"
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <SelectField
                  name="gender"
                  labelName={langString("gender")}
                  handleChange={formik.handleChange}
                  defaultValue={formik.values.gender}
                  menuItems={[
                    { value: "", name: "Select Gender" },
                    { value: "male", name: langString("male") },
                    { value: "female", name: langString("female") },
                    { value: "other", name: langString("other") },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <SelectField
                  name="toggle"
                  labelName={langString("YesCertified")}
                  handleChange={formik.handleChange}
                  defaultValue={formik.values.toggle}
                  menuItems={[
                    { value: "", name: langString("select") },
                    { value: "1", name: langString("YesCertified") },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Stack direction="column">
                  <AnimateButton>
                    <Button variant="contained" type="submit">
                      {langString("Filter")}
                    </Button>
                  </AnimateButton>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item xs={12} sm={4}>
          <SearchForm
            setStateFunc={setJobSeekers}
            pathName="/users/job-seekers/"
            setCount={setCount}
          />
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default AllJobSeekerFilter;
