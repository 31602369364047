import { Divider, Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import SubCard from "ui-component/cards/SubCard";
import langString from "utils/langString";

const UserInformation = ({
  userInDB,
  formik,
  checkUsernameInDB,
  id = "",
}: any) => {
  return (
    <SubCard
      title={langString("UserInformation")}
      sx={{ minHeight: { sm: "52vh", md: "39vh", lg: "31vh", xl: "18vh" } }}
    >
      <Grid container direction="column" spacing={1}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6} sm={4}>
              <Typography variant="subtitle1">
                {langString("username")}<Typography component="span" color="red"> *</Typography>:
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                disabled={userInDB.isUser}
                size="small"
                fullWidth
                id="username"
                type="text"
                name="username"
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={(event) => checkUsernameInDB(event.target.value)}
                error={
                  formik.touched.password && Boolean(formik.errors.username)
                }
                helperText={formik.touched.password && formik.errors.username}
              />
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ display: { xs: "block", sm: "none" } }}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Box>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6} sm={4}>
              <Typography variant="subtitle1">
                {langString("password")}<Typography component="span" color="red"> *</Typography>:
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                disabled={userInDB.isUser}
                size="small"
                fullWidth
                id="password"
                type="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ display: { xs: "block", sm: "none" } }}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Box>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6} sm={4}>
              <Typography variant="subtitle1">
                {langString("ConfirmPassword")}<Typography component="span" color="red"> *</Typography>:
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                disabled={userInDB.isUser}
                size="small"
                fullWidth
                id="confirmPassword"
                type="password"
                name="confirmPassword"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                error={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </SubCard>
  );
};

export default UserInformation;
