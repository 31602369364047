

const initialData = {
    description: "",
    category: "",
    vacancy: 0,
    type: "",
    salary_range_min: 0,
    salary_range_max: 0,
    deadline: "",
    is_yes_certified_required: false,
    gender: "",
    experience: 0,
    degrees: "",
    locations: 0,
    age_max: 0,
    age_min: 0,
    personal_skills: [],
    technical_skills: [],
    yes_trainings: [],
    yes_courses: [],
    is_age_required: false,
    is_degree_required: false,
    is_experience_required: false,
    is_gender_required: false,
    is_location_required: false,
  }
  export default initialData;