import { SNACKBAR_OPEN } from "store/actions";
import coreAxios from "utils/axios";

export const onSubmitHandle = async (
  event: React.FormEvent,
  jobApplied: any,
  setLocation: any,
  date: any,
  setDate: any,
  handleClose: any,
  navigate: any,
  dispatch: any,
  location: any
) => {
  event.preventDefault();
  try {
    const res = await coreAxios.patch(
      `/jobs/job-posts/applications/${jobApplied}/`,
      {
        application_status: "selected",
        interview_date: date,
        location: location,
      }
    );

    if (res.status === 200) {
      handleClose();
      navigate(-1);
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: "Selected successful",
        variant: "alert",
        alertSeverity: "success",
      });
    }
  } catch (error: any) {
    if (error.response) {
      handleClose();
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: error.response.data.data.detail,
        variant: "alert",
        alertSeverity: "error",
      });
    }
  }
};
