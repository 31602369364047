import { Avatar, Chip, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import { userT } from "types/employer";
import arrayOfObjectToArray from "utils/arrayOfObjectToArray";
import getAge from "utils/getAge";
import langString from "utils/langString";

type ListPropsT = {
  jobSeeker: userT;
};

export default function List({ jobSeeker }: ListPropsT) {
  const classes = useStyle();
  const { job_seeker_profile: job_seeker } = jobSeeker;

  return (
    <Box
      style={{
        textDecoration: "none",
      }}
      component={Link}
      to={`/employer/job-seekers/${jobSeeker?.id}`}
    >
      <Box
        boxShadow={2}
        style={{
          margin: "30px 0px",
          backgroundColor: "white",
          borderRadius: "10px",
        }}
        className={classes.boxHover}
      >
        <Grid container spacing={2} xs={12}>
          <Grid
            container
            item
            xs={12}
            spacing={3}
            justifyContent="space-between"
          >
            <Grid item xs={12} sm={2}>
              <Typography variant="h4" textAlign="center" className="textCap">
                {job_seeker?.name || "Job Seeker Name"}
              </Typography>
            </Grid>
            <Grid item xs={2} display={{ xs: "none", sm: "block" }}>
              <Typography variant="h4" textAlign="center">
                {langString("SpecialStatus")}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            container
            spacing={3}
            justifyContent={{ xs: "center" }}
          >
            <Grid item xs={12} sm={2}>
              <Avatar
                src={
                  job_seeker?.picture?.replace("media", "api/v1/media") || ""
                }
                alt="company img"
                sx={{
                  width: 60,
                  height: 60,
                  margin: "0px auto",
                  marginBottom: "20px",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} textAlign={{ xs: "center" }}>
              <Typography variant="body2" margin="2px 0px" className="textCap">
                {langString("gender")} - {job_seeker?.gender}
              </Typography>
              <Typography variant="body2" margin="2px 0px" className="textCap">
                {langString("age")} -{" "}
                {job_seeker?.dob && getAge(job_seeker?.dob) > 0
                  ? getAge(job_seeker?.dob) > 1
                    ? `${getAge(job_seeker?.dob)} Years`
                    : `${getAge(job_seeker?.dob)} Year`
                  : "none"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} textAlign={{ xs: "center" }}>
              <Typography
                variant="body2"
                margin="2px 0px"
                overflow="hidden"
                textOverflow="ellipsis"
                className="textCap"
              >
                {langString("training")} -{" "}
                {arrayOfObjectToArray(
                  job_seeker?.technical_skills!,
                  "name"
                ).join(", ") || "null"}
              </Typography>
              <Typography
                variant="body2"
                margin="2px 0px"
                overflow="hidden"
                textOverflow="ellipsis"
                className="textCap"
              >
                {langString("courses")} -{" "}
                {arrayOfObjectToArray(job_seeker?.yes_courses!, "name").join(
                  ", "
                ) || "null"}
              </Typography>
            </Grid>
            <Grid container item xs={12} sm={2} textAlign={{ xs: "center" }}>
              <Grid item xs={12} style={{ marginBottom: "5px" }}>
                {(job_seeker?.yes_courses! || job_seeker?.yes_trainings!) &&
                (job_seeker?.yes_courses?.length !== 0 ||
                  job_seeker?.yes_courses?.length !== 0) ? (
                  <Chip
                    label={"YES CERTIFIED"}
                    size="medium"
                    sx={{
                      backgroundColor: "red",
                      fontSize: 10,
                      fontWeight: "bold",
                      color: "white",
                      padding: "0px 5px",
                    }}
                  />
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

const useStyle = makeStyles({
  boxHover: {
    "&:hover": {
      boxShadow: "0px 2px 5px gray",
    },
  },
});
