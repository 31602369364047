import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { YesCertificationType } from "./index";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import coreAxios from "utils/axios";
import { checkNullData } from "utils/dataHelper";
import { useSelector } from "react-redux";
import { RootState } from "store";
import langString from "utils/langString";

type EditYesCertificationPropsType = {
  certifications: YesCertificationType[];
  userUpdate: () => void;
};

export default function EditYesCertifications({
  certifications,
  userUpdate,
}: EditYesCertificationPropsType) {
  const { user } = useSelector((status: RootState) => status.account);

  const updateYesCertification = (id: number, key: string) => {
    let allCertificates: YesCertificationType[] = [...certifications];
    let allItems: YesCertificationType[] = [];
    const certificate = allCertificates.find(
      (item: YesCertificationType) => item.id === id
    );
    allCertificates.forEach((item: YesCertificationType) => {
      if (item.program === certificate?.program) {
        if (item.id === certificate.id) {
          if (key === "view_in_cv") {
            certificate.view_in_cv = !certificate.view_in_cv;
            allItems.push(certificate);
          } else {
            certificate.view_in_profile = !certificate.view_in_profile;
            allItems.push(certificate);
          }
        } else {
          allItems.push(item);
        }
      }
    });

    if (user) {
      const formData = new FormData();
      if (certificate?.program === "YES Training") {
        formData.append(
          "job_seeker_profile",
          JSON.stringify({ yes_trainings: allItems })
        );
      } else {
        formData.append(
          "job_seeker_profile",
          JSON.stringify({ yes_courses: allItems })
        );
      }
      coreAxios
        .patch(`/users/job-seekers/${user.id}/`, formData)
        .then((res) => {
          if (res.status === 200) {
            userUpdate();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <>
      <>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{langString("name")}</TableCell>
                <TableCell>{langString("program")}</TableCell>
                <TableCell>
                  {langString("completion")} {langString("date")}
                </TableCell>
                <TableCell>{langString("action")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {certifications &&
                certifications.map((certificate) => (
                  <TableRow
                    key={certificate.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {certificate.name}
                    </TableCell>
                    <TableCell>{checkNullData(certificate.program)}</TableCell>
                    <TableCell>
                      {checkNullData(certificate.completion_date)}
                    </TableCell>
                    <TableCell>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={certificate.view_in_cv}
                              onChange={(event: any) =>
                                updateYesCertification(
                                  certificate.id,
                                  "view_in_cv"
                                )
                              }
                            />
                          }
                          label={langString("viewincv")}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={certificate.view_in_profile}
                              onChange={(event: any) =>
                                updateYesCertification(
                                  certificate.id,
                                  "view_in_profile"
                                )
                              }
                            />
                          }
                          label={langString("viewinprofile")}
                        />
                      </FormGroup>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    </>
  );
}
