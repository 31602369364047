import { CircularProgress } from "@mui/material";

const Loading = ({ position }: any) => {
  return (
    <>
      <div style={{ textAlign: position }}>
        <CircularProgress size={20} thickness={6} />
      </div>
    </>
  );
};

export default Loading;
