import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { EducationDataType } from "./index";
import langString from "utils/langString";

type EduListPropsType = {
  educations: EducationDataType[];
};

export default function EducationList({ educations }: EduListPropsType) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{langString("degree")}</TableCell>
            <TableCell>{langString("subject")}</TableCell>
            <TableCell>{langString("institute")}</TableCell>
            <TableCell>{langString("result")}</TableCell>
            <TableCell>{langString("yearofcompletion")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {educations.map((row) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.degree_type.name}
              </TableCell>
              <TableCell>{row.degree_group.name}</TableCell>
              <TableCell>{row.institute}</TableCell>
              <TableCell>{row.result}</TableCell>
              <TableCell>{row.year_of_completion}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
