import { UserProfileType } from "../../types/UserProfile";
import { useEffect, useState } from "react";
import RequirementsCard from "./RequirementsCard";
import getAge from "../../utils/getAge";
import langString from "utils/langString";

export type RequirementsType = {
  name: string;
  isMatch: boolean;
};
type TargetGroupCardPropsType = {
  targetGroup: any;
  profile?: UserProfileType | null;
};

const TargetGroupCard = ({
  targetGroup,
  profile,
}: TargetGroupCardPropsType) => {
  const [requirements, setRequirements] = useState<RequirementsType[]>([]);

  useEffect(() => {
    const targetGroups: RequirementsType[] = [];
    if (targetGroup && profile) {
      if (targetGroup.is_yes_certified_required) {
        targetGroups.push({
          name: "YES Certified",
          isMatch: true,
        });
      }
      if (targetGroup.is_gender_required && targetGroup.gender) {
        if (targetGroup.gender === profile.gender) {
          targetGroups.push({
            name: targetGroup.gender,
            isMatch: true,
          });
        } else {
          targetGroups.push({
            name: targetGroup.gender,
            isMatch: false,
          });
        }
      }
      if (
        targetGroup.is_age_required &&
        targetGroup.age_max &&
        targetGroup.age_min
      ) {
        const age = getAge(profile.dob ? profile.dob : "");
        if (targetGroup.age_min < age && targetGroup.age_max > age) {
          targetGroups.push({
            name: "Age: " + targetGroup.age_min + "~" + targetGroup.age_max,
            isMatch: true,
          });
        } else {
          targetGroups.push({
            name: "Age: " + targetGroup.age_min + "~" + targetGroup.age_max,
            isMatch: false,
          });
        }
      }
      if (targetGroup.is_location_required && targetGroup.locations) {
        targetGroup.locations.forEach((loc: any) => {
          targetGroups.push({
            name: loc.name,
            isMatch: profile.address === loc.id ? true : false,
          });
        });
      }
      if (targetGroup.is_experience_required && targetGroup.experience) {
        targetGroups.push({
          name: "Experience: " + targetGroup.experience + " Year",
          isMatch:
            profile.total_experience &&
            profile.total_experience >= targetGroup.experience
              ? true
              : false,
        });
      }
      if (targetGroup.degrees) {
        const profileDegrees = profile.degrees?.map(
          (degree: any) => degree.degree_type.id
        );
        targetGroup.degrees.forEach((item: any) => {
          if (profileDegrees?.includes(item.id)) {
            targetGroups.push({
              name: item.name,
              isMatch: true,
            });
          } else {
            targetGroups.push({
              name: item.name,
              isMatch: false,
            });
          }
        });
      }
    }
    setRequirements(targetGroups);
  }, [profile, targetGroup]);

  return (
    <RequirementsCard
      title={langString("targetgroup")}
      requirements={requirements}
    />
  );
};

export default TargetGroupCard;
