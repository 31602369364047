import { Alert, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import GppBadIcon from "@mui/icons-material/GppBad";
import MainCard from "ui-component/cards/MainCard";
import StarIcon from "@mui/icons-material/Star";
import langString from "utils/langString";

export type RequirementsType = {
  name: string;
  isMatch: boolean;
};
type RequirementsCardPropsType = {
  title: string;
  requirements: RequirementsType[];
  employer?: boolean;
};

const RequirementsCard = ({
  requirements,
  title,
  employer = false,
}: RequirementsCardPropsType) => {
  const classes = useStyle();
  const renderIcon = (matched: boolean) => {
    if (employer) {
      return <StarIcon fontSize="inherit" />;
    }
    if (matched) {
      return <CheckBoxIcon fontSize="inherit" />;
    } else {
      return <GppBadIcon fontSize="inherit" />;
    }
  };

  const severityType = (matched: boolean) => {
    if (employer) {
      return "info";
    }
    if (matched) {
      return "success";
    } else {
      return "error";
    }
  };
  return (
    <>
      <MainCard>
        <Typography variant="h3" className={classes.paragraph} gutterBottom>
          {title}
        </Typography>
        {requirements.length ? (
          requirements.map((item: RequirementsType) => (
            <Stack className={classes.stack} spacing={2}>
              <Alert
                icon={renderIcon(item.isMatch)}
                severity={severityType(item.isMatch)}
              >
                <strong>{item.name}</strong>
              </Alert>
            </Stack>
          ))
        ) : (
          <strong className={classes.paragraph}>
            {langString("notargetgroup")}
          </strong>
        )}
      </MainCard>
    </>
  );
};

export default RequirementsCard;

const useStyle = makeStyles({
  paragraph: {
    padding: "20px",
  },
  margin: {
    marginTop: "5px",
    marginBottom: "5px",
  },
  stack: { width: "100%", margin: 7, fontWeight: 600 },
});
