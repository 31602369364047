import App from "App";
// style + assets
import "assets/scss/style.scss";
import PrivateRoute from "components/PrivateRoute";
import config from "config";
import { lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
// third party
import { BrowserRouter, Route, Routes } from "react-router-dom";
// project imports
import * as serviceWorker from "serviceWorker";
import { store } from "store";
import Loadable from "ui-component/Loadable";
import ApplicantJobSeekerProfile from "views/ApplicantJobSeekerProfile";
import Calender from "views/Calender";
import Companies from "views/Companies";
import CompanyProfile from "views/Companies/Profile";
import CreateJob from "views/CreateJob";
import EmployerJobSeeker from "views/EmployerSeeJobSeeker";
import EmployerSeeJobSeekerProfile from "views/EmployerSeeJobSeekerProfile";
import Feedback from "views/Feedback";
import EmployerHome from "views/Home/Employer";
import JobSeekerHome from "views/Home/JobSeeker";
import JobMarket from "views/JobMarket";
import JobProfile from "views/JobMarket/JobProfile";
import Messaging from "views/Messaging";
import ChatMainPage from "views/Messaging/chat";
import PostedJobs from "views/PostedJobs";
import PostedJobDetails from "views/PostedJobs/JobDetails";
import UpdateJob from "views/PostedJobs/UpdateJob";
import EmployerProfile from "views/Profile/Employer";
import JobSeekerProfile from "views/Profile/JobSeeker";
import JobSeekerPublicProfile from "views/Profile/JobSeeker/PublicProfile";
import EmployersReports from "views/Reports/Employers";
import JobseekersReports from "views/Reports/Jobseekers";
import Employer from "views/SignUp/Employer";
import SignUp from "views/SignUp/index";
import JobSeeker from "views/SignUp/JobSeeker";

const Landing = Loadable(lazy(() => import("views/Landing")));
// ==============================|| REACT DOM RENDER  ||============================== //

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename={config.basename}>
      <App>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/job-market" element={<JobMarket />} />
          <Route path="/job-market/:jobId" element={<JobProfile />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/sign-up/job-seeker" element={<JobSeeker />} />
          <Route path="/sign-up/employer" element={<Employer />} />

          <Route path="/feedback" element={<Feedback />} />

          <Route
            path="/job-seeker/home"
            element={<PrivateRoute component={<JobSeekerHome />} />}
          />
          <Route path="/job-seeker/profile" element={<JobSeekerProfile />} />
          <Route
            path="/job-seeker/public-profile"
            element={<JobSeekerPublicProfile />}
          />
          <Route path="/job-seeker/companies" element={<Companies />} />
          <Route
            path="/job-seeker/company-profile/:id"
            element={<CompanyProfile />}
          />

          <Route path="/employer/create-job" element={<CreateJob />} />
          <Route
            path="/employer/home"
            element={<PrivateRoute component={<EmployerHome />} />}
          />
          <Route
            path="/employer/profile"
            element={<PrivateRoute component={<EmployerProfile />} />}
          />
          <Route
            path="/employer/post-jobs"
            element={<PrivateRoute component={<PostedJobs />} />}
          />
          <Route
            path="/employer/post-jobs/:jobId"
            element={<PrivateRoute component={<PostedJobDetails />} />}
          />
          <Route
            path="/employer/post-jobs/update/:jobId"
            element={<PrivateRoute component={<UpdateJob />} />}
          />
          <Route
            path="/employer/job-seekers"
            element={<PrivateRoute component={<EmployerJobSeeker />} />}
          />
          <Route
            path="/employer/job-seekers/:jobSeekerId"
            element={
              <PrivateRoute component={<EmployerSeeJobSeekerProfile />} />
            }
          />
          <Route
            path="/employer/applicant/:jobSeekerId"
            element={<PrivateRoute component={<ApplicantJobSeekerProfile />} />}
          />
          <Route
            path="/events"
            element={<PrivateRoute component={<Calender />} />}
          />
          <Route
            path="/events/:eventId"
            element={<PrivateRoute component={<Calender />} />}
          />
          <Route
            path="/messages"
            element={<PrivateRoute component={<Messaging />} />}
          />
          <Route
            path="/messages/chat"
            element={<PrivateRoute component={<ChatMainPage />} />}
          />
          <Route
            path="/reports/employers"
            element={<PrivateRoute component={<EmployersReports />} />}
          />
          <Route
            path="/reports/jobseekers"
            element={<PrivateRoute component={<JobseekersReports />} />}
          />
        </Routes>
      </App>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
