import firebase from "../coreFirebase";
import storage from "./storage";
export default function getTokenFromFirebase() {
  if (Notification.permission !== "denied") {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        const msg = firebase.messaging();
        msg
          .requestPermission()
          .then(() => {
            return msg.getToken();
          })
          .then((data) => {
            if (data) {
              storage.set("firebase-token", data);
            }
          });
      }
    });
  }
}
