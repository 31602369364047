function generateArrayOfYears(rangeOfYears: number) {
    var max = new Date().getFullYear()
    var min = max - rangeOfYears;
    var years = [];
  
    for (var i = max; i >= min; i--) {
      years.push({
        name: i,
        value: i
      });
    }
    return years;
  }

  export default generateArrayOfYears;