import {
  Avatar,
  Badge,
  Box,
  Button,
  ButtonBase,
  CardActions,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  Paper,
  Popper,
  Stack,
  Typography,
  useMediaQuery
} from "@mui/material";
// material-ui
import { useTheme } from "@mui/material/styles";
import { IconBell } from "@tabler/icons";
import Loading from "components/Loading";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
// third-party
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import {
  allNotification,
  getNotifications,
  moreNotification
} from "store/notificationReducer";
// project imports
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";
import coreAxios from "utils/axios";
import { getUrlForRedirectArgT } from "utils/getUrlForRedirect";
import NotificationList from "./NotificationList";




// type
export type notificationT = {
  count: number;
  next: string;
  results: {
    id: number;
    type: getUrlForRedirectArgT;
    message: string;
    created_at: string;
    redirect_id: number;
    seen_time: string | null;
  }[];
};
// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const notifications = useSelector((state: RootState) => state.notifications);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = React.useRef<any>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (
    event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent
  ) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  React.useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);

  const fetchMoreData = async () => {
    const res = await coreAxios.get(notifications.next);
    if (res.status === 200) {
      dispatch(moreNotification(res.data.data.results));
    }
  };
  const fetchAllData = async () => {
    const res = await coreAxios.get(
      "/message-management/notifications/?limit=1000"
    );
    if (res.status === 200) {
      dispatch(allNotification(res.data.data.results));
    }
  };

  return (
    <>
      <Box
        sx={{
          ml: 2,
          mr: 3,
          [theme.breakpoints.down("md")]: {
            mr: 2,
          },
        }}
      >
        <ButtonBase
          sx={{ borderRadius: "12px" }}
          component={Badge}
          badgeContent={notifications.alert ? "new" : 0}
          color="primary"
        >
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: "all .2s ease-in-out",
              background:
                theme.palette.mode === "dark"
                  ? theme.palette.dark.main
                  : theme.palette.secondary.light,
              color:
                theme.palette.mode === "dark"
                  ? theme.palette.warning.dark
                  : theme.palette.secondary.dark,
              '&[aria-controls="menu-list-grow"],&:hover': {
                background:
                  theme.palette.mode === "dark"
                    ? theme.palette.warning.dark
                    : theme.palette.secondary.dark,
                color:
                  theme.palette.mode === "dark"
                    ? theme.palette.grey[800]
                    : theme.palette.secondary.light,
              },
            }}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            color="inherit"
          >
            <IconBell stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
      </Box>
      <Popper
        placement={matchesXs ? "bottom" : "bottom-end"}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [matchesXs ? 5 : 0, 20],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions
            position={matchesXs ? "top" : "top-right"}
            in={open}
            {...TransitionProps}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Grid container direction="column" spacing={2}>
                    <Grid item xs={12}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ pt: 2, px: 2 }}
                      >
                        <Grid item>
                          <Stack direction="row" spacing={2}>
                            <Typography variant="subtitle1">
                              All Notification
                            </Typography>
                            <Chip
                              size="small"
                              label={notifications.count}
                              sx={{
                                color: theme.palette.background.default,
                                bgcolor: theme.palette.warning.dark,
                              }}
                            />
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <PerfectScrollbar
                        style={{
                          height: "100%",
                          maxHeight: "calc(100vh - 205px)",
                          overflowX: "hidden",
                        }}
                      >
                        <Grid container direction="column" spacing={2}>
                          <Grid item xs={12} p={0}>
                            <Divider sx={{ my: 0 }} />
                          </Grid>
                        </Grid>
                        <InfiniteScroll
                          dataLength={notifications.results.length}
                          next={fetchMoreData}
                          hasMore={
                            notifications.results.length !== notifications.count
                          }
                          loader={<Loading position="center" />}
                        >
                          {notifications.results.map((notification) => (
                            <NotificationList
                              key={notification.id}
                              notification={notification}
                            />
                          ))}
                        </InfiniteScroll>
                      </PerfectScrollbar>
                    </Grid>
                  </Grid>
                  <Divider />
                  {!notifications.show && (
                    <CardActions sx={{ p: 1.25, justifyContent: "center" }}>
                      <Button
                        size="small"
                        disableElevation
                        onClick={fetchAllData}
                      >
                        View All
                      </Button>
                    </CardActions>
                  )}
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default NotificationSection;
