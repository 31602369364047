const checkLocationSelected = (values:any) => {
    const address: number[] = [];
    if (typeof values.division === "number") {
      address.push(values.division);
    } 
    if (typeof values.district === "number") {
        address.push(values.district);
      } 
      if (typeof values.subDistrict === "number") {
        address.push(values.subDistrict);
      } 
      if (typeof values.thana === "number") {
        address.push(values.thana);
      }
    return address;
};


export default checkLocationSelected;