import firebase from "firebase";
import 'firebase/messaging';

var firebaseConfig = {
  apiKey: "AIzaSyCQna2mKpyAIXXgHra12UWV6QYglX9f5LE",
  authDomain: "yes-job-portal.firebaseapp.com",
  projectId: "yes-job-portal",
  storageBucket: "yes-job-portal.appspot.com",
  messagingSenderId: "296903010719",
  appId: "1:296903010719:web:1e7bc964b00e43a3bd35aa",
  measurementId: "G-CL0B7VZQ39",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// for messaging
export const messaging = firebase.messaging();


export default firebase;
