// material-ui
import { useTheme, styled } from "@mui/material/styles";
import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";

// assets

import { dateTimeDuration } from "utils/dateFormate";
import getUrlForRedirect, {
  getUrlForRedirectArgT,
  setNotificationTitle,
} from "utils/getUrlForRedirect";
import { useNavigate } from "react-router-dom";
import coreAxios from "utils/axios";
import storage from "utils/storage";
import { useDispatch } from "react-redux";
import { getNotifications } from "store/notificationReducer";

// styles
const ListItemWrapper = styled("div")(({ theme }) => ({
  cursor: "pointer",
  padding: 16,
  "&:hover": {
    background:
      theme.palette.mode === "dark"
        ? theme.palette.dark.main
        : theme.palette.primary.light,
  },
  "& .MuiListItem-root": {
    padding: 0,
  },
}));

// NotificationList props type
type notificationListT = {
  notification: {
    id: number;
    type: getUrlForRedirectArgT;
    message: string;
    created_at: string;
    redirect_id: number;
    seen_time: string | null;
  };
};

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = ({ notification }: notificationListT) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // server request
  const sendToken = async (notificationId: number) => {
    const firebaseToken = storage.get("firebase-token");
    const res = await coreAxios.patch(
      `/message-management/notifications/${notificationId}/`,
      {
        used_device: {
          registration_id: firebaseToken,
        },
      }
    );
    if (res.status === 200) {
      dispatch(getNotifications());
      const path = getUrlForRedirect(
        res.data.data.type,
        res.data.data.redirect_id
      );
      if (path) {
        navigate(path);
      }
    }
  };

  return (
    <List
      sx={{
        width: "100%",
        minWidth: 230,
        maxWidth: 330,
        py: 0,
        backgroundColor:
          notification.seen_time === null ? "#F0F1FC" : "#FFFFFF",
        [theme.breakpoints.down("md")]: {
          maxWidth: 300,
        },
        "& .MuiListItemSecondaryAction-root": {
          top: 22,
        },
        "& .MuiDivider-root": {
          my: 0,
        },
        "& .list-container": {
          pl: 7,
        },
      }}
      onClick={() => {
        sendToken(notification.id);
      }}
    >
      <ListItemWrapper>
        <ListItem alignItems="center">
          <ListItemText
            primary={setNotificationTitle(notification.type)}
            className={"textCap"}
          />
          <ListItemSecondaryAction>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12}>
                <Typography variant="caption" display="block" gutterBottom>
                  {dateTimeDuration(notification.created_at)}
                </Typography>
              </Grid>
            </Grid>
          </ListItemSecondaryAction>
        </ListItem>
        <Grid container>
          <Grid item xs={12} sx={{ pb: 0.5, pt: 0.5 }}>
            <Typography variant="subtitle2" className={"textCap"}>
              {notification.message}
            </Typography>
          </Grid>
        </Grid>
      </ListItemWrapper>
      <Divider />
    </List>
  );
};

export default NotificationList;
