import { Typography } from "@mui/material";
import Loading from "components/Loading";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
// import { useSelector } from "react-redux";
// import { RootState } from "store";
import { userT } from "types/employer";
import coreAxios from "utils/axios";
import langString from "utils/langString";
import AllJobSeekerFilter from "./AllJobSeekerFilter";
import List from "./List";

export default function EmployerJobSeeker() {
  const [jobSeekers, setJobSeekers] = useState<userT[]>([]);
  const [next, setNext] = useState<string>("");
  const [count, setCount] = useState<number>(0);
  const [isFilter, setIsFilter] = useState(true);

  // server request
  useEffect(() => {
    coreAxios
      .get(`/users/job-seekers/`)
      .then((res) => {
        setCount(res.data.data.count);
        setNext(res.data.data.next);
        setJobSeekers(res.data.data.results);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const fetchMoreData = async () => {
    const res = await coreAxios.get(next);
    if (res.status === 200) {
      setJobSeekers((prevState) => [...prevState, ...res.data.data.results]);
      setNext(res.data.data.next);
    }
  };

  return (
    <>
      <AllJobSeekerFilter
        setJobSeekers={setJobSeekers}
        setIsFilter={setIsFilter}
        setCount={setCount}
      />
      <Typography variant="h1" padding="30px 20px">
        {langString("JobSeekers")}
      </Typography>
      {isFilter ? (
        <InfiniteScroll
          dataLength={jobSeekers.length}
          next={fetchMoreData}
          hasMore={jobSeekers.length !== count}
          loader={<Loading position="center" />}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>{langString("Youhaveseenitall")}</b>
            </p>
          }
        >
          {jobSeekers.map((jobSeeker) => (
            <List key={jobSeeker.id} jobSeeker={jobSeeker} />
          ))}
        </InfiniteScroll>
      ) : (
        <Typography variant="h3" padding="30px 20px" textAlign={"center"}>
          {langString("Datanotfound")}
        </Typography>
      )}
    </>
  );
}
