import { Button, Grid, Stack, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import Location from "components/Location";
import UserInformation from "components/UserInformation";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { SNACKBAR_OPEN } from "store/actions";
import { gridSpacing } from "store/constant";
import SubCard from "ui-component/cards/SubCard";
import AnimateButton from "ui-component/extended/AnimateButton";
import checkLocationSelected from "utils/checkLocationSelected";
import langString from "utils/langString";
import initialValues from "./initialValues";
import validationSchema from "./validationSchema";

const JobSeeker = () => {
  const [userInDB, setUserInDB] = useState({
    isUser: false,
    isEmail: false,
  });
  const classes = useStyle();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const postReqForJobSeekerSignUp = async (formData: FormData) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVICE_URL}users/job-seekers/`, formData);
      if (res.status === 201) {
        navigate("/");
        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: "Sign Up successful",
          variant: "alert",
          alertSeverity: "success",
        });
      }
    } catch (error: any) {
      if (error.response) {
        const message = JSON.parse(error.response.data.data).errorMessage;
        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message,
          variant: "alert",
          alertSeverity: "error",
        });
      }
    }
  };

  const onSubmitHandle = (values: any) => {

    if(values?.picture?.size < 2097152){
      const address = checkLocationSelected(values);
      const formData = new FormData();
      const job_seeker_profile = {
        name: values.name,
        dob: dayjs(values.dob!).format("YYYY-MM-DD"),
        email: values.email,
        phone: values.phone,
        address: address[address.length - 1].toString(),
      };
      formData.append("picture", values.picture!);
      formData.append("username", values.username);
      formData.append("password", values.password ? values.password : "1212");
      formData.append("job_seeker_profile", JSON.stringify(job_seeker_profile));
      postReqForJobSeekerSignUp(formData);
  } else{
    dispatch({
      type: SNACKBAR_OPEN,
      open: true,
      message: "Picture size must be less then 2MB",
      variant: "alert",
      alertSeverity: "error",
    });
  }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmitHandle,
  });

  const checkUsernameInDB = async (username: string) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVICE_URL}users/job-seekers/check-exists/`, {
        username,
      });
      if (res.status === 200) {
        if (res.data.data.email) {
          setUserInDB((prevState) => ({ ...prevState, isEmail: true }));
        }
        formik.setFieldValue(
          "email",
          res.data.data.email ? res.data.data.email : ""
        );
        formik.setFieldValue("password", "******");
        formik.setFieldValue("confirmPassword", "******");
        setUserInDB((prevState) => ({ ...prevState, isUser: true }));
      }
    } catch (e) {
      setUserInDB((prevState) => ({ ...prevState, isUser: false }));
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="h3" textAlign="center" style={{ marginBottom: 15 }}>
        {langString("JOB-SEEKERREGISTRATIONFORM")}
      </Typography>
      <Grid container direction="column" spacing={gridSpacing}>
        <Grid item xs={12}>
          <SubCard title="Personal Details">
            <Grid container spacing={2} xs={12} sm={6}>
              <Grid item xs={12}>
                <Typography component="label" htmlFor="name">
                  {langString("name")}<Typography component="span" color="red"> *</Typography>:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  fullWidth
                  id="name"
                  name="name"
                  label={<>{langString("name")}<Typography component="span" color="red"> *</Typography></>}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>

              <Grid container item xs={12} alignItems="center">
                <Grid item xs={3}>
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="raised-button-file"
                    type="file"
                    onChange={(event) => {
                      formik.setFieldValue(
                        "picture",
                        event?.currentTarget?.files?.[0]
                      );
                    }}
                  />
                  <label htmlFor="raised-button-file">
                    <Typography
                      variant="body2"
                      className={classes.uploadPicture}
                    >
                      {langString("uploadpicture")}:
                    </Typography>
                  </label>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {(formik as any).values?.picture?.name}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} alignItems="center">
                <Grid item xs={3}>
                  <Typography component="label" htmlFor="dob">
                    {langString("dateofbirth")}<Typography component="span" color="red"> *</Typography>:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    size="small"
                    id="dob"
                    name="dob"
                    type="date"
                    onChange={(event) =>
                      formik.setFieldValue("dob", event.target.value)
                    }
                    error={formik.touched.dob && Boolean(formik.errors.dob)}
                    helperText={formik.touched.dob && formik.errors.dob}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} alignItems="center">
                <Grid item xs={3}>
                  <Typography component="label" htmlFor="phone">
                    {langString("phonenumber")} <Typography component="span" color="red"> *</Typography>:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    size="small"
                    id="phone"
                    name="phone"
                    type="tel"
                    onChange={(event) =>
                      formik.setFieldValue("phone", event.target.value)
                    }
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} alignItems="center">
                <Grid item xs={3}>
                  <Typography component="label" htmlFor="email">
                    {langString("email")} <Typography component="span" color="red"> *</Typography>:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled={userInDB.isEmail}
                    fullWidth
                    size="small"
                    id="email"
                    name="email"
                    type="email"
                    value={formik.values.email}
                    onChange={(event) =>
                      formik.setFieldValue("email", event.target.value)
                    }
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Location formik={formik} />
          </SubCard>
        </Grid>
        <Grid item xs={12}>
          <UserInformation
            userInDB={userInDB}
            formik={formik}
            checkUsernameInDB={checkUsernameInDB}
          />
        </Grid>
        <Grid container item xs={12} spacing={1} justifyContent="center">
          <Grid item xs={4} sm={2} md={1} lg={1}>
            <Stack direction="column">
              <AnimateButton>
                <Button variant="contained" onClick={() => navigate("/")}>
                  {langString("Cancel")}
                </Button>
              </AnimateButton>
            </Stack>
          </Grid>
          <Grid item xs={4} sm={2} md={1} lg={1}>
            <Stack direction="column">
              <AnimateButton>
                <Button variant="contained" type="submit">
                  {langString("Confirm")}
                </Button>
              </AnimateButton>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default JobSeeker;

const useStyle = makeStyles({
  uploadPicture: {
    textDecoration: "underline",
    fontWeight: "bold",
    color: "blue",
    "&:hover": {
      cursor: "pointer",
    },
  },
});
