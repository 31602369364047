const initialData = {
  description: "",
  category: "",
  vacancy: 0,
  type: "",
  salary_range_min: 0,
  salary_range_max: 0,
  deadline: "",
  is_yes_certified_required: false,
  gender: "",
  experience: 0,
  degrees: "",
  locations: "",
  age_max: 0,
  age_min: 0,
  personal_skills: [],
  technical_skills: [],
  yes_trainings: [],
  yes_courses: [],
  is_experience: false,
  is_age: false,
  is_locations: false,
  is_gender: false,
  is_degree: false,
};
export default initialData;
