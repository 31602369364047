// assets
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import {
  Box,
  CardContent,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
// material-ui
import { useTheme } from "@mui/material/styles";
import React, { useCallback, useEffect, useRef, useState } from "react";
// third-party
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { RootState } from "store";
import { gridSpacing } from "store/constant";
import { getNotifications } from "store/notificationReducer";
import { messageInfoT } from "types/employer";
import MainCard from "ui-component/cards/MainCard";
import axios from "utils/axios";
import { messaging } from "../../../coreFirebase";
// project imports
import ChartHistory, { History as HistoryProps } from "./ChartHistory";

// ==============================|| APPLICATION CHAT ||============================== //
export type dataT = {
  message: HistoryProps[];
  next?: string;
  count?: number;
};
const ChatMainPage = () => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const windowId = searchParams.get("windowId");
  const [data, _setData] = useState<dataT>({ message: [], next: "", count: 0 });
  const [user, setUser] = useState<messageInfoT>();
  const [count, setCount] = useState(0);
  const userAuthority = useSelector(
    (state: RootState) => state.account.user?.user_authority?.name
  );
  const userId = useSelector((state: RootState) => state.account?.user?.id);

  const dispatch = useDispatch();

  // scroll to bottom when new message is sent or received
  const wrapper = useRef(document.createElement("div"));
  const el = wrapper.current;

  const scrollToBottom = useCallback(() => {
    el.scrollIntoView(false);

    // eslint-disable-next-line
  }, [data.message.length, el]);

  useEffect(() => {
    el.scrollIntoView(false);
  }, [count, el]);

  const dataRef = React.useRef(data);
  const setData = (data: dataT) => {
    dataRef.current = data;
    _setData(data);
  };
  // fetch user details of current user

  React.useEffect(() => {
    messaging.onMessage((payload: any) => {
      const dataOfMessage = JSON.parse(payload.data.data);
      const notification = JSON.parse(payload.data.notification);
      if (notification.recipient === userId) {
        const newMessage = {
          owner: dataOfMessage.owner,
          message: dataOfMessage.message,
          created_at: dataOfMessage.created_at,
        };
        setData({
          message: [...dataRef.current.message, newMessage],
        });
        setCount((prevState) => prevState + 1);
      }
      dispatch(getNotifications());
    });
  }, [dispatch, userId]);

  React.useEffect(() => {
    const getData = async () => {
      const response = await axios.get(
        `/message-management/windows/${windowId}`
      );
      if (response.status === 200) {
        setUser(response.data.data);
        setData({
          message: [...response.data.data.messages.results.reverse()],
          next: response.data.data.messages.next,
          count: response.data.data.messages.count,
        });
      }
    };
    getData();
  }, [windowId]);

  // handle new message form
  const [message, setMessage] = useState("");
  const handleOnSend = (event: any) => {
    event.preventDefault();
    if (message !== "") {
      axios
        .post(`/message-management/windows/${windowId}/messages/`, {
          message,
        })
        .then((res) => {
          if (res.status === 201) {
            const newMessage = {
              owner: res.data.data.owner,
              message: res.data.data.message,
              created_at: res.data.data.created_at,
            };
            setData({
              message: [...dataRef.current.message, newMessage],
            });
            scrollToBottom();
          }
        });
    }
    setMessage("");
  };

  const getMoreData = async () => {
    if (dataRef.current.next) {
      const response = await axios.get(dataRef.current.next);
      if (response.status === 200) {
        setUser(response.data.data);
        setData({
          message: [
            ...response.data.data.messages.results,
            ...dataRef.current.message,
          ],
          next: response.data.data.messages.next,
          count: response.data.data.messages.count,
        });
      }
    }
  };

  const top = useRef(document.getElementById("scrollTopGetData"));
  const scrollTop =top.current;
  const onScroll = (e:any) => {
    const currentScrollY = e.target.scrollTop;
    if (currentScrollY === 0 ) {
      getMoreData();
      scrollTop?.scrollTo(0, scrollTop.clientHeight);
    }
  };

  if (!user) return <Typography>Loading...</Typography>;
  return (
    <Box sx={{ display: "flex" }}>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          <MainCard
            sx={{
              bgcolor: theme.palette.mode === "dark" ? "dark.main" : "grey.50",
            }}
          >
            <Grid container spacing={gridSpacing}>
              <Grid item xs={12}>
                <Grid container alignItems="center" xs={12} spacing={0.5}>
                  <Grid item xs={12}>
                    <Typography variant="h3" textAlign={"center"}>
                      {userAuthority === "employer"
                        ? `${user.job_seeker?.name} (Job-Seeker)`
                        : `${user.employer?.name} (Employer)`}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ mt: theme.spacing(2) }} />
              </Grid>
              <PerfectScrollbar
                id="scrollTopGetData"
                style={{
                  width: "100%",
                  height: "calc(100vh - 440px)",
                  overflow: "auto",
                  minHeight: 525,
                }}
                onScroll={onScroll}
              >
                <CardContent>
                  <ChartHistory
                    theme={theme}
                    fetchMore={getMoreData}
                    dataMessage={data.message}
                    scrollToBottom={scrollToBottom}
                    wrapper={wrapper}
                    count={dataRef.current.count !== undefined ? dataRef.current.count:0}
                  />
                </CardContent>
              </PerfectScrollbar>

              <Grid item xs={12}>
                <form
                  onSubmit={(event: React.FormEvent<HTMLFormElement>) =>
                    handleOnSend(event)
                  }
                >
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs zeroMinWidth>
                      <TextField
                        fullWidth
                        placeholder="Type a Message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </Grid>
                    <Grid item>
                      <IconButton color="primary" type="submit" size="large">
                        <SendTwoToneIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </MainCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChatMainPage;
