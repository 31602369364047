import JobMarketTabs from "./Tabs";

const JobMarket = () => {
  return (
    <>
      <JobMarketTabs />
    </>
  );
};

export default JobMarket;
