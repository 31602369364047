import { Button, Grid, Stack, Typography } from "@mui/material";
import BasicDataTable from "components/BasicDataTable";
import { useEffect, useMemo, useState } from "react";
import MainCard from "ui-component/cards/MainCard";
import AnimateButton from "ui-component/extended/AnimateButton";
import coreAxios from "utils/axios";
import { checkNullData } from "utils/dataHelper";
import langString from "utils/langString";
import storage from "utils/storage";

const EmployersReports = () => {
  const [rows, setRows] = useState<any[]>([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // server request
  useEffect(() => {
    const params: any = {
      limit: rowsPerPage,
      offset: page * 10,
    };

    coreAxios
      .post(
        `jobs/reports/employers/?limit=${params.limit}&offset=${params.offset}`
      )
      .then((res) => {
        if (res.data.status === 200) {
          setCount(res.data.data.count);
          setRows(res.data.data.results);
        }
      })
      .catch((e: any) => {
        console.log(e);
      });
  }, [page, rowsPerPage]);

  const columns = useMemo(
    () => [
      {
        Header: langString("employerid"),
        accessor: "id",
      },
      {
        Header: langString("nameofemployer"),
        accessor: "name",
      },
      {
        Header: langString("typeofemployer"),
        accessor: "typeOfEmployer",
      },
      {
        Header: langString("registrationdate"),
        accessor: "registrationDate",
      },
      {
        Header: langString("nameofjobposted"),
        accessor: "nameOfJobPosted",
      },
      {
        Header: langString("dateofpost"),
        accessor: "dateOfPost",
      },
    ],
    []
  );

  const rowsData = (data: any) => {
    const processedData: any[] = [];
    data.forEach((row: any, index: number) =>
      processedData.push({
        id: row["employer"] && checkNullData(row["employer"]["id"]),
        name: row["employer"] && checkNullData(row["employer"]["name"]),
        typeOfEmployer:
          row["employer"] && checkNullData(row["employer"]["type"]["name"]),
        registrationDate:
          row["employer"] &&
          checkNullData(row["employer"]["registration_date"]),
        nameOfJobPosted: row["employer"] && checkNullData(row["description"]),
        dateOfPost: row["employer"] && checkNullData(row["posted_date"]),
      })
    );
    return processedData;
  };

  const onClickExportFile = () => {
    (async () => {
      const token = storage.get("token");

      const response = await fetch(
        `${process.env.REACT_APP_SERVICE_URL}jobs/reports/export/`,
        {
          method: "POST",
          body: JSON.stringify({
            type: "employer",
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const blob = await response.blob();
        const href = await URL.createObjectURL(blob);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style.display = "none";
        a.href = href;
        a.download = "employers-report";
        a.click();
      }
    })();
  };

  return (
    <>
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
        alignContent="stretch"
        wrap="wrap"
        marginBottom={2}
      >
        <Grid item xs={12} sm={2} textAlign="right">
          <Stack direction="column">
            <AnimateButton>
              <Button
                variant="contained"
                type="button"
                onClick={onClickExportFile}
              >
                {langString("export")}
              </Button>
            </AnimateButton>
          </Stack>
        </Grid>
      </Grid>

      {rows ? (
        <BasicDataTable
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={(value: number) => setPage(value)}
          setRowsPerPage={(value: number) => setRowsPerPage(value)}
          columns={columns}
          rows={rowsData(rows)}
        />
      ) : (
        <MainCard>
          <Typography variant="h4" sx={{ margin: 3, textAlign: "center" }}>
            "Loading..."
          </Typography>
        </MainCard>
      )}
    </>
  );
};

export default EmployersReports;
