import { Grid, TextField, Typography } from "@mui/material";
import SelectField from "components/FormFields/SelectField";
import { useSelector } from "react-redux";
import { RootState } from "store";
import MainCard from "ui-component/cards/MainCard";
import formateSelectFieldValues from "utils/formateSelectFieldValues";
import langString from "utils/langString";
import useStyle from "./style";

const JobDescription = ({ formik }: any) => {
  const { jobType, jobCategory } = useSelector(
    (state: RootState) => state.config
  );

  const jobTypes = formateSelectFieldValues("Select Type", jobType!);
  const jobCategories = formateSelectFieldValues(
    "Select Type Category",
    jobCategory!
  );

  const classes = useStyle();

  return (
    <>
      <Grid item xs={12} sm={6}>
        <MainCard className={classes.h}>
          <Typography variant="h4" gutterBottom>
            {langString("JobDescription")}
          </Typography>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                disabled
                fullWidth
                size="small"
                id="outlined-basic"
                label={langString("Description")}
                variant="outlined"
                onChange={formik?.handleChange}
                name="description"
                value={formik?.values.description}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />
            </Grid>
            <Grid item xs={6}>
              <SelectField
                name="category"
                defaultValue={formik?.values.category}
                handleChange={formik?.handleChange}
                labelName={langString("JobCategory")}
                menuItems={jobCategories}
                formik={formik}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectField
                labelName={langString("Type")}
                defaultValue={formik?.values.type}
                handleChange={formik?.handleChange}
                name="type"
                menuItems={jobTypes}
                formik={formik}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                id="outlined-basic"
                label={langString("vacancy")}
                variant="outlined"
                onChange={formik?.handleChange}
                name="vacancy"
                value={formik?.values.vacancy}
                error={formik.touched.vacancy && Boolean(formik.errors.vacancy)}
                helperText={formik.touched.vacancy && formik.errors.vacancy}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                id="outlined-basic"
                label={langString("minsalary")}
                variant="outlined"
                onChange={formik?.handleChange}
                name="salary_range_min"
                type="number"
                value={formik?.values.salary_range_min}
                error={
                  formik.touched.salary_range_min &&
                  Boolean(formik.errors.salary_range_min)
                }
                helperText={
                  formik.touched.salary_range_min &&
                  formik.errors.salary_range_min
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                id="outlined-basic"
                label={langString("maxsalary")}
                variant="outlined"
                onChange={formik?.handleChange}
                name="salary_range_max"
                type="number"
                value={formik?.values.salary_range_max}
                error={
                  formik.touched.salary_range_max &&
                  Boolean(formik.errors.salary_range_max)
                }
                helperText={
                  formik.touched.salary_range_max &&
                  formik.errors.salary_range_max
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled
                fullWidth
                size="small"
                id="outlined-basic"
                variant="outlined"
                onChange={formik?.handleChange}
                name="deadline"
                type="date"
                label={langString("deadline")}
                InputLabelProps={{ shrink: true }}
                value={formik?.values.deadline}
                error={
                  formik.touched.deadline && Boolean(formik.errors.deadline)
                }
                helperText={formik.touched.deadline && formik.errors.deadline}
              />
            </Grid>
          </Grid>
        </MainCard>
      </Grid>
    </>
  );
};

export default JobDescription;
