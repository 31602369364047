import coreAxios from "utils/axios";
import {
  formattingDataEducations,
  formattingDataExperience,
  formattingDataOtherCertifications,
  formattingDataRecommendations,
  formattingDataYesCertification
} from "./formattingData";

export const getJobSeekerData = (
  jobSeekerId: any,
  setPersonalDetails: any,
  setRecommendations: any,
  setPersonalSkills: any,
  setTechnicalSkills: any,
  setEducationsRaw: any,
  setYesCertificationRaw: any,
  setOtherCertificationsRaw: any,
  setExperienceRaw: any,
  setBadges:any,
) => {
  coreAxios
    .get(`/users/job-seekers/${jobSeekerId}/`)
    .then((res) => {
      const { job_seeker_profile } = res.data.data;
      // data formate
      const recommendations = formattingDataRecommendations(
        job_seeker_profile?.recommendations!
      );
      const educations = formattingDataEducations(job_seeker_profile?.degrees!);
      const yesCertification = formattingDataYesCertification(
        job_seeker_profile?.yes_courses!,
        job_seeker_profile?.yes_trainings!
      );
      const otherCertifications = formattingDataOtherCertifications(
        job_seeker_profile?.other_certifications!
      );
      const experience = formattingDataExperience(
        job_seeker_profile?.experiences!
      );
      // set state
      setPersonalDetails((prevStatus: any) => ({
        ...prevStatus,
        picture: job_seeker_profile?.picture!,
        name: job_seeker_profile?.name,
        gender: job_seeker_profile?.gender,
        age: job_seeker_profile.dob,
        phone: job_seeker_profile?.phone,
        email: job_seeker_profile?.email,
      }));
      setRecommendations(recommendations);
      setPersonalSkills(job_seeker_profile?.personal_skills!);
      setTechnicalSkills(job_seeker_profile?.technical_skills!);
      setEducationsRaw(educations);
      setYesCertificationRaw(yesCertification);
      setOtherCertificationsRaw(otherCertifications);
      setExperienceRaw(experience);
      setBadges(job_seeker_profile.badges);
    })
    .catch((e) => {
      console.log(e);
    });
};
