import { Button, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import JobSeekerModal from "components/JobSeekerModal";
import { useEffect, useState } from "react";
import MainCard from "ui-component/cards/MainCard";
import AnimateButton from "ui-component/extended/AnimateButton";
import coreAxios from "utils/axios";
import { isPrevDate } from "utils/dateFormate";
import langString from "utils/langString";

type PropTypes = {
  job: any;
  isLoggedIn: boolean;
};

export default function JobApply({ job, isLoggedIn }: PropTypes) {
  const classes = useStyle();
  const [status, setStatus] = useState("not-applied");
  const [applicationID, setApplicationID] = useState(0);

  const applyForJob = () => {
    coreAxios
      .post(`/jobs/job-posts/${job.id}/applications/`)
      .then((res) => {
        if (res.data.status === 200) {
          setStatus("applied");
          setApplicationID(res.data.data.id);
        }
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const cancelJobApplication = () => {
    coreAxios
      .delete(`/jobs/job-posts/applications/${applicationID}/`)
      .then((res) => {
        if (res.data.status === 200) {
          setStatus("not-applied");
        }
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (job && job.application_status) {
      setStatus(job.application_status);
    }
    if (job && job.application_id) {
      setApplicationID(job.application_id);
    }
  }, [job]);

  return (
    <MainCard className={classes.p20}>
      <Typography variant="h3" gutterBottom>
        {langString("status")}: {status.toUpperCase()}
      </Typography>
      {job?.application_status && (
        <Stack className={classes.center}>
          <AnimateButton>
            {isLoggedIn ? (
              <>
                {status === "not-applied" && (
                  <Button disabled={isPrevDate(job.deadline)} variant="contained" onClick={() => applyForJob()}>
                    {langString("apply")} {langString("now")}
                  </Button>
                )}
                {status === "applied" && (
                  <>
                    <h5>{langString("employerdecision")}</h5>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => cancelJobApplication()}
                    >
                      {langString("cancel")} {langString("application")}
                    </Button>
                  </>
                )}
                {status === "pending" && (
                  <>
                    <Typography variant="h6" gutterBottom>
                      {langString("employerdecision")}
                    </Typography>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => cancelJobApplication()}
                    >
                      {langString("cancel")} {langString("application")}
                    </Button>
                  </>
                )}
                {status === "selected" && (
                  <>
                    <h3>
                      {langString("interview")} {langString("on")}:{" "}
                      {job?.interview_date || "Date Not Defined"}
                    </h3>
                    <h3>
                      {langString("interview")} {langString("location")}:{" "}
                      {job?.interview_location?.name || "Location Not Defined"}
                    </h3>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => cancelJobApplication()}
                    >
                      {langString("cancel")} {langString("application")}
                    </Button>
                  </>
                )}
              </>
            ) : (
              <JobSeekerModal />
            )}
          </AnimateButton>
        </Stack>
      )}
    </MainCard>
  );
}

const useStyle = makeStyles({
  p20: {
    padding: "20px",
  },
  dFlex: {
    display: "flex",
    justifyContent: "space-between",
  },
  center: {
    minHeight: 60,
    textAlign: "center",
    marginTop: 60,
  },
});
