import { Avatar, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { MENU_OPEN, SET_MENU } from "store/actions";
import { useTheme } from "@mui/material/styles";

type homeProp = {
  name?: string;
  img?: string;
  navData: {
    itemsId: number;
    items: {
      id: string;
      name: string;
      path: string;
    }[];
  }[];
};

const Home = ({ name, img, navData }: homeProp) => {
  const classes = useStyle();
  const theme = useTheme();
  const dispatch = useDispatch();
  const matchesSM = useMediaQuery(theme.breakpoints.down("lg"));
  const itemHandler = (id: string) => {
    dispatch({ type: MENU_OPEN, id });
    matchesSM && dispatch({ type: SET_MENU, opened: false });
  };
  return (
    <>
      <Grid
        container
        spacing={2}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        justifyContent="center"
      >
        <Grid item xs={12} sm={12} md={12} lg={4} textAlign="center">
          <Avatar
            src={img}
            alt="company img"
            sx={{ width: 200, height: 200, margin: "auto" }}
          />
          <Typography
            variant="h2"
            margin="36px 0px"
            className="textCap"
            textAlign="center"
          >
            {name}
          </Typography>
        </Grid>
        {navData.map(({ itemsId, items }) => {
          return (
            <Grid
              key={itemsId}
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              spacing={3}
              justifyContent="center"
            >
              {items?.map(({ id, name, path }) => {
                return (
                  <Grid
                    key={id}
                    item
                    xs={12}
                    sm={4}
                    md={4}
                    lg={3}
                    textAlign="center"
                  >
                    <Button
                      component={Link}
                      to={`${path}`}
                      className={classes.link}
                      onClick={() => itemHandler(id)}
                    >
                      {name}
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default Home;

const useStyle = makeStyles({
  margin: {
    margin: "10px",
  },
  link: {
    backgroundColor: "blue",
    color: "white",
    padding: "25px",
    fontSize: "20px",
    minWidth: "30vh",
    "&:hover": {
      backgroundColor: "blue",
    },
    // eslint-disable-next-line
    ["@media (min-width:320px)"]: {
      width: "100%",
      margin: "10px 0px",
    },
    // eslint-disable-next-line
    ["@media (min-width:1024px)"]: {
      margin: "10px 10px",
    },
  },
});
