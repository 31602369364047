import {
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import MainCard from "ui-component/cards/MainCard";
import coreAxios from "utils/axios";
import { ExperienceType } from "./index";
import { useSelector } from "react-redux";
import { RootState } from "store";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import dayjs from "dayjs";
import langString from "utils/langString";

type AddExperiencePropsType = {
  closeAddForm: () => void;
  userUpdate: () => void;
  experiences: ExperienceType[];
};

export default function AddExperience({
  closeAddForm,
  experiences,
  userUpdate,
}: AddExperiencePropsType) {
  const { user } = useSelector((status: RootState) => status.account);

  const [org, setOrg] = useState("");
  const [position, setPosition] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const saveNewEexperience = () => {
    const allExperiences: ExperienceType[] = [...experiences];

    allExperiences.push({
      id: 0,
      organization_name: org,
      position: position,
      start_date: startDate && dayjs(startDate).format("YYYY-MM-DD"),
      end_date: endDate && dayjs(endDate).format("YYYY-MM-DD"),
      view_in_profile: true,
      view_in_cv: true,
    });
    if (user) {
      const formData = new FormData();
      formData.append(
        "job_seeker_profile",
        JSON.stringify({ experiences: allExperiences })
      );
      coreAxios
        .patch(`/users/job-seekers/${user.id}/`, formData)
        .then((res) => {
          if (res.status === 200) {
            userUpdate();
            closeAddForm();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <MainCard>
      <Grid container>
        <Grid item xs={6} sx={{ marginBottom: 1 }}>
          <Typography variant="h4">
            {langString("addnew")} {langString("experience")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          textAlign="right"
          sx={{ marginTop: 1, marginBottom: 2 }}
        >
          <Button
            size="small"
            color="inherit"
            variant="contained"
            onClick={() => closeAddForm()}
          >
            {langString("close")}
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={3} marginY={{ xs: 1 }}>
          <FormControl>
            <TextField
              id="org"
              name="org"
              label={langString("organization") + " " + langString("name")}
              onChange={(event: any) => setOrg(event.target.value)}
              value={org}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3} marginY={{ xs: 1 }}>
          <FormControl>
            <TextField
              id="position"
              name="position"
              label={langString("position")}
              onChange={(event: any) => setPosition(event.target.value)}
              value={position}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3} marginY={{ xs: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label={langString("startdate")}
              inputFormat="MM/dd/yyyy"
              value={startDate ? startDate : new Date()}
              onChange={(value: Date | null) => setStartDate(value)}
              renderInput={(params: any) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={3} marginY={{ xs: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label={langString("enddate")}
              inputFormat="MM/dd/yyyy"
              value={endDate ? endDate : new Date()}
              onChange={(value: Date | null) => setEndDate(value)}
              renderInput={(params: any) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} textAlign="right" sx={{ marginTop: 1 }}>
          <Button
            size="small"
            color="success"
            variant="contained"
            onClick={() => saveNewEexperience()}
          >
            {langString("save")}
          </Button>
        </Grid>
      </Grid>
    </MainCard>
  );
}
