import { Grid, Paper, Typography } from "@mui/material";
import langString from "utils/langString";
import { RecommendationType } from "./index";

type RecommListPropsType = {
  recommendations: RecommendationType[];
};

export default function RecommendationList({
  recommendations,
}: RecommListPropsType) {
  return (
    <div>
      {recommendations.map((recommendation) => {
        return (
          recommendation.view_in_profile && (
            <Paper
              key={recommendation.id}
              sx={{ backgroundColor: "#eeeeee", margin: "10px 0px" }}
            >
              <Grid container xs={12} sx={{ padding: "10px" }}>
                <Grid item xs={12} sm={9}>
                  <Typography variant="h4" className="textCap">
                    {recommendation.message}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography variant="body1" className="textCap">
                    {langString("name")}: {recommendation.name}
                  </Typography>
                  <Typography variant="body1" className="textCap">
                    {langString("position")}: {recommendation.position}
                  </Typography>
                  <Typography variant="body1" className="textCap">
                    {langString("company")}: {recommendation.company}
                  </Typography>
                  <Typography variant="body1" className="textCap">
                  {langString("yearofestablishment")}: {recommendation.year}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          )
        );
      })}
    </div>
  );
}
