import {
  FormControl, Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import formateSelectFieldValues from "utils/formateSelectFieldValues";
import getLocation from "utils/getLocation";
import langString from "utils/langString";
type locationT = {
  value: any;
  name: any;
}[];

const Location = ({ formik, id = "", address = 0 }: any) => {
  const [district, setDistrict] = useState<locationT>();
  const [thana, setThana] = useState<locationT>();

  const { location } = useSelector((state: RootState) => state.config);
  const locations = getLocation(location!, null, "division");
  const division = formateSelectFieldValues("Division All", locations!);

  // eslint-disable-next-line
  useEffect(() => {
    if (
      formik.values.division === "" &&
      formik.values.district === "" &&
      // formik.values.subDistrict === "" &&
      formik.values.thana === ""
    ) {
      const arr: { id: any; name: any; pId: any; parent: any }[] = [];
      const printAddress = (locationId: any): any => {
        if (location) {
          for (let i = 0; i < location.length; i++) {
            const loc: any = location?.[i];

            if (loc?.id === locationId) {
              arr.push({
                id: loc?.id,
                name: loc?.name,
                pId: loc?.geo_definition.id,
                parent: loc?.geo_definition.node_name,
              });
              printAddress(loc?.parent);
            }
          }
        }
        return "";
      };
      if (
        typeof id === "string" &&
        id !== "" &&
        arr.length > -1 &&
        address !== 0
      ) {
        printAddress(address);
        const division = arr?.find((val) => val.parent === "division");
        const districts = getLocation(location!, division?.id, "district");
        // const subDistrict = getLocation(
        //   location!,
        //   arr?.find((val) => val.parent === "district")?.id,
        //   "sub-district"
        // );
        const thana = getLocation(
          location!,
          arr?.find((val) => val.parent === "district")?.id,
          "thana"
        );

        setDistrict(formateSelectFieldValues("District All", districts));
        // setSubDistrict(
        //   formateSelectFieldValues("Sub District All", subDistrict)
        // );
        setThana(formateSelectFieldValues("Thana All", thana));

        formik?.setFieldValue("division", division?.id);
        formik?.setFieldValue(
          "district",
          arr?.find((val) => val.parent === "district")?.id
        );
        // formik?.setFieldValue(
        //   "subDistrict",
        //   arr?.find((val) => val.parent === "sub-district")?.id
        // );
        formik?.setFieldValue(
          "thana",
          arr?.find((val) => val.parent === "thana")?.id
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, formik.values]);

  return (
    <>
      <Grid container xs={12} alignItems="center" spacing={2}>
        <Grid item xs={12} marginTop={"20px"}>
          <Typography component="label" htmlFor="address">
            {langString("address")} <Typography component="span" color="red"> *</Typography>:
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <>
            <FormControl fullWidth>
              <InputLabel id={`${"division"}-select`} sx={{ m: -0.5 }}>
                {langString("Division")} <Typography component="span" color="red"> *</Typography>
              </InputLabel>
              <Select
                size="small"
                labelId={`${"division"}-select`}
                id="division"
                value={formik.values.division}
                name="division"
                onChange={(e) => {
                  formik?.setFieldValue("division", e.target.value);
                  const locations = getLocation(
                    location!,
                    e.target.value,
                    "district"
                  );
                  const districts = formateSelectFieldValues(
                    "District All",
                    locations!
                  );
                  setDistrict(districts);
                  // setSubDistrict([]);
                  setThana([]);
                }}
                label={langString("Division")}
                error={
                  formik.touched.division && Boolean(formik.errors.division)
                }
              >
                {division.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {!item.value ? <em>{item.name}</em> : item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        </Grid>
        <Grid item xs={6} sm={3}>
          <>
            <FormControl fullWidth>
              <InputLabel id={`${"district"}-select`} sx={{ m: -0.5 }}>
                {langString("District")} <Typography component="span" color="red"> *</Typography>
              </InputLabel>
              <Select
                size="small"
                labelId={`${"district"}-select`}
                id="district"
                name="district"
                value={formik.values.district}
                onChange={(e) => {
                  formik?.setFieldValue("district", e.target.value);
                  const locations = getLocation(
                    location!,
                    e.target.value,
                    "thana"
                  );
                  // const subDistricts = formateSelectFieldValues(
                  //   "SubDistrict All",
                  //   locations!
                  // );
                  // setSubDistrict(subDistricts);
                  const thanas = formateSelectFieldValues(
                    "Thana All",
                    locations!
                  );
                  setThana(thanas);
                  // setThana([]);
                }}
                label={langString("District")}
                error={
                  formik.touched.district && Boolean(formik.errors.district)
                }
              >
                {district?.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {!item.value ? <em>{item.name}</em> : item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        </Grid>
        {/* <Grid item xs={3}>
          <>
            <FormControl fullWidth>
              <InputLabel id={`${"division"}-select`} sx={{ m: -0.5 }}>
                Sub District
              </InputLabel>
              <Select
                size="small"
                labelId={`${"sub-district"}-select`}
                id="sub-district"
                name="subDistrict"
                value={formik.values.subDistrict}
                onChange={(e) => {
                  formik?.setFieldValue("subDistrict", e.target.value);
                  const locations = getLocation(
                    location!,
                    e.target.value,
                    "thana"
                  );
                  const thanas = formateSelectFieldValues(
                    "Thana All",
                    locations!
                  );
                  setThana(thanas);
                }}
                label="sub-district"
              >
                {subDistrict?.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {!item.value ? <em>{item.name}</em> : item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        </Grid> */}
        <Grid item xs={6} sm={3}>
          <>
            <FormControl fullWidth>
              <InputLabel id={`${"thana"}-select`} sx={{ m: -0.5 }}>
                {langString("Thana")} <Typography component="span" color="red"> *</Typography>
              </InputLabel>
              <Select
                size="small"
                labelId={`${"thana"}-select`}
                id="thana"
                name="thana"
                value={formik.values.thana}
                onChange={(e) => {
                  formik?.setFieldValue("thana", e.target.value);
                }}
                label={langString("Thana")}
                error={formik.touched.thana && Boolean(formik.errors.thana)}
              >
                {thana?.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {!item.value ? <em>{item.name}</em> : item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        </Grid>
      </Grid>
    </>
  );
};

export default Location;
