import { Avatar, Grid, Typography } from "@mui/material";
import MainCard from "ui-component/cards/MainCard";
import getAge from "utils/getAge";
import langString from "utils/langString";
import setPicturePath from "utils/setPictureUrl";

export type PersonalDetailsProps = {
  personalDetails: {
    picture: string;
    name: string;
    age: string;
    gender: string;
    phone: string;
    email: string;
  };
};

export default function PersonalDetails({
  personalDetails,
}: PersonalDetailsProps) {
  const { name, age, gender, phone, email, picture } = personalDetails;
  return (
    <>
      <MainCard>
        <Typography variant="h4" sx={{ marginBottom: "10px" }}>
          {langString("PersonalDetails")}
        </Typography>
        <Grid container item xs={12} alignItems="center">
          <Grid item xs={12} sm={4}>
            <Avatar
              src={picture && picture !== "" ? setPicturePath(picture) : ""}
              alt="company img"
              sx={{ width: 128, height: 128 }}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography variant="body1" className="textCap">
              {langString("name")}: {name}
            </Typography>
            <Typography variant="body1" className="textCap">
              {langString("gender")}: {gender}
            </Typography>
            <Typography variant="body1" className="textCap">
              {langString("Age")}: {getAge(age ? age : "")}
            </Typography>
            <Typography variant="body1" className="textCap">
              {langString("phone")}: {phone ? phone : ""}
            </Typography>
            <Typography variant="body1">
              {" "}
              {langString("email")}: {email ? email : ""}
            </Typography>
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
}
