import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "store";
import MainCard from "ui-component/cards/MainCard";
import langString from "utils/langString";
import useStyle from "./style";

const BasicSkill = ({ formik }: any) => {
  const { personalSkills, technicalSkills } = useSelector(
    (state: RootState) => state.config
  );

  const classes = useStyle();

  return (
    <>
      <Grid item xs={12} sm={6}>
        <MainCard className={classes.h}>
          <Typography variant="h4" gutterBottom>
            {langString("BasicSkillRequirements")}
          </Typography>
          <Grid item>
            <Autocomplete
              multiple
              id="personal_skills"
              size="small"
              options={personalSkills ? personalSkills : []}
              getOptionLabel={(option) => option.name}
              value={formik?.values?.personal_skills}
              onChange={(e, values) => {
                formik?.setFieldValue("personal_skills", values);
              }}
              renderInput={(params) => (
                <TextField
                  sx={{ m: 1 }}
                  {...params}
                  variant="standard"
                  label={langString("PersonalSkills")}
                  placeholder={langString("PersonalSkills")}
                  name="personal_skills"
                />
              )}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              multiple
              id="technical_skills"
              size="small"
              options={technicalSkills ? technicalSkills : []}
              getOptionLabel={(option) => option.name}
              value={formik?.values?.technical_skills}
              onChange={(e, values) =>
                formik?.setFieldValue("technical_skills", values)
              }
              renderInput={(params) => (
                <TextField
                  sx={{ m: 1 }}
                  {...params}
                  variant="standard"
                  label={langString("TechnicalSkills")}
                  placeholder={langString("TechnicalSkills")}
                  name="technical_skills"
                />
              )}
            />
          </Grid>
        </MainCard>
      </Grid>
    </>
  );
};

export default BasicSkill;
