import {
  Avatar,
  Button,
  Grid,
  TablePagination,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainCard from "ui-component/cards/MainCard";
import coreAxios from "utils/axios";
import { checkNullData } from "utils/dataHelper";
import langString from "utils/langString";
import setPicturePath from "utils/setPictureUrl";
import CompaniesFilter from "./FilterForm";

const Companies = () => {
  const [comps, setComps] = useState<any[]>([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [industry, setIndustry] = useState(0);
  const [location, setLocation] = useState("");
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const followCompany = (id: number) => {
    coreAxios
      .get(`/users/employers/${id}/follow/`)
      .then((res) => {
        if (res.data.status === 200) {
          const allCompanies = [...comps];
          allCompanies.forEach((item: any, index: number) => {
            if (item.id === id) {
              allCompanies[index].company_profile.follow_status =
                res.data.data.follow_status;
            }
          });
          setComps(allCompanies);
        }
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  // server request
  useEffect(() => {
    const params: any = {
      limit: rowsPerPage,
      offset: page * 10,
    };
    if (industry) params.industry = industry;
    if (location) params.location = location;
    if (search) params.search = search;

    coreAxios
      .get("/users/employers/", {
        params: params,
      })
      .then((res) => {
        if (res.data.status === 200) {
          setCount(res.data.data.count);
          setComps(res.data.data.results);
        }
      })
      .catch((e: any) => {
        console.log(e);
      });
  }, [page, rowsPerPage, industry, location, search]);

  return (
    <>
      <CompaniesFilter
        industry={industry}
        setIndustry={(value: number) => setIndustry(value)}
        location={location}
        setLocation={(value: string) => setLocation(value)}
        search={search}
        setSearch={(value: string) => setSearch(value)}
      />
      {comps.length ? (
        comps.map((row: any) => (
          <MainCard sx={{ marginTop: 1 }}>
            <Grid container textAlign={{ xs: "center" }}>
              <Grid item sm={2} xs={12}>
                <Typography variant="h6" sx={{ marginBottom: 1 }}>
                  {checkNullData(row.company_profile?.name)}
                </Typography>
                <Avatar
                  src={setPicturePath(row.company_profile?.logo)}
                  alt="company img"
                  sx={{ width: 40, height: 40, marginX: "auto" }}
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <p>
                  {langString("industry")} :{" "}
                  <strong>
                    {checkNullData(row.company_profile?.industry_type?.name)}
                  </strong>
                </p>
                <p>
                  {langString("establishment")} :{" "}
                  <strong>
                    {checkNullData(row.company_profile?.year_of_establishment)}
                  </strong>
                </p>
              </Grid>
              <Grid item sm={3} xs={12}>
                <p>
                  {langString("location")}:{" "}
                  <strong>
                    {checkNullData(row.company_profile?.address?.name)}
                  </strong>
                </p>
                <p>
                  {langString("employees")}:{" "}
                  <strong>
                    {checkNullData(row.company_profile?.employee_volume)}
                  </strong>
                </p>
              </Grid>
              <Grid item sm={2} xs={12}>
                {langString("job")}:{" "}
                <strong>{row.company_profile?.job_posts ? row.company_profile?.job_posts : 0}</strong>
              </Grid>
              <Grid item sm={2} xs={12}>
                <Button
                  color="primary"
                  onClick={() =>
                    navigate(`/job-seeker/company-profile/${row.id}`)
                  }
                >
                  {langString("view")}
                </Button>
                {row.company_profile?.follow_status ? (
                  <Button color="inherit" onClick={() => followCompany(row.id)}>
                    {langString("unfollow")}
                  </Button>
                ) : (
                  <Button color="success" onClick={() => followCompany(row.id)}>
                    {langString("follow")}
                  </Button>
                )}
              </Grid>
            </Grid>
          </MainCard>
        ))
      ) : (
        <MainCard>
          <Typography variant="h4" sx={{ margin: 3, textAlign: "center" }}>
            {langString("datanotfound")}
          </Typography>
        </MainCard>
      )}
      <TablePagination
        component="div"
        count={count}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default Companies;
