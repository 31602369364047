// action - state management
import { LOGIN, LOGOUT, SET_USER } from './actions';
import { initialLoginContextProps } from 'types';

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState: initialLoginContextProps = {
	isLoggedIn: false,
	isInitialized: false,
	user: undefined,
};

export interface AccountReducerActionProps {
	type: string;
	payload?: initialLoginContextProps;
}

const accountReducer = (state = initialState, action: AccountReducerActionProps) => {
	switch (action.type) {
		case SET_USER:
		case LOGIN: {
			const { user,isLoggedIn } = action.payload!;
			return {
				...state,
				isInitialized: true,
				isLoggedIn,
				user
			};
		}

		case LOGOUT: {
			return {
				...state,
				isInitialized: true,
				isLoggedIn: false,
				user: null
			};
		}
		default: {
			return { ...state };
		}
	}
};

export default accountReducer;
