import { Message } from "components/Table";

export const formattingData = (
  data: any,
  user: "employer" | "job-seeker" | "system-admin"
): Message[] => {
  let message: Message[] = [];
  if (user === "job-seeker") {
    message = data.results.map((value: any, index: number) => {
      return {
        id: value.id,
        sl: index + 1,
        name:
          value?.employer?.contact_person?.length !== 0
            ? value?.employer?.contact_person?.[0].name
            : "Invalid",
        company: value.employer.name,
        role: "employer",
        designation:
          value?.employer?.contact_person?.length !== 0
            ? value?.employer?.contact_person?.[0].designation
            : "Invalid",
        message: value.new_messages === 0 ? "No New Messages" : "New Message!",
      };
    });
  }
  if (user === "employer") {
    message = data.results.map((value: any, index: number) => {
      return {
        id: value.id,
        sl: index + 1,
        name: value.job_seeker.name,
        company: "--",
        role: "job-seeker",
        designation: "--",
        message: value.new_messages === 0 ? "No New Messages" : "New Message!",
      };
    });
  }
  return message;
};
