import {
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import MainCard from "ui-component/cards/MainCard";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import coreAxios from "utils/axios";
import { useSelector } from "react-redux";
import { RootState } from "store";
import langString from "utils/langString";

export type SkillType = {
  id: number;
  name: string;
};
type SkillsPropsType = {
  userUpdate: () => void;
  technicalSkills: SkillType[];
  personalSkills: SkillType[];
};
export default function Skills({
  personalSkills,
  technicalSkills,
  userUpdate,
}: SkillsPropsType) {
  const [open, setOpen] = useState(false);
  const [skillName, setSkillName] = useState("");
  const [techSkills, setTechSkills] = useState<any[]>([]);
  const [personSkills, setPersonSkills] = useState<any[]>([]);
  const [technical, setTechnical] = useState(false);
  const [skill, setSkill] = useState(0);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((status: RootState) => status.account);

  const processFormData = (skillID: number) => {
    const formData = new FormData();
    if (skillID) {
      let allSkills: number[] = [];

      if (technical) {
        technicalSkills.forEach((item: SkillType) => allSkills.push(item.id));
        allSkills.push(skillID);

        formData.append(
          "job_seeker_profile",
          JSON.stringify({ technical_skills: allSkills })
        );
      } else {
        personalSkills.forEach((item: SkillType) => allSkills.push(item.id));
        allSkills.push(skillID);
        formData.append(
          "job_seeker_profile",
          JSON.stringify({ personal_skills: allSkills })
        );
      }
    }
    return formData;
  };

  const userSkillUpdate = (formData: any) => {
    if (user) {
      coreAxios
        .patch(`/users/job-seekers/${user.id}/`, formData)
        .then((res) => {
          if (res.status === 200) {
            userUpdate();
            setOpen(false);
            setLoading(false);
            setSkillName("");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const saveNewSkill = () => {
    setLoading(true);
    const formData = processFormData(skill);
    userSkillUpdate(formData);
  };

  const createNewSkill = () => {
    setLoading(true);
    if (skillName) {
      const formData = new FormData();
      formData.append("name", skillName);
      let reqUrl = "/settings/personal-skills/";
      if (technical) {
        reqUrl = "/settings/technical-skills/";
      }
      coreAxios
        .post(reqUrl, formData)
        .then((res) => {
          if (res.data.status === 201) {
            const formData = processFormData(res.data.data.id);
            userSkillUpdate(formData);
          } else {
            setOpen(false);
            setLoading(false);
            setSkillName("");
          }
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  };

  const deleteSkill = (id: number, technical: boolean) => {
    let allSkills: number[] = [];
    if (user) {
      const formData = new FormData();
      if (technical) {
        technicalSkills.forEach(
          (item: SkillType) => id !== item.id && allSkills.push(item.id)
        );
        formData.append(
          "job_seeker_profile",
          JSON.stringify({ technical_skills: allSkills })
        );
      } else {
        personalSkills.forEach(
          (item: SkillType) => id !== item.id && allSkills.push(item.id)
        );
        formData.append(
          "job_seeker_profile",
          JSON.stringify({ personal_skills: allSkills })
        );
      }
      coreAxios
        .patch(`/users/job-seekers/${user.id}/`, formData)
        .then((res) => {
          if (res.status === 200) {
            userUpdate();
            setOpen(false);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    coreAxios
      .get(`/settings/personal-skills/`)
      .then((res) => {
        if (res.data.status === 200) {
          setPersonSkills(res.data.data);
        }
      })
      .catch((e: any) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    coreAxios
      .get(`/settings/technical-skills/`)
      .then((res) => {
        if (res.data.status === 200) {
          setTechSkills(res.data.data);
        }
      })
      .catch((e: any) => {
        console.log(e);
      });
  }, []);

  return (
    <>
      <MainCard>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4" sx={{ marginBottom: "10px" }}>
              {langString("skills")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ margin: "10px 0px" }}>
              {langString("technical")} {langString("skills")}
            </Typography>
            {technicalSkills.length
              ? technicalSkills.map((skill) => (
                  <Chip
                    key={skill.id}
                    label={skill.name}
                    color="primary"
                    className="textCap"
                    sx={{ margin: "1px 10px" }}
                    onDelete={() => deleteSkill(skill.id, true)}
                  />
                ))
              : "No Skills Available"}
            <Button
              size="small"
              color="info"
              variant="outlined"
              onClick={() => {
                setTechnical(true);
                setOpen(true);
              }}
            >
              <AddBoxIcon />
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ margin: "10px 0px" }}>
              {langString("personal")} {langString("skills")}
            </Typography>
            {personalSkills.length
              ? personalSkills.map((skill) => (
                  <Chip
                    key={skill.id}
                    label={skill.name}
                    color="success"
                    className="textCap"
                    sx={{ margin: "1px 10px" }}
                    onDelete={() => deleteSkill(skill.id, false)}
                  />
                ))
              : "No Skills Available"}
            <Button
              size="small"
              color="info"
              variant="outlined"
              onClick={() => {
                setTechnical(false);
                setOpen(true);
              }}
            >
              <AddBoxIcon />
            </Button>
          </Grid>
        </Grid>
      </MainCard>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: 300, sx: 400, lg: 650 },
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Grid container>
            <Grid item xs={6} sx={{ marginTop: 1 }}>
              <FormControl fullWidth>
                <InputLabel id="skill-select-label">
                  {langString("select")}{" "}
                  {technical ? langString("technical") : langString("personal")}
                  {langString("skill")}
                </InputLabel>
                <Select
                  labelId="skill-select-label"
                  id="skill-select"
                  value={skill}
                  label="Skill"
                  onChange={(event: any) => setSkill(event.target.value)}
                >
                  <MenuItem value={0}>
                    {langString("select")} {langString("skill")}
                  </MenuItem>
                  {technical
                    ? techSkills.length &&
                      techSkills.map((item: any) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))
                    : personSkills.length &&
                      personSkills.map((item: any) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} textAlign="center" sx={{ marginTop: 1 }}>
              <Button
                color="success"
                variant="contained"
                onClick={() => saveNewSkill()}
              >
                {langString("save")}
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{ marginTop: 1, textAlign: "center" }}
            >
              <hr />
              <Typography variant="h6">
                {langString("oryoucanaddnewskillinstead")}
              </Typography>
            </Grid>
            <Grid item xs={8} sx={{ marginTop: 1 }}>
              <FormControl fullWidth>
                <TextField
                  id="skillName"
                  name="skillName"
                  type="text"
                  label={`${langString("skill")}*`}
                  onChange={(event: any) => setSkillName(event.target.value)}
                  value={skillName}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <Button
                sx={{ margin: 2 }}
                color="success"
                variant="contained"
                onClick={() => createNewSkill()}
              >
                {langString("add")}
              </Button>
            </Grid>
          </Grid>
          {loading && (
            <Grid container>
              <Grid item sm={12} sx={{ textAlign: "center" }}>
                <Typography variant="h5">Updating...</Typography>
              </Grid>
            </Grid>
          )}
        </Box>
      </Modal>
    </>
  );
}
