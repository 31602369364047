// import { useDispatch } from "react-redux";

// material-ui
import { Button, Grid, Stack, TextField } from "@mui/material";
// third-party
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { RootState } from "store";
// import { SNACKBAR_OPEN } from "store/actions";
import { gridSpacing } from "store/constant";
// project imports
import AnimateButton from "ui-component/extended/AnimateButton";
import coreAxios from "utils/axios";
import langString from "utils/langString";
import { formattingData } from "./formattingData";


// import * as yup from "yup";

/**
 * 'Enter your email'
 * yup.string Expected 0 arguments, but got 1 */

// ==============================|| FORM VALIDATION - LOGIN FORMIK  ||============================== //

const SearchForm = (props: any) => {
  const user = useSelector(
    (state: RootState) => state.account.user?.user_authority?.name
  );
  const formik = useFormik({
    initialValues: {
      search: "",
    },
    onSubmit: async (values) => {
      try {
        const result = await coreAxios.get(
          `${props?.pathName}?search=${values.search}`
        );
        if (result?.status === 200) {
          const messages = formattingData(result.data.data, user!);
          props?.setStateFunc(messages);
        }
      } catch (e) {
        console.log(e);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={8}>
          <TextField
            size="small"
            fullWidth
            id="search"
            name="search"
            label={langString("search")}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={3}>
          <Stack direction="column">
            <AnimateButton>
              <Button variant="contained" type="submit">
                {langString("OK")}
              </Button>
            </AnimateButton>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default SearchForm;
