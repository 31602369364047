import { Button, Grid, Stack, Typography } from "@mui/material";

import { gridSpacing } from "store/constant";
import { useFormik } from "formik";
import JobDescription from "./JobDescription";
import TargetGroup from "./TargetGroup";
import BasicSkill from "./ BasicSkill";
import TrainingOrCourse from "./TrainingOrCourse";
import AnimateButton from "ui-component/extended/AnimateButton";
import useStyle from "./style";
import useAuth from "hooks/useAuth";
import SimpleModal from "components/SimpleModal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import coreAxios from "utils/axios";
import { useNavigate } from "react-router-dom";
import { clearJob } from "store/createAction/createJob";
import initialData from "./initialData";
import onSubmitDataFormat from "./onSubmitDataFormat";
import validationSchema from "./validationSchema";
import { SNACKBAR_OPEN } from "store/actions";
import langString from "utils/langString";

const CreateJob = () => {
  const [formValue, setFormValue] = useState(initialData);
  const { isLoggedIn } = useAuth();
  const classes = useStyle();
  const formValues = useSelector((state: RootState) => state.job.job);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmitHandle = async (values: any) => {
    const data = onSubmitDataFormat(values);

    try {
      const res = await coreAxios.post("/jobs/job-posts/", data);
      if (res.status === 201) {
        dispatch(clearJob());
        navigate("/employer/post-jobs");
        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: "Job Created successful",
          variant: "alert",
          alertSeverity: "success",
        });
      }
    } catch (error: any) {
      if (error.response) {
        const message = JSON.parse(error.response.data.data).errorMessage;
        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message,
          variant: "alert",
          alertSeverity: "error",
        });
      }
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...formValue },
    validationSchema: validationSchema,
    onSubmit: onSubmitHandle,
  });

  useEffect(() => {
    setFormValue((previousState) => ({
      ...previousState,
      ...formValues,
    }));
  }, [formValues]);

  return (
    <>
      <Typography variant="h3" gutterBottom>
        {langString("CREATEJOB")}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={gridSpacing}>
          <JobDescription formik={formik} />
          <TargetGroup formik={formik} />
          <BasicSkill formik={formik} />
          <TrainingOrCourse formik={formik} />
          <Grid container justifyContent="center" className={classes.mt}>
            <Stack direction="column">
              <AnimateButton>
                {isLoggedIn ? (
                  <Button variant="contained" type="submit">
                    {langString("Create")}
                  </Button>
                ) : (
                  <SimpleModal formData={formik.values} />
                )}
              </AnimateButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default CreateJob;
