import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";

type CardLandingProps = {
  children: JSX.Element;
  title: string;
};

const CardLanding = ({ children, title }: CardLandingProps) => {
  return (
    <>
      <Card sx={{ minHeight: 425 }}>
        <CardContent>
          <Grid
            container
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h3" textAlign="center">
                {title}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>{children}</Grid>
          </Grid>
        </CardActions>
      </Card>
    </>
  );
};

export default CardLanding;
