import langString from "utils/langString";

export const messageColumn = [
    {
      Header: langString("sl"),
      accessor: "sl",
    },
    {
        Header: langString("name"),
        accessor: "name",
      },
    {
      Header: langString("company"),
      accessor: "company",
    },
    {
      Header: langString("role"),
      accessor: "role",
    },
    {
      Header: langString("designation"),
      accessor: "designation",
    },
    {
      Header: langString("message"),
      accessor: "message",
    },
   
  ];