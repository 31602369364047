import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ccdb from "assets/images/ccdb.png";
import ypsa from "assets/images/ypsa.png";
const Footer = () => {
  const classes = useStyles();
  return (
    <>
      <Grid
        xs={12}
        spacing={3}
        alignContent="center"
        justifyContent="center"
        className={classes.alignCenter}
      >
        <img className={classes.mx} width={35} src={ccdb} alt="Company Logo" />

        <img
          className={classes.mx}
          width={80}
          src="https://hotjobs.bdjobs.com/jobs/mpower/logonew.png"
          alt="Company Logo"
        />

        <img className={classes.mx} width={35} src={ypsa} alt="Company Logo" />
      </Grid>
    </>
  );
};

const useStyles = makeStyles({
  alignCenter: {
    margin: "20px",
    textAlign: "center",
  },
  mx: {
    marginLeft: "25px",
    marginRight: "25px",
  },
});

export default Footer;
