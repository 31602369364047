import { Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import MainCard from "ui-component/cards/MainCard";
import OtherCertificationList from "./List";
import EditOtherCertifications from "./Edit";
import AddOtherCertification from "./AddOtherCertificate";
import AddBoxIcon from "@mui/icons-material/AddBox";
import langString from "utils/langString";

export type OtherCertificationType = {
  id: number;
  view_in_cv: boolean;
  view_in_profile: boolean;
  course_name: string;
  organization_name: string;
  completion_date: string | Date | null;
};

type OtherCertificationsProps = {
  certifications: OtherCertificationType[];
  userUpdate: () => void;
};

export default function OtherCertifications({
  certifications,
  userUpdate,
}: OtherCertificationsProps) {
  const [enableEdit, setEnableEdit] = useState(false);
  const [addForm, setAddForm] = useState(false);
  return (
    <>
      <MainCard>
        <Grid container>
          <Grid item xs={9}>
            <Typography variant="h4" sx={{ marginBottom: "10px" }}>
              {langString("othercertifications")}
            </Typography>
          </Grid>
          <Grid item xs={3} textAlign="right">
            {!enableEdit && (
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={() => setEnableEdit(true)}
              >
                {langString("edit")}
              </Button>
            )}
          </Grid>
        </Grid>
        {enableEdit ? (
          <EditOtherCertifications
            userUpdate={userUpdate}
            certifications={certifications}
          />
        ) : (
          <OtherCertificationList certifications={certifications} />
        )}
        {addForm && (
          <Grid container>
            <Grid item xs={12}>
              <AddOtherCertification
                certificates={certifications}
                closeAddForm={() => setAddForm(false)}
                userUpdate={() => userUpdate()}
              />
            </Grid>
          </Grid>
        )}

        <Grid container>
          <Grid item xs={12} textAlign="right">
            {enableEdit && (
              <Button
                size="small"
                color="success"
                variant="contained"
                onClick={() => setEnableEdit(false)}
              >
                {langString("done")}
              </Button>
            )}
            {!addForm && !enableEdit && (
              <Button
                size="small"
                color="info"
                variant="outlined"
                onClick={() => setAddForm(true)}
              >
                <AddBoxIcon />
              </Button>
            )}
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
}
