import { Checkbox, Grid, TextField, Typography } from "@mui/material";
import DisabledSelectField from "components/FormFields/DisabledSelectForm";
import SelectField from "components/FormFields/SelectField";
import { useSelector } from "react-redux";
import { RootState } from "store";
import MainCard from "ui-component/cards/MainCard";
import formateSelectFieldValues from "utils/formateSelectFieldValues";
import langString from "utils/langString";
import useStyle from "./style";

const TargetGroup = ({ formik }: any) => {
  const { degreeType, location } = useSelector(
    (state: RootState) => state.config
  );

  const degreeTypes = formateSelectFieldValues("Degree Type All", degreeType!);
  let locations: any = location?.filter(
    (value: { geo_definition: { node_name: string } }) =>
      value.geo_definition.node_name === "thana"
  );
  locations = formateSelectFieldValues("Thana All", locations!);

  const classes = useStyle();

  return (
    <>
      <Grid item xs={12} sm={6}>
        <MainCard className={classes.h}>
          <Typography variant="h4" gutterBottom>
            {langString("TargetGroup")}
          </Typography>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              md={12}
              alignItems="center"
              sx={{ display: "flex" }}
            >
              <Checkbox
                name="is_yes_certified_required"
                checked={
                  formik.values.is_yes_certified_required
                    ? formik.values.is_yes_certified_required
                    : false
                }
                onChange={(e, v) => {
                  formik.handleChange(e);
                }}
              />
              {formik.values.is_yes_certified_required ? (
                <Typography variant="h4" gutterBottom>
                  {langString("YesCertified")}
                </Typography>
              ) : (
                <Typography variant="h4" gutterBottom color="gray">
                  {langString("YesCertified")}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              alignItems="center"
              sx={{ display: "flex" }}
            >
              <Checkbox
                name="is_degree"
                checked={
                  formik?.values?.is_degree ? formik?.values?.is_degree : false
                }
                onChange={(e, v) => {
                  formik.handleChange(e);
                }}
              />
              {formik?.values?.is_degree ? (
                <SelectField
                  labelName={langString("degrees")}
                  name="degrees"
                  defaultValue={formik.values.degrees}
                  handleChange={formik.handleChange}
                  menuItems={degreeTypes}
                />
              ) : (
                <DisabledSelectField
                  labelName={langString("degrees")}
                  name="degrees"
                  defaultValue={formik.values.degrees}
                  handleChange={formik.handleChange}
                  value={[
                    { value: "", name: "None" },
                    { value: "1", name: "Job1" },
                  ]}
                />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              alignItems="center"
              sx={{ display: "flex" }}
            >
              <Checkbox
                name="is_gender"
                checked={
                  formik?.values?.is_gender ? formik?.values?.is_gender : false
                }
                onChange={(e, v) => {
                  formik.handleChange(e);
                }}
              />
              {formik?.values?.is_gender ? (
                <SelectField
                  labelName={langString("gender")}
                  name="gender"
                  defaultValue={formik.values.gender}
                  handleChange={formik.handleChange}
                  menuItems={[
                    { value: "", name: langString("select") },
                    { value: "male", name: langString("male") },
                    { value: "female", name: langString("female") },
                    { value: "other", name: langString("other") },
                  ]}
                />
              ) : (
                <DisabledSelectField
                  labelName="Gender"
                  name="gender"
                  defaultValue={formik.values.gender}
                  handleChange={formik.handleChange}
                  value={[
                    { value: "", name: "Select" },
                    { value: "1", name: "male" },
                    { value: "2", name: "female" },
                    { value: "3", name: "other" },
                  ]}
                />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              alignItems="center"
              sx={{ display: "flex" }}
            >
              <Checkbox
                name="is_locations"
                checked={
                  formik?.values?.is_locations
                    ? formik?.values?.is_locations
                    : false
                }
                onChange={(e, v) => {
                  formik.handleChange(e);
                }}
              />
              {formik?.values?.is_locations ? (
                <SelectField
                  labelName={langString("locations")}
                  name="locations"
                  defaultValue={formik.values.locations}
                  handleChange={formik.handleChange}
                  menuItems={locations!}
                />
              ) : (
                <DisabledSelectField
                  labelName={langString("locations")}
                  name="locations"
                  defaultValue={formik.values.locations}
                  handleChange={formik.handleChange}
                  value={[
                    { value: "", name: "Select locations" },
                    { value: "1", name: "Job1" },
                  ]}
                />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              alignItems="center"
              sx={{ display: "flex"}}
            >
              <Checkbox
                name="is_age"
                checked={
                  formik?.values?.is_age ? formik?.values?.is_age : false
                }
                onChange={(e, v) => {
                  formik.handleChange(e);
                }}
              />
              {formik?.values?.is_age ? (
                <>
                  <TextField
                    type="number"
                    fullWidth
                    size="small"
                    id="outlined-basic"
                    label={<>{langString("minAge")}<Typography component="span" color="red"> *</Typography></>}
                    variant="outlined"
                    onChange={formik.handleChange}
                    name="age_min"
                    value={
                      formik?.values?.age_min === 0
                        ? null
                        : formik?.values?.age_min
                    }
                    InputLabelProps={{ shrink: true }}
                    error={
                      formik.touched.age_min &&
                      Boolean(formik.errors.age_min)
                    }
                    helperText={
                      formik.touched.age_min && formik.errors.age_min
                    }
                  />
                  <Typography
                    variant="caption"
                    gutterBottom
                    className={classes.mx}
                  >
                    To
                  </Typography>
                  <TextField
                    type="number"
                    fullWidth
                    size="small"
                    id="outlined-basic"
                    label={<>{langString("maxAge")}<Typography component="span" color="red"> *</Typography></>}
                    variant="outlined"
                    onChange={formik.handleChange}
                    name="age_max"
                    value={
                      formik?.values?.age_max === 0
                        ? null
                        : formik?.values?.age_max
                    }
                    InputLabelProps={{ shrink: true }}
                    error={
                      formik.touched.age_max &&
                      Boolean(formik.errors.age_max)
                    }
                    helperText={
                      formik.touched.age_max && formik.errors.age_max
                    }

                  />
                </>
              ) : (
                <>
                  <TextField
                    disabled
                    type="number"
                    fullWidth
                    size="small"
                    id="outlined-basic"
                    label={<>{langString("minAge")}<Typography component="span" color="red"> *</Typography></>}
                    variant="outlined"
                    onChange={formik.handleChange}
                    name="age_min"
                    helperText={
                      formik.touched.age_min && formik.errors.age_min
                    }
                    error={
                      formik.touched.age_min &&
                      Boolean(formik.errors.age_min)
                    }

                  />
                  <Typography
                    variant="caption"
                    gutterBottom
                    className={classes.mx}
                  >
                    To
                  </Typography>
                  <TextField
                    disabled
                    type="number"
                    fullWidth
                    size="small"
                    id="outlined-basic"
                    label={<>{langString("maxAge")}<Typography component="span" color="red"> *</Typography></>}
                    variant="outlined"
                    onChange={formik.handleChange}
                    name="age_max"
                    helperText={
                      formik.touched.age_max && formik.errors.age_max
                    }
                    error={
                      formik.touched.age_max &&
                      Boolean(formik.errors.age_max)
                    }
                  />
                </>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              alignItems="center"
              sx={{ display: "flex" }}
            >
              <Checkbox
                name="is_experience"
                checked={
                  formik?.values?.is_experience
                    ? formik?.values?.is_experience
                    : false
                }
                onChange={(e, v) => {
                  formik.handleChange(e);
                }}
              />
              {formik.values.is_experience ? (
                <TextField
                  type="number"
                  fullWidth
                  size="small"
                  id="outlined-basic"
                  label={langString("experience")}
                  variant="outlined"
                  onChange={formik.handleChange}
                  name="experience"
                  InputLabelProps={{ shrink: true }}
                  value={
                    formik?.values?.experience
                      ? formik?.values?.experience
                      : null
                  }
                  helperText={
                    formik.touched.experience && formik.errors.experience
                  }
                  error={
                    formik.touched.experience &&
                    Boolean(formik.errors.experience)
                  }
                />
              ) : (
                <TextField
                  disabled
                  type="number"
                  fullWidth
                  size="small"
                  id="outlined-basic"
                  label={<>{langString("experience")}<Typography component="span" color="red"> *</Typography></>}
                  variant="outlined"
                  onChange={formik.handleChange}
                  name="experience"
                  helperText={
                    formik.touched.experience && formik.errors.experience
                  }
                  error={
                    formik.touched.experience &&
                    Boolean(formik.errors.experience)
                  }
                />
              )}
            </Grid>
          </Grid>
        </MainCard>
      </Grid>
    </>
  );
};

export default TargetGroup;
