import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import langString from "./langString";

export default function dateFormat(date: string) {
  if (date) {
    const format = dayjs(date.split("T")[0]).format("DD/MM/YYYY");
    return format ? format : "";
  }
  return langString("notspecified");
}

export function getTime(dateTime: string) {
  const date = dateTime.split("T")[1].split(":");
  date.pop();
  return date.join(":");
}

export function getTimeAMOrPM(dateTime: string) {
  return new Date(dateTime)
    .toLocaleTimeString()
    .replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3");
}

export function isBefore(toTime: string, fromTime: string) {
  if (toTime === "" || fromTime === "") {
    return false;
  }
  const date1 = dayjs(fromTime);
  const date2 = dayjs(toTime);

  return date1.isBefore(date2);
}

export function dateTimeDuration(date: string) {
  dayjs.extend(relativeTime);
  return dayjs().to(dayjs(date));
}
export function dateTimeFormat(date: string) {
  dayjs.extend(relativeTime);
  return dayjs(date).format("h:mm:ss a - dddd, MMMM D YYYY");
}
export function isPrevDate(date: string) {
  dayjs.extend(relativeTime);
  return !dayjs().isBefore(dayjs(date).add(1, 'day'))
}

export function getDate(date: string) {
  dayjs.extend(relativeTime);
  return dayjs(date).format("dddd, MMMM D YYYY");
}
