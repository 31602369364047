import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { OtherCertificationType } from "./index";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import coreAxios from "utils/axios";
import { checkNullData } from "utils/dataHelper";
import { useSelector } from "react-redux";
import { RootState } from "store";
import langString from "utils/langString";

type EditOtherCertificationPropsType = {
  certifications: OtherCertificationType[];
  userUpdate: () => void;
};

export default function EditOtherCertifications({
  certifications,
  userUpdate,
}: EditOtherCertificationPropsType) {
  const { user } = useSelector((status: RootState) => status.account);

  const updateOtherCertification = (id: number, key: string) => {
    let allCertificates: OtherCertificationType[] = [...certifications];
    let allItems: OtherCertificationType[] = [];
    const certificate = allCertificates.find(
      (item: OtherCertificationType) => item.id === id
    );
    if (certificate) {
      allCertificates.forEach((item: OtherCertificationType) => {
        if (item.id === certificate.id) {
          if (key === "view_in_cv") {
            certificate.view_in_cv = !certificate.view_in_cv;
            allItems.push(certificate);
          } else {
            certificate.view_in_profile = !certificate.view_in_profile;
            allItems.push(certificate);
          }
        } else {
          allItems.push(item);
        }
      });
    }
    if (user) {
      const formData = new FormData();
      formData.append(
        "job_seeker_profile",
        JSON.stringify({ other_certifications: allItems })
      );
      coreAxios
        .patch(`/users/job-seekers/${user?.id}/`, formData)
        .then((res) => {
          if (res.status === 200) {
            userUpdate();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <>
      <>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  {langString("course")} {langString("name")}
                </TableCell>
                <TableCell>
                  {langString("organization")} {langString("course")}
                </TableCell>
                <TableCell>
                  {langString("completion")} {langString("date")}
                </TableCell>
                <TableCell>{langString("action")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {certifications &&
                certifications.map((certificate) => (
                  <TableRow
                    key={certificate.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {certificate.course_name}
                    </TableCell>
                    <TableCell>
                      {checkNullData(certificate.organization_name)}
                    </TableCell>
                    <TableCell>
                      {checkNullData(certificate.completion_date)}
                    </TableCell>
                    <TableCell>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={certificate.view_in_cv}
                              onChange={() =>
                                updateOtherCertification(
                                  certificate.id,
                                  "view_in_cv"
                                )
                              }
                            />
                          }
                          label={langString("viewincv")}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={certificate.view_in_profile}
                              onChange={() =>
                                updateOtherCertification(
                                  certificate.id,
                                  "view_in_profile"
                                )
                              }
                            />
                          }
                          label={langString("viewinprofile")}
                        />
                      </FormGroup>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    </>
  );
}
