export type selectFieldValues = {
  value: string|number,
  name: string | number,
}

const formateSelectFieldValues = (name:string,values: any[]): selectFieldValues[] => {
    const value = values?.map((item: any) => {
        return {
          value: item.id,
          name: item.name,
        };
      });
      return [{ value: "", name: name }, ...value!];
};

export default formateSelectFieldValues;