import {
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import MainCard from "ui-component/cards/MainCard";
import coreAxios from "utils/axios";
import { OtherCertificationType } from "./index";
import { useSelector } from "react-redux";
import { RootState } from "store";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import dayjs from "dayjs";
import langString from "utils/langString";

type AddOtherCertificationPropsType = {
  closeAddForm: () => void;
  userUpdate: () => void;
  certificates: OtherCertificationType[];
};

export default function AddOtherCertification({
  closeAddForm,
  certificates,
  userUpdate,
}: AddOtherCertificationPropsType) {
  const { user } = useSelector((status: RootState) => status.account);

  const [empty, setEmpty] = useState(false);
  const [org, setOrg] = useState("");
  const [course, setCourse] = useState("");
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const saveNewCertificate = () => {
    if (org && course && endDate) {
      setEmpty(false);
      const allCertificates: OtherCertificationType[] = [...certificates];
      allCertificates.push({
        id: 0,
        organization_name: org,
        course_name: course,
        completion_date: endDate && dayjs(endDate).format("YYYY-MM-DD"),
        view_in_profile: true,
        view_in_cv: true,
      });
      if (user) {
        const formData = new FormData();
        formData.append(
          "job_seeker_profile",
          JSON.stringify({ other_certifications: allCertificates })
        );
        coreAxios
          .patch(`/users/job-seekers/${user.id}/`, formData)
          .then((res) => {
            if (res.status === 200) {
              userUpdate();
              closeAddForm();
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } else {
      setEmpty(true);
    }
  };

  return (
    <MainCard>
      <Grid container>
        <Grid item xs={6} sx={{ marginBottom: 1 }}>
          <Typography variant="h4">
            {langString("addnew")} {langString("certificate")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          textAlign="right"
          sx={{ marginTop: 1, marginBottom: 2 }}
        >
          <Button
            size="small"
            color="inherit"
            variant="contained"
            onClick={() => closeAddForm()}
          >
            {langString("close")}
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={4} marginY={{ xs: 1 }}>
          <FormControl>
            <TextField
              id="org"
              name="org"
              label={`${langString("organization")} ${langString("name")}*`}
              onChange={(event: any) => setOrg(event.target.value)}
              value={org}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} marginY={{ xs: 1 }}>
          <FormControl>
            <TextField
              id="course"
              name="course"
              label={`${langString("course")}*`}
              onChange={(event: any) => setCourse(event.target.value)}
              value={course}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} marginY={{ xs: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label={`${langString("enddate")}*`}
              inputFormat="MM/dd/yyyy"
              value={endDate ? endDate : new Date()}
              onChange={(value: Date | null) => setEndDate(value)}
              renderInput={(params: any) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} textAlign="right" sx={{ marginTop: 1 }}>
          <Button
            size="small"
            color="success"
            variant="contained"
            onClick={() => saveNewCertificate()}
          >
            {langString("save")}
          </Button>
        </Grid>
      </Grid>
      {empty && (
        <Grid container>
          <Grid item xs={12} textAlign="center" sx={{ marginTop: 1 }}>
            <strong style={{ color: "red" }}>
              {langString("pleasefillallthefields")}
            </strong>
          </Grid>
        </Grid>
      )}
    </MainCard>
  );
}
