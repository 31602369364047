// material-ui
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Stack,
} from "@mui/material";

import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";

// project imports
import { useTable } from "react-table";
import MainCard from "ui-component/cards/MainCard";
import { gridSpacing } from "store/constant";
import AnimateButton from "ui-component/extended/AnimateButton";
import langString from "utils/langString";

// Props type
interface columns {
  Header: string;
  accessor: string;
}

interface data {
  si: number;
  company: string;
  job_id: string;
  job_category: string;
  job_type: string;
  location: string;
  posting_date: string | number;
  deadline: string | number;
  application_status: string;
  vacancy: number;
}

type TableBasicProps = {
  columns: columns[];
  data: data[];
};

// ==============================|| TABLE - BASIC ||============================== //

export default function TableBasic({ columns, data }: TableBasicProps) {
  const { getTableProps, headerGroups, rows, prepareRow } = useTable<any>({
    columns,
    data,
  });
  const navigate = useNavigate();
  const classes = useStyle();
  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <MainCard content={false}>
          {/* table */}
          <TableContainer>
            <Grid
              container
              spacing={3}
              alignItems="center"
              justifyContent="space-between"
              style={{
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingTop: "10px",
              }}
            >
              <Grid item xs={6} sm={3}>
                <h3>
                  {langString("PostedJobs")} [ {rows?.length} ]
                </h3>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Stack textAlign="end">
                  <AnimateButton>
                    <Button
                      variant="contained"
                      onClick={() => navigate("/employer/create-job")}
                    >
                      {langString("CreateJobPost")}
                    </Button>
                  </AnimateButton>
                </Stack>
              </Grid>
            </Grid>
            <Table
              {...getTableProps()}
              sx={{ minWidth: 350 }}
              aria-label="simple table"
            >
              <TableHead>
                {headerGroups.map((headerGroup: any) => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => (
                      <TableCell {...column.getHeaderProps()} align="center">
                        {column.render("Header")}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <TableRow
                      className={classes.rowsHover}
                      {...row.getRowProps()}
                      onClick={
                        () => {}
                        // navigate(`/job-market/${row.original.job_id}`)
                      }
                    >
                      {row.cells.map((cell) => {
                        return (
                          <TableCell {...cell.getCellProps()} align="center">
                            {cell.render("Cell")}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </MainCard>
      </Grid>
    </Grid>
  );
}

const useStyle = makeStyles({
  rowsHover: {
    cursor: "pointer",
    borderBottom: "10px solid white",
    backgroundColor: "#eeeeee",
    "&:hover": {
      backgroundColor: "#bdbdbd",
    },
  },
});
