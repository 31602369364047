import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { OtherCertificationType } from "./index";
import { checkNullData } from "utils/dataHelper";
import langString from "utils/langString";

type OtherCertificationListPropsType = {
  certifications: OtherCertificationType[];
};

export default function OtherCertificationList({
  certifications,
}: OtherCertificationListPropsType) {
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                {langString("course")} {langString("name")}
              </TableCell>
              <TableCell>
                {langString("organization")} {langString("course")}
              </TableCell>
              <TableCell>
                {langString("completion")} {langString("date")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {certifications &&
              certifications.map(
                (certificate) =>
                  certificate.view_in_profile && (
                    <TableRow
                      key={certificate.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {certificate.course_name}
                      </TableCell>
                      <TableCell>
                        {checkNullData(certificate.organization_name)}
                      </TableCell>
                      <TableCell>
                        {checkNullData(certificate.completion_date)}
                      </TableCell>
                    </TableRow>
                  )
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
