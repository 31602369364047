import * as yup from "yup";

const validationSchema = yup.object({
    description: yup
        .string()
        .min(3, "Too Short")
        .max(50, "Too Long!")
        .required("Description is required"),
    deadline: yup
        .date()
        .required("Deadline is required"),
    salary_range_min: yup.number().positive("Min Salary Range must be a positive number"),
    salary_range_max: yup
        .number().positive("Max Salary Range must be a positive number")
        .moreThan(yup.ref('salary_range_min'), "Max should be more then min"),
    vacancy: yup.number().positive("Vacancy must be a positive number"),
});

export default validationSchema;
