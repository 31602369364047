import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Hidden,
  Typography
} from "@mui/material";
import Badges from "components/Badges";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "store";
import { loadUser } from "store/createAction/auth";
import coreAxios from "utils/axios";
import langString from "utils/langString";
import getAge from "../../../utils/getAge";
import { makeRecomendations, makeSkills } from "./DataHelper";
import Educations, { EducationDataType } from "./Education";
import Experiences, { ExperienceType } from "./Experiences";
import OtherCertifications, {
  OtherCertificationType
} from "./OtherCertifications";
import UserPersonalDetails from "./Personal";
import { PersonalInfoType } from "./Personal/PersonalInfo";
import Recommendations, { RecommendationType } from "./Recommendations";
import Skills, { SkillType } from "./Skills";
import YesCertifications, { YesCertificationType } from "./YesCertifications";

export default function JobSeekerProfile() {
  const { user } = useSelector((status: RootState) => status.account);
  const location = useSelector((state: RootState) => state.config.location);

  const dispatch = useDispatch();
  const [
    personalDetails,
    setPersonalDetails,
  ] = useState<PersonalInfoType | null>(null);
  const [recommendations, setRecommendations] = useState<RecommendationType[]>(
    []
  );
  const [educations, setEducations] = useState<EducationDataType[]>([]);
  const [personalSkills, setPersonalSkills] = useState<SkillType[]>([]);
  const [technicalSkills, setTechnicalSkills] = useState<SkillType[]>([]);
  const [yesCertifications, setYesCertifications] = useState<
    YesCertificationType[]
  >([]);
  const [otherCertifications, setOtherCertifications] = useState<
    OtherCertificationType[]
  >([]);
  const [experiences, setExperiences] = useState<ExperienceType[]>([]);
  const [available, setAvailable] = useState<boolean>(true);
  const [badges, setBadges] = useState<any[]>([]);
  const navigate = useNavigate();
  const downloadCV = () => {
    coreAxios
      .get("/users/cv/")
      .then((res) => {
        if (res.data.status === 200) {
          window.open(res.data.data.url, "_blank");
        }
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const changeAvailability = () => {
    if (user) {
      const formData = new FormData();
      formData.append(
        "job_seeker_profile",
        JSON.stringify({ available_status: !available })
      );
      coreAxios
        .patch(`/users/job-seekers/${user.id}/`, formData)
        .then((res) => {
          if (res.status === 200) {
            setAvailable(!available);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const userStateUpdate = () => {
    if (user) {
      coreAxios
        .get(`/users/job-seekers/${user.id}`)
        .then((res) => {
          if (res.data.status === 200) {
            const jobSeekerProfile = res.data.data.job_seeker_profile;
            setPersonalDetails({
              picture: jobSeekerProfile.picture,
              name: jobSeekerProfile.name,
              dob: jobSeekerProfile.dob,
              age: getAge(jobSeekerProfile.dob),
              gender: jobSeekerProfile.gender,
              phone: jobSeekerProfile.phone,
              email: jobSeekerProfile.email,
            });
            setAvailable(jobSeekerProfile.available_status);
            setBadges(jobSeekerProfile.badges);
            setRecommendations(
              makeRecomendations(jobSeekerProfile.recommendations)
            );
            setEducations(jobSeekerProfile.degrees);
            setTechnicalSkills(makeSkills(jobSeekerProfile.technical_skills));
            setPersonalSkills(makeSkills(jobSeekerProfile.personal_skills));
            setYesCertifications([
              ...jobSeekerProfile.yes_trainings,
              ...jobSeekerProfile.yes_courses,
            ]);
            setOtherCertifications(jobSeekerProfile.other_certifications);
            setExperiences(jobSeekerProfile.experiences);
            dispatch(loadUser());
          }
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    if (user) {
      coreAxios
        .get(`/users/job-seekers/${user.id}`)
        .then((res) => {
          if (res.data.status === 200) {
            const jobSeekerProfile = res.data.data.job_seeker_profile;

            const address :any = location?.find((value: any)=>value?.id === jobSeekerProfile.address);

            setPersonalDetails({
              picture: jobSeekerProfile.picture,
              name: jobSeekerProfile.name,
              dob: jobSeekerProfile.dob,
              age: getAge(jobSeekerProfile.dob),
              gender: jobSeekerProfile.gender,
              phone: jobSeekerProfile.phone,
              email: jobSeekerProfile.email,
              address: address?.name ? address?.name : ''
            });
            setRecommendations(
              makeRecomendations(jobSeekerProfile.recommendations)
            );
            setEducations(jobSeekerProfile.degrees);
            setTechnicalSkills(makeSkills(jobSeekerProfile.technical_skills));
            setPersonalSkills(makeSkills(jobSeekerProfile.personal_skills));
            setYesCertifications([
              ...jobSeekerProfile.yes_trainings,
              ...jobSeekerProfile.yes_courses,
            ]);
            setOtherCertifications(jobSeekerProfile.other_certifications);
            setExperiences(jobSeekerProfile.experiences);
          }
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  }, [user, location]);

  return (
    <>
      <Typography variant="h2" sx={{ marginBottom: 1 }}>
        {langString("jobseeker")} {langString("profile")}
      </Typography>
      <Grid container xs={12} spacing={3}>
        <Grid item xs={12} sm={8}>
          {personalDetails && (
            <UserPersonalDetails
              personalDetails={personalDetails}
              badges={badges}
              editable={true}
              userUpdate={() => userStateUpdate()}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          <Badges badges={badges}/>
        </Grid>
        <Grid item xs={12}>
          <Recommendations
            recommendations={recommendations}
            userUpdate={() => userStateUpdate()}
          />
        </Grid>
        <Grid item xs={12}>
          <Educations
            educations={educations}
            userUpdate={() => userStateUpdate()}
          />
        </Grid>
        <Grid item xs={12}>
          <Skills
            userUpdate={() => userStateUpdate()}
            personalSkills={personalSkills}
            technicalSkills={technicalSkills}
          />
        </Grid>
        <Grid item xs={12}>
          <YesCertifications
            certifications={yesCertifications}
            userUpdate={() => userStateUpdate()}
          />
        </Grid>
        <Grid item xs={12}>
          <OtherCertifications
            certifications={otherCertifications}
            userUpdate={() => userStateUpdate()}
          />
        </Grid>
        <Grid item xs={12}>
          <Experiences
            experiences={experiences}
            userUpdate={() => userStateUpdate()}
          />
        </Grid>
        <Hidden mdDown>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox checked={available} onChange={changeAvailability} />
                  }
                  label={langString("availableforpublicviewandmessageing")}
                />
              </Grid>
              <Grid item xs={6} textAlign="right">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => navigate(`/job-seeker/public-profile`)}
                  sx={{ marginLeft: 5 }}
                >
                  {langString("viewpublicprofile")}
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => downloadCV()}
                  sx={{ marginLeft: 5 }}
                >
                  {langString("downloadcv")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden mdUp>
        <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox checked={available} onChange={changeAvailability} />
                  }
                  label={langString("availableforpublicviewandmessageing")}
                />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => navigate(`/job-seeker/public-profile`)}
                  sx={{ marginLeft: 5 }}
                >
                  {langString("viewpublicprofile")}
                </Button>
                
              </Grid>
              <Grid item xs={12} textAlign="center">
              <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => downloadCV()}
                  sx={{ marginLeft: 5 }}
                >
                  {langString("downloadcv")}
                </Button>
                
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
        
      </Grid>
    </>
  );
}
