import React from "react";

// material-ui
import {
  Button,
  IconButton,
  Modal,
  Typography,
  CardProps,
  Stack,
} from "@mui/material";

// project imports
import MainCard from "ui-component/cards/MainCard";
import { saveJob } from "store/createAction/createJob";
// assets
import CloseIcon from "@mui/icons-material/Close";
import AnimateButton from "ui-component/extended/AnimateButton";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";


export interface BodyProps extends CardProps {
  handleClose: () => void;
  classes: any;
  navigate: NavigateFunction;
}

const Body = React.forwardRef(
  (
    { handleClose, classes, navigate }: BodyProps,
    ref: React.Ref<HTMLDivElement>
  ) => (
    <div ref={ref} tabIndex={-1}>
      <MainCard
        sx={{
          position: "absolute",
          width: { xs: 280, lg: 450 },
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        title=" "
        secondary={
          <IconButton onClick={handleClose} size="large">
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      >
        <div className={classes.textCenter}>
          <Typography variant="h3">
            To post a Job circular, you need to signup/login to the system.
          </Typography>
          <div className={classes.itemCenter}>
            <Stack direction="column">
              <AnimateButton>
                <Button variant="contained" type="button">
                  SIGN UP
                </Button>
              </AnimateButton>
            </Stack>
            <Stack direction="column">
              <AnimateButton>
                <Button
                  variant="contained"
                  type="button"
                  onClick={() => {
                    navigate("/?job=create-job");
                  }}
                >
                  LOG IN
                </Button>
              </AnimateButton>
            </Stack>
          </div>
        </div>
      </MainCard>
    </div>
  )
);

// ==============================|| SIMPLE MODAL ||============================== //

export default function SimpleModal({ formData }: any) {

  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyle();
  const handleOpen = () => {
    dispatch(saveJob(formData));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="contained" type="button" onClick={handleOpen}>
        OK
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Body handleClose={handleClose} classes={classes} navigate={navigate} />
      </Modal>
    </>
  );
}

const useStyle = makeStyles({
  textCenter: {
    textAlign: "center",
    padding: 10,
  },
  itemCenter: {
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: 20,
  },
});
