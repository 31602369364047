import dayjs from "dayjs";
import * as yup from "yup";

const validationSchema = yup.object({
    description: yup
        .string()
        .min(3, "Too Short")
        .max(50, "Too Long!")
        .required("Description is required"),
    category: yup.string().required(),
    type: yup.string().required(),
    deadline: yup
        .date()
        .required("Deadline is required")
        .test("deadline", "Please choose a valid deadline", (value) => {
            return !dayjs().isAfter(value, "date");
        }),
    salary_range_min: yup.number().positive("Min Salary Range must be a positive number"),
    salary_range_max: yup
        .number().positive("Max Salary Range must be a positive number")
        .moreThan(yup.ref('salary_range_min'), "Max should be more then min"),
    age_min: yup.number().positive("Positive number").required("Age min is required"),
    age_max: yup
        .number().positive("Positive number")
        .moreThan(yup.ref('age_min'), "More then min Age").required("Age max is required"),
    experience: yup.number().positive("Positive number").required("Experience is required"),
    vacancy: yup.number().positive("Vacancy must be a positive number"),
});

export default validationSchema;
