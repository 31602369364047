// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import { getMenuItems } from 'menu-items';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const {user} = useSelector((state:RootState)=> state.account);
    
    const navItems = getMenuItems(user?.user_authority?.name)?.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
