import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import AllJobs from "./AllJobs";
import Applied from "./Applied";
import { useSelector } from "react-redux";
import { RootState } from "store";

// assets

// tab content
function TabPanel(props: {
  children: React.ReactElement | string;
  value: number;
  index: number;
}) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 3,
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// ================================|| UI TABS - SAMPLE ||================================ //

export default function JobMarketTabs() {
  const theme = useTheme();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const [value, setValue] = useState(0);
  const { user } = useSelector((status: RootState) => status.account);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!user || params.tab === "1") {
      setValue(1);
    } else {
      setValue(0);
    }
  }, [params.tab, user]);

  return (
    <>
      <Tabs
        value={value}
        variant="scrollable"
        onChange={handleChange}
        sx={{
          mb: 3,
          "& a": {
            minHeight: "auto",
            minWidth: 10,
            py: 1.5,
            px: 1,
            mr: 2.2,
            color: theme.palette.grey[600],
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          },
          "& a.Mui-selected": {
            color: theme.palette.primary.main,
          },
          "& a > svg": {
            mb: "0px !important",
            mr: 1.1,
          },
        }}
      >
        {user && (
          <Tab component={Link} to="#" label="Applied To" {...a11yProps(0)} />
        )}

        <Tab component={Link} to="#" label="All Job Posts" {...a11yProps(1)} />
      </Tabs>
      {user && (
        <TabPanel value={value} index={0}>
          <Applied />
        </TabPanel>
      )}
      <TabPanel value={value} index={1}>
        <AllJobs />
      </TabPanel>
    </>
  );
}
