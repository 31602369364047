import { useSelector } from "react-redux";

// material-ui
import { Grid, TextField } from "@mui/material";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
// project imports
import MainCard from "ui-component/cards/MainCard";
import { gridSpacing } from "store/constant";

// third-party
import { makeStyles } from "@mui/styles";
import { RootState } from "store";
import SingleSelect from "components/FormFields/SingleSelect";
import { ApplicationStatus } from "utils/Constants";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import langString from "utils/langString";
// ==============================|| FORM VALIDATION - LOGIN FORMIK  ||============================== //

type AppliedFilterPropsType = {
  category: number;
  startDate: Date | null;
  endDate: Date | null;
  search: string;
  application: string;
  setCategory: (value: number) => void;
  setStartDate: (value: Date | null) => void;
  setEndDate: (value: Date | null) => void;
  setApplication: (value: string) => void;
  setSearch: (value: string) => void;
};

const AppliedFilter = ({
  category,
  startDate,
  endDate,
  search,
  application,
  setCategory,
  setStartDate,
  setEndDate,
  setApplication,
  setSearch,
}: AppliedFilterPropsType) => {
  const { jobCategory: categories } = useSelector(
    (status: RootState) => status.config
  );
  const classes = useStyle();

  return (
    <MainCard className={classes.pt}>
      <Grid
        container
        spacing={gridSpacing}
        direction="row"
        alignItems="flex-end"
      >
        <Grid item xs={12}>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12} sm={4}>
              <SingleSelect
                name="category"
                label={langString("job") + " " + langString("category")}
                handleChange={(value: any) => setCategory(value)}
                values={categories ? categories : []}
                value={category}
                valueKey="id"
                nameKey="name"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SingleSelect
                name="application_status"
                label={langString("application") + " " + langString("status")}
                value={application}
                handleChange={(value: string) => setApplication(value)}
                values={ApplicationStatus}
                valueKey="key"
                nameKey="value"
              />
            </Grid>
          </Grid>
          <Grid container spacing={gridSpacing} style={{ marginTop: "10px" }}>
            <Grid item xs={12} sm={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label={langString("startdate")}
                  inputFormat="MM/dd/yyyy"
                  value={startDate}
                  onChange={(newValue: Date | null) => setStartDate(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label={langString("enddate")}
                  inputFormat="MM/dd/yyyy"
                  value={endDate}
                  onChange={(newValue: Date | null) => setEndDate(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                size="small"
                id="outlined-basic"
                label={langString("search")}
                variant="outlined"
                value={search}
                onChange={(event: any) => setSearch(event.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default AppliedFilter;

const useStyle = makeStyles({
  pt: {
    paddingTop: "10px",
  },
  mt: {
    marginTop: "65px",
  },
  searchForm: {
    position: "absolute",
    top: "255px",
    right: "16px",
  },
});
