import { Avatar, CardContent, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import MainCard from "ui-component/cards/MainCard";
import langString from "utils/langString";
export default function Badges({badges}:any) {
  return (
    <>
      <MainCard content={false}>
        <CardContent>
          <Typography variant="h4" sx={{ marginBottom: "10px" }}>
            {langString("badges")}
          </Typography>
          <Grid container spacing={3}>
          {badges && badges.length
                ? badges.map((badge: any) => (            
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item>
                      <Box sx={{ position: "relative" }}>
                        <Avatar color="success" src={badge.badgeimage} alt={badge.description}/>
                      </Box>
                    </Grid>
                    <Grid item xs zeroMinWidth>
                      <Grid container spacing={1}>
                        <Grid item xs zeroMinWidth>
                          <Typography
                            align="left"
                            component="div"
                            variant="subtitle1"
                          >
                            {badge.name}
                          </Typography>
                          <Typography
                            align="left"
                            component="div"
                            variant="subtitle2"
                          >
                            {badge.description}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                )):(
                <Grid item xs zeroMinWidth>
                    <Grid container spacing={1}>
                      <Grid item xs zeroMinWidth>
                        <Typography
                            align="left"
                            component="div"
                            variant="subtitle1"
                          >
                            {langString("nobadge")}
                        </Typography> 
                      </Grid>
                    </Grid>
                </Grid>
                )
              }

          </Grid>
        </CardContent>
      </MainCard>
    </>
  );
}
