import { Avatar, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { employerT } from "types/employer";
import MainCard from "ui-component/cards/MainCard";
import langString from "utils/langString";
import setPicturePath from "utils/setPictureUrl";

type companyDetailsT = {
  userInfo: employerT;
};

export default function CompanyDetails({ userInfo }: companyDetailsT) {
  return (
    <>
      <MainCard>
        <Typography variant="h2" margin="10px 0px">
          {langString("CompanyDetails")}
        </Typography>
        <Grid container xs={12}>
          <Grid
            item
            display={{ xs: "inline", sm: "none" }}
            xs={12}
            sm={6}
            justifyItems="center"
          >
            <Avatar
              src={setPicturePath(userInfo?.company_profile?.logo) || ""}
              alt="company img"
              sx={{ width: 150, height: 150, margin: "auto" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="body1"
              fontSize={18}
              margin="10px 0px"
              className="textCap"
            >
              {langString("name")}: {userInfo?.company_profile?.name || ""}
            </Typography>
            <Typography variant="body1" fontSize={18} margin="10px 0px">
              {langString("YearofEst")}:{" "}
              {userInfo?.company_profile?.year_of_establishment || ""}
            </Typography>
            <Typography
              variant="body1"
              fontSize={18}
              margin="10px 0px"
              className="textCap"
            >
              {langString("Location")}:{" "}
              {userInfo?.company_profile?.address?.name || ""}
            </Typography>
            <Typography
              variant="body1"
              fontSize={18}
              margin="10px 0px"
              className="textCap"
            >
              {langString("Industrytype")}:{" "}
              {userInfo?.company_profile?.industry_type?.name || ""}
            </Typography>
          </Grid>
          <Grid
            item
            display={{ xs: "none", sm: "inline" }}
            xs={12}
            sm={6}
            justifyItems="center"
          >
            <Avatar
              src={setPicturePath(userInfo?.company_profile?.logo) || ""}
              alt="company img"
              sx={{ width: 150, height: 150, margin: "auto" }}
            />
          </Grid>
          <Grid container xs={12}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h2" margin="10px 0px">
                {langString("Contactinfo")}
              </Typography>
              <Typography
                variant="body1"
                fontSize={18}
                margin="10px 0px"
                className="textCap"
              >
                {langString("name")}:{" "}
                {userInfo?.company_profile?.contact_person[0]?.name || ""}
              </Typography>
              <Typography
                variant="body1"
                fontSize={18}
                margin="10px 0px"
                className="textCap"
              >
                {langString("Designation")}:{" "}
                {userInfo?.company_profile?.contact_person[0]?.designation ||
                  ""}
              </Typography>
              <Typography variant="body1" fontSize={18} margin="10px 0px">
                {langString("email")}:{" "}
                {userInfo?.company_profile?.contact_person[0]?.email || ""}
              </Typography>
              <Typography variant="body1" fontSize={18} margin="10px 0px">
                {langString("Phone")}:{" "}
                {userInfo.company_profile?.contact_person[0]?.phone || ""}
              </Typography>
            </Grid>
          </Grid>
          <Typography
            sx={{ margin: "30px 0px" }}
            component={Link}
            to={`/sign-up/employer?id=${userInfo.id || ""}`}
            style={{ fontSize: "20px", textDecoration: "none" }}
          >
            {langString("Editinformation")}
          </Typography>
        </Grid>
      </MainCard>
    </>
  );
}
