import { Grid, Switch } from "@mui/material";
import { makeStyles } from "@mui/styles";
import logo from "assets/images/logo.png";
import usaid from "assets/images/usaid.png";
import { useEffect, useState } from "react";
import { gridSpacing } from "store/constant";

const Header = () => {
  const classes = useStyles();

  const [checked, setChecked] = useState(false);
  const changeLanguage = (value: boolean) => {
    if (value) {
      setChecked(true);
      localStorage.setItem("language", "bn");
    } else {
      setChecked(false);
      localStorage.setItem("language", "en");
    }
    window.location.reload();
  };

  useEffect(() => {
    const lang = localStorage.getItem("language");
    if (!lang) {
      localStorage.setItem("language", "bn");
      setChecked(true);
    }
    if (lang && lang === "bn") setChecked(true);
  }, []);

  return (
    <>
      <Grid container spacing={gridSpacing} className={classes.header}>
        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs={2}>
              {/* <ButtonWithEndIcon
                name={langString("tutorial")}
                variant="contained"
                endIcon={<PlayArrowIcon />}
              /> */}
            </Grid>
            <Grid item className={classes.displayFlex}>
              <Grid
                item
                justifySelf="center"
                alignSelf="center"
                className="spaceBetween"
              >
                <img width="100%" src={usaid} alt="USAID Icon" loading="lazy" />
              </Grid>
              <Grid
                item
                justifySelf="center"
                alignSelf="center"
                className="spaceBetween"
              >
                <img width="70%" src={logo} alt="RI Icon" loading="lazy" />
              </Grid>
            </Grid>
            <Grid item justifyContent="center" alignItems="center">
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>English</Grid>
                <Grid item>
                  <Switch
                    checked={checked}
                    onChange={(event: any) =>
                      changeLanguage(event.target.checked)
                    }
                    size="medium"
                  />
                </Grid>
                <Grid item>Bangla</Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles({
  header: {
    marginBottom: "20px",
  },
  displayFlex: {
    display: "flex",
    "& .spaceBetween": {
      margin: "0px 6px",
      "& img": {
        minWidth: "36px",
        maxWidth: "128px",
      },
    },
  },
});

export default Header;
