import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';

// import AuthContext from 'contexts/Auth0Context';
import { LOGOUT } from 'store/actions';
// import AuthContext from 'contexts/JWTContext';

// ==============================|| AUTH HOOKS ||============================== //

const useAuth = () => {
    const [isLoading, setLoading] = useState(true); 
    const account = useSelector((state: RootState) => state.account);
    const dispatch = useDispatch();
    if (!account) throw new Error('context must be use inside provider');

    const logout = () => {
        dispatch({ type: LOGOUT });
    }
    useEffect(()=>{
        if(typeof account.user !== "undefined"){
            setLoading(false);
        }
    },[account.user]);
    return { ...account,isLoading, logout };
};

export default useAuth;
