import Home from "components/Home";
import { useSelector } from "react-redux";
import { RootState } from "store";
import langString from "utils/langString";
import setPicturePath from "utils/setPictureUrl";
const EmployerHome = () => {
  const { user } = useSelector((state: RootState) => state.account);

  return (
    <>
      <Home
        name={user?.profile?.name !== null && user?.profile?.name !== undefined ? user?.profile?.name : "null"}
        img={setPicturePath(user?.profile?.logo ? user?.profile?.logo : "")}
        navData={employerHome}
      />
    </>
  );
};

export default EmployerHome;

const employerHome = [
  {
    itemsId: 1,
    items: [
      {
        id: "profile",
        name: langString('profile'),
        path: "/employer/profile",
      },
      {
        id: "post-jobs",
        name: `${langString('post')} ${langString('jobs')}`,
        path: "/employer/post-jobs",
      },
    ],
  },
  {
    itemsId: 2,
    items: [
      {
        id: "job-seekers",
        name: `${langString('job')} ${langString('seekers')}`,
        path: "/employer/job-seekers",
      },
      {
        id: "messages",
        name: langString('messages'),
        path: "/messages",
      },
    ],
  },
  {
    itemsId: 3,
    items: [
      {
        id: "events",
        name: langString('events'),
        path: "/events",
      },
      {
        id: "feedback",
        name: langString('feedback'),
        path: "/feedback",
      },
    ],
  },
];
