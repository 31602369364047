import { UserProfileType } from "../../types/UserProfile";
import { useEffect, useState } from "react";
import RequirementsCard from "./RequirementsCard";
import langString from "utils/langString";

export type RequirementsType = {
  name: string;
  isMatch: boolean;
};
type TrainingPropsType = {
  yes_trainings: any[];
  yes_courses: any[];
  profile?: UserProfileType | null;
};

const Trainings = ({
  yes_trainings,
  yes_courses,
  profile,
}: TrainingPropsType) => {
  const [requirements, setRequirements] = useState<RequirementsType[]>([]);

  useEffect(() => {
    const allRequirements: RequirementsType[] = [];
    if (yes_trainings && profile) {
      const profileTrainings = profile.yes_trainings?.map(
        (item: any) => item.id
      );
      yes_trainings.forEach((element: any) => {
        if (profileTrainings?.includes(element.id)) {
          allRequirements.push({ name: element.name, isMatch: true });
        } else {
          allRequirements.push({ name: element.name, isMatch: false });
        }
      });
    }
    if (yes_courses && profile) {
      const profileCourses = profile.yes_courses?.map((item: any) => item.id);
      yes_courses.forEach((element: any) => {
        if (profileCourses?.includes(element.id)) {
          allRequirements.push({ name: element.name, isMatch: true });
        } else {
          allRequirements.push({ name: element.name, isMatch: false });
        }
      });
    }

    setRequirements(allRequirements);
  }, [profile, yes_trainings, yes_courses]);

  return (
    <RequirementsCard
      title={langString("trainingcourserequirements")}
      requirements={requirements}
    />
  );
};

export default Trainings;
