import { Avatar, Chip, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "store";
import arrayOfObjectToArray from "utils/arrayOfObjectToArray";
import { checkNullData } from "utils/dataHelper";
import langString from "utils/langString";

type ListPropsType = {
  application: any;
};

export default function ApplicantList({ application }: ListPropsType) {
  const {
    job_seeker,
    application_status,
    special_status_list,
    id,
    interview_date,
    location,
  } = application;

  const { location: locations } = useSelector(
    (state: RootState) => state.config
  );
  const printAddress = (locationId: any): any => {
    if (locations) {
      for (let i = 0; i < locations.length; i++) {
        const loc: any = locations?.[i];

        if (loc?.id === locationId)
          return loc?.name + ", " + printAddress(loc?.parent);
      }
    }
    return "";
  };
  const address = location ? printAddress(location.id) : "";
  const classes = useStyle();

  const chipColor = (name: string) => {
    if (name === "yes-certified" || name === "rejected") {
      return "#e84118";
    } else if (name === "perfect-match" || name === "pending") {
      return "#3498db";
    } else {
      return "#2ecc71";
    }
  };

  return (
    <Box
      style={{
        textDecoration: "none",
      }}
      component={Link}
      to={`/employer/applicant/${job_seeker?.user}/?jobApplied=${id}`}
    >
      <Box
        boxShadow={2}
        style={{
          margin: "5px 0px",
          backgroundColor: "white",
          borderRadius: "10px",
          textDecoration: "none",
        }}
        className={classes.boxHover}
      >
        <Grid
          container
          spacing={1}
          xs={12}
          sx={{ padding: "5px" }}
          alignItems={"center"}
        >
          <Grid item xs={12} sm={2}>
            <Typography variant="h4" textAlign="center" className="textCap">
              {job_seeker?.name || "Job Seeker Name"}
            </Typography>
            <Avatar
              src={job_seeker?.picture?.replace("media", "api/v1/media") || ""}
              alt="company img"
              sx={{
                width: 60,
                height: 60,
                margin: "0px auto",
                marginBottom: "20px",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body2" margin="2px 0px" className="textCap">
              {langString("expertise")}:{" "}
              {job_seeker?.technical_skills.length &&
                job_seeker.technical_skills.map((item: any) => (
                  <strong key={item.id}>{item.name}, </strong>
                ))}
              {job_seeker?.personal_skills.length &&
                job_seeker.personal_skills.map((item: any) => (
                  <strong key={item.id}>{item.name}, </strong>
                ))}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography
              variant="body2"
              margin="2px 0px"
              overflow="hidden"
              textOverflow="ellipsis"
              className="textCap"
            >
              {langString("training")} -{" "}
              {arrayOfObjectToArray(job_seeker?.yes_trainings!, "name").join(
                ", "
              ) || "null"}
            </Typography>
            <Typography
              variant="body2"
              margin="2px 0px"
              overflow="hidden"
              textOverflow="ellipsis"
              className="textCap"
            >
              {langString("courses")} -{" "}
              {arrayOfObjectToArray(job_seeker?.yes_courses!, "name").join(
                ", "
              ) || "null"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2} style={{ marginBottom: "5px" }}>
            {special_status_list.length &&
              special_status_list.map((item: any, index: number) => (
                <Chip
                  key={index}
                  label={item.toUpperCase()}
                  size="medium"
                  sx={{
                    backgroundColor: chipColor(item),
                    fontSize: 12,
                    fontWeight: "bold",
                    color: "white",
                    padding: "3px",
                    margin: "2px",
                  }}
                />
              ))}
          </Grid>
          <Grid item xs={3} style={{ marginBottom: "5px" }}>
            <Typography
              variant="h3"
              margin="2px 0px"
              className="textCap"
              textAlign="center"
              color={chipColor(checkNullData(application_status))}
            >
              {checkNullData(application_status)}!
            </Typography>
            {checkNullData(application_status) === "selected" ? (
              <>
                <Typography
                  variant="h6"
                  margin="2px 0px"
                  className="textCap"
                  color={"gray"}
                >
                  <span style={{ fontWeight: "bold", fontSize: "13px" }}>
                    {langString("interview")} {langString("on")}:{" "}
                  </span>
                  {interview_date ? interview_date : ""}
                </Typography>
                <Typography
                  variant="h6"
                  margin="2px 0px"
                  className="textCap"
                  color={"gray"}
                >
                  <span style={{ fontWeight: "bold", fontSize: "13px" }}>
                    {langString("location")}:{" "}
                  </span>
                  {address ? address : ""}
                </Typography>
              </>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

const useStyle = makeStyles({
  boxHover: {
    "&:hover": {
      boxShadow: "0px 2px 5px gray",
    },
  },
});
