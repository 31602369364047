import { useMemo, useEffect, useState } from "react";
import AppliedFilter from "./FilterForm";
import coreAxios from "utils/axios";
import BasicDataTable from "components/BasicDataTable";
import MainCard from "ui-component/cards/MainCard";
import { Typography } from "@mui/material";
import langString from "utils/langString";

const Applied = () => {
  const [jobPosts, setJobPosts] = useState<any[]>([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [category, setCategory] = useState(0);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [search, setSearch] = useState("");
  const [application, setApplication] = useState("");

  // server request
  useEffect(() => {
    const params: any = {
      limit: rowsPerPage,
      offset: page * 10,
    };
    if (category) params.category = category;
    if (startDate) params.start_date = startDate;
    if (endDate) params.end_date = endDate;
    if (search) params.search = search;
    if (application && application !== "0")
      params.application_status = application;

    coreAxios
      .get("/jobs/job-posts/applications/own/", {
        params: params,
      })
      .then((res) => {
        if (res.data.status === 200) {
          setCount(res.data.data.count);
          setJobPosts(res.data.data.results);
        }
      })
      .catch((e: any) => {
        console.log(e);
      });
  }, [page, rowsPerPage, category, startDate, endDate, search, application]);

  const columns = useMemo(
    () => [
      {
        Header: langString("sl"),
        accessor: "sl",
      },
      {
        Header: langString("company"),
        accessor: "company",
      },
      {
        Header: langString("job") + " " + langString("id"),
        accessor: "jobId",
      },
      {
        Header: langString("job") + " " + langString("category"),
        accessor: "job_category",
      },
      {
        Header: langString("applied") + " " + langString("on"),
        accessor: "applied_on",
      },
      {
        Header: langString("interview") + " " + langString("date"),
        accessor: "interview_date",
      },
      {
        Header: langString("status"),
        accessor: "application_status",
      },
      {
        Header: langString("vacancy"),
        accessor: "vacancy",
      },
    ],
    []
  );

  return (
    <>
      <AppliedFilter
        category={category}
        setCategory={(value: number) => setCategory(value)}
        startDate={startDate}
        setStartDate={(value: Date | null) => setStartDate(value)}
        endDate={endDate}
        setEndDate={(value: Date | null) => setEndDate(value)}
        search={search}
        setSearch={(value: string) => setSearch(value)}
        application={application}
        setApplication={(value: string) => setApplication(value)}
      />
      {jobPosts ? (
        <BasicDataTable
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={(value: number) => setPage(value)}
          setRowsPerPage={(value: number) => setRowsPerPage(value)}
          columns={columns}
          rows={jobPosts}
          link="/job-market/"
          linkID="job_post_id"
        />
      ) : (
        <MainCard>
          <Typography variant="h4" sx={{ margin: 3, textAlign: "center" }}>
            "Loading..."
          </Typography>
        </MainCard>
      )}
    </>
  );
};

export default Applied;
