import { NavigateFunction } from "react-router-dom";
import { Dispatch } from "redux";
import { SNACKBAR_OPEN } from "store/actions";
import coreAxios from "utils/axios";

export const onReject = async (
  jobApplied: string,
  navigate: NavigateFunction,
  dispatch: Dispatch<any>,
  handleClose: any
) => {
  try {
    const res = await coreAxios.patch(
      `/jobs/job-posts/applications/${jobApplied}/`,
      {
        application_status: "rejected",
      }
    );
    if (res.status === 200) {
      navigate(-1);
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: "Rejected successful",
        variant: "alert",
        alertSeverity: "error",
      });
    }
  } catch (error: any) {
    if (error.response) {
      const message = error.response.data.data.detail;
      handleClose();
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message,
        variant: "alert",
        alertSeverity: "error",
      });
    }
  }
};
