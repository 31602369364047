import { Button, Grid } from "@mui/material";
import Table, { dataT } from "components/Table";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "store";
import MainCard from "ui-component/cards/MainCard";
import coreAxios from "utils/axios";
import langString from "utils/langString";
import { formattingData } from "./formattingData";
import { messageColumn } from "./messageColumns";
import SearchForm from "./SearchForm";

export default function Messaging() {
  const [messages, setMessages] = useState<dataT[]>([]);
  const user = useSelector(
    (state: RootState) => state.account.user?.user_authority?.name
  );
  useEffect(() => {
    coreAxios
      .get(`/message-management/windows/`)
      .then((res) => {
        if (res.status === 200) {
          // data formate
          const messages = formattingData(res.data.data, user!);
          // set state
          if (messages.length !== 0) {
            setMessages(messages);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [user]);
  const tableAction = () => {
    return {
      Header: langString("actions"),
      accessor: "actions",
      Cell: ({
        cell: {
          row: { original },
        },
      }: any) => {
        return (
          <>
            <Button
              component={Link}
              to={`/messages/chat/?windowId=${original?.id}`}
              size="small"
            >
              View
            </Button>
          </>
        );
      },
    };
  };
  const messageColumns = React.useMemo(
    () => [...messageColumn, tableAction()],
    []
  );
  const messagesData = React.useMemo(() => messages, [messages]);

  return (
    <>
      <MainCard>
        <Grid
          container
          spacing={1}
          direction="row"
          alignItems="center"
          justifyContent={"end"}
        >
          <Grid item xs={12} sm={4}>
            <SearchForm
              setStateFunc={setMessages}
              pathName="/message-management/windows/"
            />
          </Grid>
        </Grid>
      </MainCard>
      <Table
        columns={messageColumns}
        data={messagesData}
        title={langString("MESSAGELIST")}
      />
    </>
  );
}
