import Home from "components/Home";
import { useSelector } from "react-redux";
import { RootState } from "store";
import langString from "utils/langString";
import setPicturePath from "utils/setPictureUrl";
const JobSeekerHome = () => {
  const { user } = useSelector((state: RootState) => state.account);

  return (
    <>
      <Home
        name={user?.username}
        img={setPicturePath(
          user?.profile?.picture! ? user?.profile?.picture! : ""
        )}
        navData={JobSeekerItem}
      />
    </>
  );
};

export default JobSeekerHome;

const JobSeekerItem = [
  {
    itemsId: 1,
    items: [
      {
        id: "profile",
        name: langString('profile'),
        path: "/job-seeker/profile",
      },
      {
        id: "job-market",
        name: `${langString('find')} ${langString('jobs')}`,
        path: "/job-market/",
      },
    ],
  },
  {
    itemsId: 2,
    items: [
      {
        id: "companies",
        name: langString('companies'),
        path: "/job-seeker/companies",
      },
      {
        id: "messages",
        name: langString('messages'),
        path: "/messages",
      },
    ],
  },
  {
    itemsId: 3,
    items: [
      {
        id: "events",
        name: langString('events'),
        path: "/events",
      },
      {
        id: "feedback",
        name: langString('feedback'),
        path: "/feedback",
      },
    ],
  },
];
