// material-ui
import { Button, Grid, Stack, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SelectField from "components/FormFields/SelectField";
// third-party
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { SNACKBAR_OPEN } from "store/actions";
import { gridSpacing } from "store/constant";
// project imports
import MainCard from "ui-component/cards/MainCard";
import AnimateButton from "ui-component/extended/AnimateButton";
import coreAxios from "utils/axios";
import formateSelectFieldValues from "utils/formateSelectFieldValues";
import langString from "utils/langString";




// ==============================|| FORM VALIDATION - LOGIN FORMIK  ||============================== //

const FilterForm = ({ setPostedJob, userId }: any) => {
  const { jobCategory } = useSelector((status: RootState) => status.config);
  const classes = useStyle();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      postMinDate: "",
      postMaxDate: "",
      status: "",
      deadlineMinDate: "",
      deadlineMaxDate: "",
      jobCategory: "",
    },
    onSubmit: async (values) => {
      try {
        if (typeof userId === "number" && userId > 0) {
          const result = await coreAxios.get(
            `/users/employers/${userId}/job-posts/?category=${values.jobCategory}&posted_start_date=${values.postMinDate}&posted_end_date=${values.postMaxDate}&status=${values.status}&start_date=${values.deadlineMinDate}&end_date=${values.deadlineMaxDate}`
          );

          if (result?.status === 200) {
            if(result?.data?.data.count === 0){
              dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: "Data not found",
                variant: "alert",
                alertSeverity: "error",
              });
            }
            setPostedJob(result?.data?.data);
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
  });

  const jobCategories = formateSelectFieldValues(
    "Job Category All",
    jobCategory!
  );
  // const jobTypes = formateSelectFieldValues("Job Type All", jobType!);

  return (
    <MainCard className={classes.pt}>
      <Grid
        container
        spacing={gridSpacing}
        direction="row"
        alignItems="flex-end"
      >
        <Grid item xs={12} sm={10}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={gridSpacing} alignItems="center">
              <Grid item xs={12} sm={2}>
                <Typography variant="body1">
                  {langString("PostingDateRange")}
                </Typography>
              </Grid>
              <Grid item xs={5} sm={2}>
                <TextField
                  fullWidth
                  size="small"
                  name="postMinDate"
                  type="date"
                  defaultValue={new Date()}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={2} sm={1}>
                <Typography variant="body1" textAlign="center">
                  {langString("To")}
                </Typography>
              </Grid>
              <Grid item xs={5} sm={2}>
                <TextField
                  fullWidth
                  size="small"
                  name="postMaxDate"
                  type="date"
                  defaultValue={new Date()}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <SelectField
                  name="status"
                  labelName={langString("status")}
                  handleChange={formik.handleChange}
                  menuItems={[
                    { value: "", name: "Status All" },
                    { value: "active", name: "active" },
                    { value: "expired", name: "expired" },
                  ]}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={gridSpacing}
              alignItems="center"
              style={{ marginTop: "10px" }}
            >
              <Grid item xs={12} sm={2}>
                <Typography variant="body1">
                  {langString("DeadlineDateRange")}
                </Typography>
              </Grid>
              <Grid item xs={5} sm={2}>
                <TextField
                  fullWidth
                  size="small"
                  name="deadlineMinDate"
                  type="date"
                  defaultValue={new Date()}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={2} sm={1}>
                <Typography variant="body1" textAlign="center">
                  {langString("To")}
                </Typography>
              </Grid>
              <Grid item xs={5} sm={2}>
                <TextField
                  fullWidth
                  size="small"
                  name="deadlineMaxDate"
                  type="date"
                  defaultValue={new Date()}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <SelectField
                  name="jobCategory"
                  labelName={langString("JobCategory")}
                  handleChange={formik.handleChange}
                  menuItems={jobCategories}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Stack>
                  <AnimateButton>
                    <Button variant="contained" type="submit">
                      {langString("Filter")}
                    </Button>
                  </AnimateButton>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default FilterForm;

const useStyle = makeStyles({
  pt: {
    paddingTop: "10px",
  },
  mt: {
    marginTop: "65px",
  },
  searchForm: {
    position: "absolute",
    top: "255px",
    right: "16px",
  },
});
