// material-ui
import { Card, CardContent, Grid, Theme, Typography } from "@mui/material";
import React, { useEffect } from "react";
// project imports
import { gridSpacing } from "store/constant";
import { dateTimeFormat } from "utils/dateFormate";



// ==============================|| CHAT MESSAGE HISTORY ||============================== //

export type History = {
  owner?: boolean;
  created_at: string;
  message: string;
};

export interface ChartHistoryProps {
  dataMessage: History[];
  fetchMore: () => void;
  scrollToBottom: () => void;
  theme: Theme;
  wrapper: React.MutableRefObject<HTMLDivElement>;
  count:number;
}

const ChartHistory = ({
  dataMessage,
  theme,
  fetchMore,
  scrollToBottom,
  wrapper,
  count
}: ChartHistoryProps) => {

  useEffect(() => {
    if (dataMessage.length <= 10) {
      scrollToBottom();
    }
  }, [dataMessage.length, scrollToBottom]);

  // useEffect(() => {
  //   const scroller = document.querySelector("#scrollTopGetData")!;
  //   scroller?.addEventListener("scroll", (event) => {
  //     if (scroller.scrollTop === 0) {
  //       fetchMore();
  //       scroller.scrollTo(0, scroller.clientHeight);
  //     }
  //   });
  //   // eslint-disable-next-line
  // }, []);

  return (
    <Grid item xs={12}>
      <Grid container spacing={gridSpacing} ref={wrapper}>
        {dataMessage &&
          dataMessage.map((history, index) => (
            <React.Fragment key={index}>
              {history.owner ? (
                <Grid item xs={12}>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={2} />
                    <Grid item xs={10}>
                      <Card
                        sx={{
                          display: "inline-block",
                          float: "right",
                          bgcolor:
                            theme.palette.mode === "dark"
                              ? "grey.600"
                              : theme.palette.primary.light,
                        }}
                      >
                        <CardContent
                          sx={{
                            p: 2,
                            pb: "16px !important",
                            width: "fit-content",
                            ml: "auto",
                          }}
                        >
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <Typography
                                variant="body2"
                                color={
                                  theme.palette.mode === "dark"
                                    ? "dark.900"
                                    : ""
                                }
                              >
                                {history.message}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                align="right"
                                variant="subtitle2"
                                color={
                                  theme.palette.mode === "dark"
                                    ? "dark.900"
                                    : ""
                                }
                              >
                                {dateTimeFormat(history.created_at)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} sm={7}>
                      <Card
                        sx={{
                          display: "inline-block",
                          float: "left",
                          background:
                            theme.palette.mode === "dark"
                              ? theme.palette.dark[900]
                              : theme.palette.secondary.light,
                        }}
                      >
                        <CardContent sx={{ p: 2, pb: "16px !important" }}>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <Typography variant="body2">
                                {history.message}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography align="right" variant="subtitle2">
                                {dateTimeFormat(history.created_at)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </React.Fragment>
          ))}
      </Grid>
    </Grid>
  );
};

export default ChartHistory;
