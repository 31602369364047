export default function setPicturePath(picPath: string) {
  if (!picPath) {
    return "";
  } else if (picPath.includes("http")) {
    return picPath;
  }
  return `${process?.env?.REACT_APP_SERVICE_URL}${picPath
    ?.split("/")
    .filter((v) => v !== "")
    .join("/")}`;
}
