import { NavItemType } from "types";
import { employer, jobSeeker, systemAdmin } from "./other";
// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [employer],
};

export const getMenuItems = (userAuthority: string | undefined) => {
  if (userAuthority === "employer") {
    return [employer];
  } else if (userAuthority === "job-seeker") {
    return [jobSeeker];
  } else if (userAuthority === "system-admin") {
    return [systemAdmin];
  }
};

export default menuItems;
