import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { YesCertificationType } from "./index";
import { checkNullData } from "utils/dataHelper";
import langString from "utils/langString";

type YesCertificationListPropsType = {
  certifications: YesCertificationType[];
};

export default function YesCertificationList({
  certifications,
}: YesCertificationListPropsType) {
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{langString("name")}</TableCell>
              <TableCell>{langString("program")}</TableCell>
              <TableCell>
                {langString("completion")} {langString("date")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {certifications &&
              certifications.map(
                (certificate) =>
                  certificate.view_in_profile && (
                    <TableRow
                      key={certificate.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {certificate.name}
                      </TableCell>
                      <TableCell>
                        {checkNullData(certificate.program)}
                      </TableCell>
                      <TableCell>
                        {checkNullData(certificate.completion_date)}
                      </TableCell>
                    </TableRow>
                  )
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
