// material-ui
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { makeStyles } from "@mui/styles";

// project imports
import { useTable } from "react-table";
import MainCard from "ui-component/cards/MainCard";
import { gridSpacing } from "store/constant";

// Props type
interface columns {
  Header: string;
  accessor: string;
}

interface Education {
  degree: string;
  subject: string;
  institute: string;
  result: string;
  yearOfCompletion: string;
  view_in_cv?: boolean;
  view_in_profile?: boolean;
}

interface YesCertification {
  courseName: string;
  program: string;
  date: string;
  view_in_cv?: boolean;
  view_in_profile?: boolean;
}
interface OtherCertifications {
  courseName: string;
  organization: string;
  date: string;
  view_in_cv?: boolean;
  view_in_profile?: boolean;
}
interface Experience {
  company: string;
  position: string;
  duration: string;
  year: string;
  view_in_cv?: boolean;
  view_in_profile?: boolean;
}

export interface Message {
  id: number;
  sl: number;
  name: string;
  company: string;
  role: string;
  designation: string;
  message: string;
}

export type dataT =
  | Education
  | YesCertification
  | OtherCertifications
  | Experience
  | Message
  | Experience;

type TableBasicProps = {
  columns: columns[];
  data: dataT[];
  title: string;
};

// ==============================|| TABLE - BASIC ||============================== //

export default function TableBasic({ title, columns, data }: TableBasicProps) {
  const { getTableProps, headerGroups, rows, prepareRow } = useTable<any>({
    columns,
    data,
  });

  const classes = useStyle();
  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <MainCard content={true}>
          {/* table */}
          <TableContainer>
            <Grid
              container
              spacing={3}
              alignItems="center"
              justifyContent="space-between"
              style={{
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingTop: "10px",
              }}
            >
              <Grid item xs={3}>
                <Typography variant="h4" sx={{ marginBottom: "10px" }}>
                  {title}
                </Typography>
              </Grid>
            </Grid>
            <Table
              {...getTableProps()}
              sx={{ minWidth: 350 }}
              aria-label="simple table"
            >
              <TableHead>
                {headerGroups.map((headerGroup: any) => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => (
                      <TableCell {...column.getHeaderProps()} align="center">
                        {column.render("Header")}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <TableRow
                      className={classes.rowsHover}
                      {...row.getRowProps()}
                      onClick={
                        () => {}
                        // navigate(`/job-market/${row.original.job_id}`)
                      }
                    >
                      {row.cells.map((cell) => {
                        return (
                          <TableCell {...cell.getCellProps()} align="center">
                            {cell.render("Cell")}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </MainCard>
      </Grid>
    </Grid>
  );
}

const useStyle = makeStyles({
  rowsHover: {
    cursor: "pointer",
    borderBottom: "10px solid white",
    backgroundColor: "#eeeeee",
    "&:hover": {
      backgroundColor: "#bdbdbd",
    },
  },
});
