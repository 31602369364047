import { RecommendationType } from "components/Card/Recommendations";
import { SkillType } from "components/Card/Skills";

export const makeSkills = (data: any[]) => {
  const skills: SkillType[] = [];
  if (data.length) {
    data.forEach((item: any) =>
      skills.push({
        id: item.id,
        name: item.name,
      })
    );
  }
  return skills;
};

export const makeRecomendations = (data: any[]) => {
  const recoms: RecommendationType[] = [];
  if (data.length) {
    data.forEach((item: any) =>
      recoms.push({
        id: item.id,
        message: item.recommendation,
        view_in_cv: item.view_in_cv,
        view_in_profile: item.view_in_profile,
        name: item.company?.contact_person[0]?.name,
        position: item.company?.contact_person[0]?.designation,
        company: item.company?.name,
        year: item.company?.year_of_establishment,
      })
    );
  }

  return recoms;
};
