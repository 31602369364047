export function formattingDataRecommendations(data: any[]) {
  return data.map((item) => {
    return {
      id: item?.id ? item?.id : "null",
      message: item?.recommendation ? item?.recommendation : "null",
      view_in_cv: item?.view_in_cv ? item?.view_in_cv : false,
      view_in_profile: item?.view_in_profile ? item?.view_in_profile : false,
      name: item?.company?.contact_person?.[0].name
        ? item?.company?.contact_person?.[0].name
        : "null",
      year: item?.company?.year_of_establishment
        ? item?.company?.year_of_establishment
        : "null",
      company: item?.company?.name ? item?.company?.name : "null",
      position: item?.company?.contact_person?.[0].designation
        ? item?.company?.contact_person?.[0].designation
        : "null",
    };
  });
}

export function formattingDataEducations(data: any[]) {
  return data?.map((item) => ({
    degree: item?.degree_type?.name ? item?.degree_type?.name : "null",
    subject: item.degree_group?.name ? item.degree_group?.name : "null",
    institute: item?.institute ? item?.institute : "null",
    result: item?.result ? item?.result : "null",
    yearOfCompletion: item?.year_of_completion
      ? item?.year_of_completion
      : "null",
    view_in_cv: item?.view_in_cv ? item?.view_in_cv : false,
    view_in_profile: item?.view_in_profile ? item?.view_in_profile : false,
  }));
}

export function formattingDataYesCertification(
  yesCourses: any[],
  yesTrainings: any[]
) {
  const courses = yesCourses.map((item) => {
    return {
      courseName: item?.name ? item?.name : "null",
      program: "YES eLearning",
      date: item?.completion_date ? item?.completion_date : "null",
      view_in_cv: item?.view_in_cv ? item?.view_in_cv : false,
      view_in_profile: item?.view_in_profile ? item?.view_in_profile : false,
    };
  });
  const trainings = yesTrainings.map((item) => {
    return {
      courseName: item?.name ? item?.name : "null",
      program: "YES Training",
      date: item?.completion_date ? item?.completion_date : "null",
      view_in_cv: item?.view_in_cv ? item?.view_in_cv : false,
      view_in_profile: item?.view_in_profile ? item?.view_in_profile : false,
    };
  });
  return [...courses, ...trainings];
}

export function formattingDataOtherCertifications(data: any[]) {
  return data?.map((item) => ({
    courseName: item?.course_name ? item?.course_name : "null",
    organization: item.organization_name ? item.organization_name : "null",
    date: item?.completion_date ? item?.completion_date : null,
    view_in_cv: item?.view_in_cv ? item?.view_in_cv : false,
    view_in_profile: item?.view_in_profile ? item?.view_in_profile : false,
  }));
}

export function formattingDataExperience(data: any[]) {
  return data?.map((item) => ({
    company: item?.organization_name ? item?.organization_name : "null",
    position: item?.position ? item?.position : "null",
    duration: item?.start_date ? item?.start_date : "null",
    year: item?.end_date ? item?.end_date : "null",
    view_in_cv: item?.view_in_cv ? item?.view_in_cv : false,
    view_in_profile: item?.view_in_profile ? item?.view_in_profile : false,
  }));
}
