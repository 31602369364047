import { Grid, Paper, Typography } from "@mui/material";
import React from "react";
import MainCard from "ui-component/cards/MainCard";
import langString from "utils/langString";

type DashboardPropT = {
  current_applicants: number;
  job_posts: number;
  selected_applicants: number;
};

export default function Dashboard({
  current_applicants,
  job_posts,
  selected_applicants,
}: DashboardPropT) {
  return (
    <>
      <MainCard>
        <Typography variant="h2" margin="5px 0px">
          {langString("Dashboard")}
        </Typography>
        <Grid
          container
          xs={12}
          spacing={3}
          justifyContent="center"
          margin="auto"
        >
          <Grid item xs={12}>
            <Paper
              style={{
                backgroundColor: "lightgray",
                width: "100%",
                padding: "5px",
              }}
            >
              <Typography variant="h3" margin="5px 10px">
                {langString("PostedJob")}
              </Typography>
              <Typography
                variant="h2"
                margin="30px 0px"
                textAlign="center"
                color="red"
              >
                {job_posts}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper
              style={{
                backgroundColor: "lightgray",
                width: "100%",
                padding: "5px",
              }}
            >
              <Typography variant="h3" margin="5px 10px">
                {langString("CurrentApplicants")}
              </Typography>
              <Typography
                variant="h2"
                margin="30px 0px"
                textAlign="center"
                color="red"
              >
                {current_applicants}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper
              style={{
                backgroundColor: "lightgray",
                width: "100%",
                padding: "5px",
              }}
            >
              <Typography variant="h3" margin="5px 10px">
                {langString("Selectforinterview")}
              </Typography>
              <Typography
                variant="h2"
                margin="30px 0px"
                textAlign="center"
                color="red"
              >
                {selected_applicants}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
}
