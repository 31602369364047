import useAuth from "hooks/useAuth";
import { Navigate } from "react-router";

const PrivateRoute = ({ component: Component }: any) => {
  const { isLoggedIn, isLoading } = useAuth();
  if(isLoading){
    return <h1>loading...</h1>
  }


  return isLoggedIn ? Component : <Navigate to="/" />;
};

export default PrivateRoute;
