export const dataFormat = (data: any) => {

    return ({
        description: data.description,
        category: data.category.id,
        vacancy: data.vacancy,
        type: data.type.id,
        salary_range_min: data.salary_range_min,
        salary_range_max: data.salary_range_max,
        deadline: data.deadline,
        personal_skills: data.personal_skills,
        technical_skills: data.technical_skills,
        yes_trainings: data.yes_trainings,
        yes_courses: data.yes_courses,
        age_max: data.target_group.age_max,
        age_min: data.target_group.age_min,
        degrees: data.target_group.degrees.length !== 0 ? data.target_group.degrees[0].id : "",
        gender: data.target_group.gender,
        experience: data.target_group.experience,
        locations: data.target_group?.locations.length !== 0 ? data.target_group?.locations?.[0].id : "",
        is_age_required: data.target_group.is_age_required,
        is_degree_required: data.target_group.is_degree_required,
        is_experience_required: data.target_group.is_experience_required,
        is_gender_required: data.target_group.is_gender_required,
        is_location_required: data.target_group.is_location_required,
        is_yes_certified_required: data.target_group.is_yes_certified_required,
    });
}