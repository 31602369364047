import { Button, Grid, Typography } from "@mui/material";
import JobApply from "components/JobCards/Apply";
import JobDescriptionCard from "components/JobCards/Description";
import useAuth from "hooks/useAuth";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { RootState } from "store";
import { gridSpacing } from "store/constant";
import coreAxios from "utils/axios";
import langString from "utils/langString";
import BasicRequirements from "../../components/JobCards/BasicRequirments";
import TargetGroupCard from "../../components/JobCards/TargetGroup";
import Trainings from "../../components/JobCards/Trainings";

const JobProfile = () => {
  const [job, setJob] = useState<any>(null);
  const { isLoggedIn } = useAuth();
  const { jobId } = useParams();
  const { user } = useSelector((status: RootState) => status.account);
  const navigate = useNavigate();

  // server request
  useEffect(() => {
    coreAxios
      .get(`/jobs/job-posts/${jobId}`)
      .then((res) => {
        if (res.data.status === 200) {
          setJob(res.data.data);
        }
      })
      .catch((e: any) => {
        console.log(e);
      });
  }, [jobId]);

  return (
    <>
      <Typography variant="h3" gutterBottom>
        {langString("job")} {langString("profile")}
      </Typography>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12} sm={8}>
          {job && <JobDescriptionCard job={job} />}
        </Grid>
        <Grid item xs={12} sm={4}>
          {job && <JobApply job={job} isLoggedIn={isLoggedIn} />}
        </Grid>
      </Grid>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12} sm={4}>
          <TargetGroupCard
            targetGroup={job && job.target_group ? job.target_group : null}
            profile={user?.profile ? user?.profile : null}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <BasicRequirements
            technical_skills={
              job && job.technical_skills ? job.technical_skills : []
            }
            personal_skills={
              job && job.personal_skills ? job.personal_skills : []
            }
            profile={user?.profile ? user?.profile : null}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Trainings
            profile={user?.profile ? user?.profile : null}
            yes_trainings={job && job.yes_trainings ? job.yes_trainings : []}
            yes_courses={job && job.yes_courses ? job.yes_courses : []}
          />
        </Grid>
      </Grid>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate(`/job-market`)}
          >
            {langString("back")}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default JobProfile;
