import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// material-ui
import { Grid, TextField } from "@mui/material";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
// project imports
import MainCard from "ui-component/cards/MainCard";
import { gridSpacing } from "store/constant";

// third-party
import { makeStyles } from "@mui/styles";
import { RootState } from "store";
import SingleSelect from "components/FormFields/SingleSelect";
import { ApplicationStatus } from "utils/Constants";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import langString from "utils/langString";
// ==============================|| FORM VALIDATION - LOGIN FORMIK  ||============================== //

type AllJobsFilterProps = {
  category: number;
  jobType: number;
  startDate: Date | null;
  endDate: Date | null;
  location: string;
  company: string;
  search: string;
  application: string;
  setCategory: (value: number) => void;
  setJobType: (value: number) => void;
  setStartDate: (value: Date | null) => void;
  setEndDate: (value: Date | null) => void;
  setCompany: (value: string) => void;
  setLocation: (value: string) => void;
  setApplication: (value: string) => void;
  setSearch: (value: string) => void;
};

const AllJobsFilter = ({
  category,
  jobType,
  startDate,
  endDate,
  location,
  company,
  search,
  application,
  setCategory,
  setJobType,
  setStartDate,
  setEndDate,
  setLocation,
  setCompany,
  setApplication,
  setSearch,
}: AllJobsFilterProps) => {
  const {
    jobCategory: categories,
    jobType: allJobTypes,
    location: allLocations,
    company: companies,
  } = useSelector((status: RootState) => status.config);
  const classes = useStyle();
  const [locations, setLocations] = useState<any[]>([]);
  useEffect(() => {
    const thanas: any[] = [];
    allLocations?.forEach((item: any) => {
      if (item.geo_definition.node_name === "thana") {
        thanas.push({ id: item.id, name: item.name });
      }
    });
    setLocations(thanas);
  }, [allLocations]);

  return (
    <MainCard className={classes.pt}>
      <Grid
        container
        spacing={gridSpacing}
        direction="row"
        alignItems="flex-end"
      >
        <Grid item xs={12}>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12} sm={3}>
              <SingleSelect
                name="category"
                label={langString("job") + " " + langString("category")}
                handleChange={(value: any) => setCategory(value)}
                values={categories ? categories : []}
                value={category}
                valueKey="id"
                nameKey="name"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <SingleSelect
                name="type"
                label={langString("job") + " " + langString("types")}
                handleChange={(value: any) => setJobType(value)}
                values={allJobTypes ? allJobTypes : []}
                value={jobType}
                valueKey="id"
                nameKey="name"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <SingleSelect
                name="location"
                label={langString("location")}
                value={location}
                handleChange={(value: any) => setLocation(value)}
                values={locations}
                valueKey="id"
                nameKey="name"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <SingleSelect
                name="company"
                label={langString("company")}
                value={company}
                handleChange={(value: any) => setCompany(value)}
                values={companies ? companies : []}
                valueKey="id"
                nameKey="name"
              />
            </Grid>
          </Grid>
          <Grid container spacing={gridSpacing} style={{ marginTop: "10px" }}>
            <Grid item xs={12} sm={3}>
              <SingleSelect
                name="application_status"
                label={langString("application") + " " + langString("status")}
                value={application}
                handleChange={(value: any) => setApplication(value)}
                values={ApplicationStatus}
                valueKey="key"
                nameKey="value"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                id="outlined-basic"
                size="small"
                label={langString("search")}
                variant="outlined"
                value={search}
                onChange={(event: any) => setSearch(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label={langString("startdate")}
                  inputFormat="MM/dd/yyyy"
                  value={startDate}
                  onChange={(newValue: Date | null) => setStartDate(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label={langString("enddate")}
                  inputFormat="MM/dd/yyyy"
                  value={endDate}
                  onChange={(newValue: Date | null) => setEndDate(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default AllJobsFilter;

const useStyle = makeStyles({
  pt: {
    paddingTop: "10px",
  },
  mt: {
    marginTop: "65px",
  },
  searchForm: {
    position: "absolute",
    top: "255px",
    right: "16px",
  },
});
