import { Button, Grid, Stack, Typography } from "@mui/material";
import { gridSpacing } from "store/constant";
import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import coreAxios from "utils/axios";
import { makeStyles } from "@mui/styles";
import JobDescriptionCard from "components/JobCards/Description";
import RequirementsCard, {
  RequirementsType,
} from "components/JobCards/RequirementsCard";
import ApplicantList from "components/JobCards/ApplicantsList";
import MainCard from "ui-component/cards/MainCard";
import langString from "utils/langString";
import AnimateButton from "ui-component/extended/AnimateButton";

export default function PostedJobDetails() {
  const [job, setJob] = useState<any>(null);
  const [totalApplicants, setTotalApplicants] = useState(0);
  const [applicants, setApplicants] = useState<any[]>([]);
  const { jobId } = useParams();
  const classes = useStyle();
  const navigate = useNavigate();
  const targets = () => {
    const requirements: RequirementsType[] = [];
    if (job && job.target_group) {
      const allTargets: any = { ...job.target_group };
      if (allTargets.is_yes_certified_required) {
        requirements.push({
          name: "YES Certified",
          isMatch: true,
        });
      }
      if (allTargets.is_gender_required && allTargets.gender) {
        requirements.push({
          name: allTargets.gender,
          isMatch: false,
        });
      }
      if (
        allTargets.is_age_required &&
        allTargets.age_max &&
        allTargets.age_min
      ) {
        requirements.push({
          name: "Age: " + allTargets.age_min + "~" + allTargets.age_max,
          isMatch: false,
        });
      }
      if (allTargets.is_location_required && allTargets.locations) {
        allTargets.locations.forEach((loc: any) => {
          requirements.push({
            name: loc.name,
            isMatch: false,
          });
        });
      }
      if (allTargets.is_experience_required && allTargets.experience) {
        requirements.push({
          name: "Experience: " + allTargets.experience + " Year",
          isMatch: false,
        });
      }
      if (allTargets.degrees) {
        allTargets.degrees.forEach((item: any) => {
          requirements.push({
            name: item.name,
            isMatch: false,
          });
        });
      }
    }
    return requirements;
  };

  const basics = () => {
    const requirements: RequirementsType[] = [];
    if (job && job.technical_skills) {
      job.technical_skills.forEach((element: any) => {
        requirements.push({ name: element.name, isMatch: false });
      });
    }
    if (job && job.personal_skills) {
      job.personal_skills.forEach((element: any) => {
        requirements.push({ name: element.name, isMatch: false });
      });
    }
    return requirements;
  };

  const trainings = () => {
    const requirements: RequirementsType[] = [];
    if (job && job.yes_trainings) {
      job.yes_trainings.forEach((element: any) => {
        requirements.push({ name: element.name, isMatch: false });
      });
    }
    if (job && job.yes_courses) {
      job.yes_courses.forEach((element: any) => {
        requirements.push({ name: element.name, isMatch: false });
      });
    }
    return requirements;
  };
  // server request
  useEffect(() => {
    coreAxios
      .get(`/jobs/job-posts/${jobId}`)
      .then((res) => {
        if (res.data.status === 200) {
          setJob(res.data.data);
        }
      })
      .catch((e: any) => {
        console.log(e);
      });
  }, [jobId]);

  // server request
  useEffect(() => {
    coreAxios
      .get(`/jobs/job-posts/${jobId}/applications/`)
      .then((res) => {
        if (res.data.status === 200) {
          setTotalApplicants(res.data.data.count);
          setApplicants(res.data.data.results);
        }
      })
      .catch((e: any) => {
        console.log(e);
      });
  }, [jobId]);

  return (
    <>
      <Grid
        container
        spacing={3}
        alignItems="center"
        justifyContent="space-between"
        style={{
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: "10px",
        }}
      >
        <Grid item xs={6} sm={3}>
          <h3>{langString("JobProfile")}</h3>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Stack textAlign="end">
            <AnimateButton>
              <Button
                variant="contained"
                onClick={() => navigate(`/employer/post-jobs/update/${jobId}`)}
              >
                {langString("Edit")}
              </Button>
            </AnimateButton>
          </Stack>
        </Grid>
      </Grid>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12} sm={8}>
          {job && <JobDescriptionCard job={job} />}
        </Grid>
        <Grid item xs={12} sm={4}>
          <RequirementsCard
            title={langString("TargetGroups")}
            requirements={targets()}
            employer={true}
          />
        </Grid>
      </Grid>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12} sm={6}>
          <RequirementsCard
            title={langString("BasicRequirements")}
            requirements={basics()}
            employer={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RequirementsCard
            title={langString("Training/CourseRequirements")}
            requirements={trainings()}
            employer={true}
          />
        </Grid>
      </Grid>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12} className={classes.mt50}>
          <MainCard>
            <Typography variant="h3" gutterBottom>
              {langString("Applicants")} <strong>[ {totalApplicants} ]</strong>
            </Typography>
            <div className={classes.mt30}>
              {applicants.length ? (
                applicants.map((applicant: any) => (
                  <div className={classes.mt30}>
                    <ApplicantList application={applicant} />{" "}
                  </div>
                ))
              ) : (
                <Typography variant="h4" textAlign="center">
                  {langString("NoApplicants")}
                </Typography>
              )}
            </div>
          </MainCard>
        </Grid>
      </Grid>
    </>
  );
}

const useStyle = makeStyles({
  mt50: {
    marginTop: "50px",
  },
  mt30: {
    marginTop: "30px",
  },
});
