import { Avatar, Grid, Typography } from "@mui/material";
import langString from "utils/langString";
import setPicturePath from "utils/setPictureUrl";

export type PersonalInfoType = {
  picture: string;
  name: string;
  age: number;
  dob: string;
  gender: string;
  phone: string;
  email: string;
  address?: string;
};
export type PersonalInfoPropsType = {
  info: PersonalInfoType;
};

export default function PersonalInfo({ info }: PersonalInfoPropsType) {
  const { name, age, gender, phone, email, picture, address } = info;
  return (
    <Grid container>
      <Grid item xs={12} sm={5}>
        <Avatar
          src={setPicturePath(picture)}
          alt="user photo"
          sx={{ width: 128, height: 128 }}
        />
      </Grid>
      <Grid item xs={12} sm={7}>
        <Typography variant="body1" className="textCap">
          {langString("name")}: {name}
        </Typography>
        <Typography variant="body1" className="textCap">
          {langString("gender")}: {gender ? gender : langString("notavailable")}
        </Typography>
        <Typography variant="body1" className="textCap">
          {langString("age")}: {age ? age : langString("notavailable")}
        </Typography>
        <Typography variant="body1" className="textCap">
          {langString("address")}: {address ? address : langString("notavailable")}
        </Typography>
        <Typography variant="body1" className="textCap">
          {langString("phone")}: {phone ? phone : langString("notavailable")}
        </Typography>
        <Typography variant="body1">
          {langString("email")}: {email ? email : langString("notavailable")}
        </Typography>
      </Grid>
    </Grid>
  );
}
