import { SNACKBAR_OPEN } from "store/actions";
import coreAxios from "utils/axios";
import { recommendationsT } from ".";

export const submitRecommendation = async (
  recommendation: any,
  jobSeekerId: string,
  setRecommendations: any,
  dispatch: any,
  handleClose: any
) => {
  try {
    if (recommendation !== "") {
      const res = await coreAxios.post(
        `/users/job-seekers/${jobSeekerId}/recommendations/`,
        { recommendation }
      );
      if (res.status === 201) {
        setRecommendations((prevState: recommendationsT[]) => [
          ...prevState,
          {
            id: res.data.data.id,
            message: res.data.data.recommendation,
            view_in_cv: res.data.data.view_in_cv,
            view_in_profile: res.data.data.view_in_profile,
            name: res.data.data?.company?.name
              ? res.data.data?.company?.name
              : "",
            position: res.data.data.company?.contact_person?.[0].designation
              ? res.data.data.company?.contact_person?.[0].designation
              : "null",
            company: res.data.data.company?.contact_person?.[0].name
              ? res.data.data.company?.contact_person?.[0].name
              : "null",
            year: res.data.data?.company?.year_of_establishment,
          },
        ]);
        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: "Recommendation successful",
          variant: "alert",
          alertSeverity: "success",
        });
        handleClose();
      }
    }
  } catch (error: any) {
    if (error.response) {
      const message = error.response.data.data.detail;
      handleClose();
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message,
        variant: "alert",
        alertSeverity: "error",
      });
    }
  }
};
