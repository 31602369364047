import { Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import MainCard from "ui-component/cards/MainCard";
import YesCertificationList from "./List";
import EditYesCertifications from "./Edit";
import langString from "utils/langString";

export type YesCertificationType = {
  id: number;
  view_in_cv: boolean;
  view_in_profile: boolean;
  name: string;
  program: string;
  completion_date: string;
  url: string;
};

type YesCertificationsProps = {
  certifications: YesCertificationType[];
  userUpdate: () => void;
};

export default function YesCertifications({
  certifications,
  userUpdate,
}: YesCertificationsProps) {
  const [enableEdit, setEnableEdit] = useState(false);
  return (
    <>
      <MainCard>
        <Grid container>
          <Grid item xs={9}>
            <Typography variant="h4" sx={{ marginBottom: "10px" }}>
              {langString("yescertifications")}
            </Typography>
          </Grid>
          <Grid item xs={3} textAlign="right">
            {!enableEdit && (
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={() => setEnableEdit(true)}
              >
                {langString("edit")}
              </Button>
            )}
          </Grid>
        </Grid>
        {enableEdit ? (
          <EditYesCertifications
            userUpdate={userUpdate}
            certifications={certifications}
          />
        ) : (
          <YesCertificationList certifications={certifications} />
        )}
        <Grid container>
          <Grid item xs={12} textAlign="right">
            {enableEdit && (
              <Button
                size="small"
                color="success"
                variant="contained"
                onClick={() => setEnableEdit(false)}
              >
                {langString("done")}
              </Button>
            )}
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
}
