import {
  Avatar,
  Button,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import axios from "axios";
import SelectField from "components/FormFields/SelectField";
import Location from "components/Location";
import UserInformation from "components/UserInformation";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RootState } from "store";
import { SNACKBAR_OPEN } from "store/actions";
import { gridSpacing } from "store/constant";
import SubCard from "ui-component/cards/SubCard";
import AnimateButton from "ui-component/extended/AnimateButton";
import coreAxios from "utils/axios";
import formateSelectFieldValues from "utils/formateSelectFieldValues";
import generateArrayOfYears from "utils/generateArrayOfYears";
import langString from "utils/langString";
import {
  submitForEmployerDataFormat,
  updateForEmployerDataFormat
} from "./dataFormat";
import initialState from "./initialState";
import validationSchema from "./validationSchema";

const Employer = () => {
  const [userInDB, setUserInDB] = useState({
    isUser: false,
    isEmail: false,
  });
  const [Src, setSrc] = useState<any>();
  const [employers, setEmployers] = useState(initialState);

  const classes = useStyle();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const years = generateArrayOfYears(50);
  const { industries } = useSelector((state: RootState) => state.config);
  const industry = formateSelectFieldValues("Select", industries!);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const isId = typeof params.id === "string" && params.id.length > 0;

  useEffect(() => {
    if (params.id) {
      coreAxios
        .get(`/users/employers/${params.id}`)
        .then((res) => {
          setSrc(
            `${
              process?.env?.REACT_APP_SERVICE_URL
            }${res.data.data.company_profile.logo
              ?.split("/")
              .filter((v: string) => v !== "")
              .join("/")}`
          );
          const data = updateForEmployerDataFormat(res);
          if (data.username) {
            setUserInDB((prevState) => ({
              ...prevState,
              isUser: true,
            }));
          }
          if (data.email) {
            setUserInDB((prevState) => ({
              ...prevState,
              isEmail: true,
            }));
          }
          setEmployers((prevStatus) => ({
            ...prevStatus,
            ...data,
          }));
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [params.id]);

  const postReqForEmployerSignUpOrUpdate = async (
    formData: FormData,
    isId: boolean
  ) => {
    try {
      if (isId) {
        const res = await coreAxios.patch(
          `/users/employers/${params?.id}/`,
          formData
        );
        if (res.status === 200) {
          navigate("/employer/profile");
          dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: "Update profile successful",
            variant: "alert",
            alertSeverity: "success",
          });
        }
      } else {
        const res = await axios.post(`${process.env.REACT_APP_SERVICE_URL}users/employers/`, formData);
        if (res.status === 201) {
          navigate("/");
          dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: "Sign Up successful",
            variant: "alert",
            alertSeverity: "success",
          });
        }
      }
    } catch (error: any) {
      if (error.response) {
        const message = JSON.parse(error.response.data.data).errorMessage;
        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message,
          variant: "alert",
          alertSeverity: "error",
        });
      }
    }
  };

  const onSubmitHandle = (values: any) => {
    if(values?.logo?.size < 2097152){
      const company_profile = submitForEmployerDataFormat(values, isId);
      const formData = new FormData();
  
      if (typeof values.logo! !== "string") {
        formData.append("logo", values.logo!);
      }
  
      formData.append("username", values.username);
      formData.append("password", values.password);
      formData.append("company_profile", JSON.stringify(company_profile));
  
      postReqForEmployerSignUpOrUpdate(formData, isId);
    } else {
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: "Picture size must be less then 2MB",
        variant: "alert",
        alertSeverity: "error",
      });
    }
    
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...employers,
    },
    validationSchema: validationSchema,
    onSubmit: onSubmitHandle,
  });

  const checkUsernameInDB = async (username: string) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVICE_URL}users/job-seekers/check-exists/`, {
        username,
      });
      if (res.status === 200) {
        if (res.data.data.email) {
          setUserInDB((prevState) => ({ ...prevState, isEmail: true }));
        }
        formik.setFieldValue(
          "email",
          res.data.data.email ? res.data.data.email : ""
        );
        formik.setFieldValue("password", "******");
        formik.setFieldValue("confirmPassword", "******");
        setUserInDB((prevState) => ({ ...prevState, isUser: true }));
      }
    } catch (e) {
      setUserInDB((prevState) => ({ ...prevState, isUser: false }));
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="h3" textAlign="center" style={{ marginBottom: 15 }}>
        {langString("EMPLOYERREGISTRATIONFORM")}
      </Typography>
      <Grid container direction="column" spacing={gridSpacing}>
        <Grid item xs={12}>
          <SubCard title="Company Details">
            <Grid
              container
              spacing={gridSpacing}
              direction={{ xs: "column-reverse", sm: "row" }}
            >
              <Grid
                container
                item
                spacing={2}
                xs={12}
                sm={6}
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs={12}>
                  <Typography component="label" htmlFor="companyName">
                    {langString("name")}<Typography component="span" color="red"> *</Typography>:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    fullWidth
                    id="name"
                    name="name"
                    label={<>
                    {langString("name")}
                    <Typography component="span" color="red"> *</Typography>
                    </>}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
                <Grid container item xs={12} alignItems="center">
                  <Grid item xs={6}>
                    <Typography
                      component="label"
                      htmlFor="year_of_establishment"
                    >
                      {langString("Yearofestablishment")}<Typography component="span" color="red"> *</Typography>:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <SelectField
                      name="year_of_establishment"
                      defaultValue={formik.values.year_of_establishment}
                      labelName={<>
                      {langString("Year")}
                      <Typography component="span" color="red"> *</Typography>
                      </>}
                      handleChange={formik.handleChange}
                      menuItems={years}
                      formik={formik}
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={12} alignItems="center">
                  <Grid item xs={6}>
                    <Typography component="label" htmlFor="employee_volume">
                      {langString("Numberofcurrentemployees")}<Typography component="span" color="red"> *</Typography>:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size="small"
                      id="employee_volume"
                      name="employee_volume"
                      type="number"
                      value={formik.values.employee_volume}
                      onChange={(event) =>
                        formik.setFieldValue(
                          "employee_volume",
                          event.target.value
                        )
                      }
                      error={
                        formik.touched.employee_volume &&
                        Boolean(formik.errors.employee_volume)
                      }
                      helperText={
                        formik.touched.employee_volume &&
                        formik.errors.employee_volume
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                item
                spacing={2}
                xs={12}
                sm={6}
                justifyContent="center"
                alignContent="center"
              >
                <Avatar
                  sx={{ width: 150, height: 150 }}
                  src={Src}
                  alt="user img"
                />

                <Grid item xs={12} textAlign="center">
                  <input
                    style={{ display: "none" }}
                    id="raised-button"
                    type="file"
                    name="logo"
                    onChange={(event) => {
                      const files = event.target.files;
                      setSrc(URL.createObjectURL(event.target.files?.[0]!));
                      formik.setFieldValue("logo", files?.[0]);
                    }}
                  />
                  <label htmlFor="raised-button">
                    <Typography variant="h5" className={classes.uploadPicture}>
                      {langString("uploadpicture")}
                    </Typography>
                  </label>
                </Grid>
              </Grid>
            </Grid>
            <Location
              id={params?.id}
              formik={formik}
              address={formik.values.address}
            />
            <Grid
              container
              xs={12}
              sm={6}
              alignItems="center"
              style={{ marginTop: 16 }}
            >
              <Grid item xs={6} sm={6}>
                <Typography component="label" htmlFor="industry_type">
                  {langString("Industrytype")}<Typography component="span" color="red"> *</Typography>:
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6}>
                <SelectField
                  name="industry_type"
                  labelName={<>{langString("Industry type")}<Typography component="span" color="red"> *</Typography></>}
                  handleChange={formik.handleChange}
                  menuItems={industry}
                  defaultValue={formik.values.industry_type}
                  formik={formik}
                />
              </Grid>
            </Grid>
          </SubCard>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} sm={6}>
            <SubCard title={langString("Contact(CompanyRepresentative)")}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography component="label" htmlFor="contactPersonName">
                        {langString("ContactPersonName")} <Typography component="span" color="red"> *</Typography>:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        size="small"
                        fullWidth
                        id="contactPersonName"
                        type="text"
                        name="contactPersonName"
                        value={formik.values.contactPersonName}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.contactPersonName &&
                          Boolean(formik.errors.contactPersonName)
                        }
                        helperText={
                          formik.touched.contactPersonName &&
                          formik.errors.contactPersonName
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Box sx={{ display: { xs: "block", sm: "none" } }}>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Box>
                <Grid item xs={12}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography component="label" htmlFor="designation">
                        {langString("Designation")} <Typography component="span" color="red"> *</Typography>:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        size="small"
                        fullWidth
                        id="designation"
                        type="text"
                        name="designation"
                        value={formik.values.designation}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.designation &&
                          Boolean(formik.errors.designation)
                        }
                        helperText={
                          formik.touched.designation &&
                          formik.errors.designation
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Box sx={{ display: { xs: "block", sm: "none" } }}>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Box>
                <Grid item xs={12}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography component="label" htmlFor="email">
                        {langString("email")} <Typography component="span" color="red"> *</Typography>:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        disabled={userInDB.isEmail}
                        fullWidth
                        size="small"
                        id="email"
                        name="email"
                        type="email"
                        value={formik.values.email}
                        onChange={(event) =>
                          formik.setFieldValue("email", event.target.value)
                        }
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Box sx={{ display: { xs: "block", sm: "none" } }}>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Box>
                <Grid item xs={12}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography component="label" htmlFor="phone">
                        {langString("Phone")} <Typography component="span" color="red"> *</Typography>:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        size="small"
                        fullWidth
                        id="phone"
                        type="tel"
                        name="phone"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.phone && Boolean(formik.errors.phone)
                        }
                        helperText={formik.touched.phone && formik.errors.phone}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </SubCard>
          </Grid>

          <Grid item xs={12} sm={6}>
            <UserInformation
              userInDB={userInDB}
              formik={formik}
              id={params.id}
              checkUsernameInDB={checkUsernameInDB}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={1} justifyContent="center">
          <Grid item xs={4} sm={2} md={1} lg={1}>
            <Stack direction="column">
              <AnimateButton>
                <Button variant="contained" onClick={() => navigate("/")}>
                  {langString("Cancel")}
                </Button>
              </AnimateButton>
            </Stack>
          </Grid>
          <Grid item xs={4} sm={2} md={1} lg={1}>
            <Stack direction="column">
              <AnimateButton>
                <Button variant="contained" type="submit">
                  {langString("Confirm")}
                </Button>
              </AnimateButton>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default Employer;

const useStyle = makeStyles({
  uploadPicture: {
    color: "blue",
    "&:hover": {
      cursor: "pointer",
    },
  },
});
