import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// reducer import
import customizationReducer from '../customizationReducer';
import snackbarReducer from '../snackbarReducer';
import cartReducer from '../cartReducer';
import kanbanReducer from '../kanbanReducer';
import accountReducer from 'store/userReducer';
import createJobReducer from './createJobReducer';
import configReducer from './configReducer';
import notificationReducer from 'store/notificationReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    snackbar: snackbarReducer,
    cart: persistReducer(
        {
            key: 'cart',
            storage,
            keyPrefix: 'berry-'
        },
        cartReducer
    ),
    kanban: kanbanReducer,
    account: accountReducer,
    job: createJobReducer,
    config: configReducer,
    notifications: notificationReducer
});

export default reducer;
