/**
 * axios setup to use mock service
 */

import axios from 'axios';
import dayjs from 'dayjs';
import jwt_decode from 'jwt-decode';
import storage from './storage';

const coreAxios = axios.create({
    baseURL: process.env.REACT_APP_SERVICE_URL,
});


// refresh token
coreAxios.interceptors.request.use(async (config) => {
  let token = storage.get('token');
  try{
    if(token){
      const { exp }:any = jwt_decode(token);
        if (dayjs(exp * 1000 - 1000 * 60 * 30).diff(dayjs()) < 1) {
          const res = await axios.post(`${process.env.REACT_APP_SERVICE_URL}accounts/api-token-refresh/`, {token});
          if(res.status === 200){
            //  setting updated token
            storage.set("token", res.data.data.token);
          }
        }
    }
  }catch(e){
  storage.remove("token");
}
  return config;
}, (err) => {
   console.log("error in getting ",err)
});


// set token in header before request send
coreAxios.interceptors.request.use(function(request) {
  let token = storage.get("token");
  if (token) {
      request.headers!.authorization = "JWT " + token;
  }

  return request;
});

// check user login
coreAxios.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (originalConfig.url !== "/accounts/api-token-auth/" && err.response) {

        // Access Token was expired
        if (err.response.status === 401) {
          const token = storage.get("token");
          if(token){
            storage.remove("token");
            window.location.reload(); 
          }
          return  coreAxios(originalConfig)
        }
      }
  
      return Promise.reject(err);
    }
  );

export default coreAxios;
