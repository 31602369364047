import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import MainCard from "ui-component/cards/MainCard";
import coreAxios from "utils/axios";
import { EducationDataType } from "./index";
import { useSelector } from "react-redux";
import { RootState } from "store";
import langString from "utils/langString";

type AddDegreePropsType = {
  closeAddForm: () => void;
  userUpdate: () => void;
  educations: EducationDataType[];
};

export default function AddDegree({
  closeAddForm,
  educations,
  userUpdate,
}: AddDegreePropsType) {
  const { user } = useSelector((status: RootState) => status.account);

  const [empty, setEmpty] = useState(false);
  const [degrees, setDegrees] = useState<any[]>([]);
  const [degree, setDegree] = useState(0);
  const [subjects, setSubjects] = useState<any[]>([]);
  const [subject, setSubject] = useState(0);
  const [institute, setInstitute] = useState("");
  const [result, setResult] = useState("");
  const [year, setYear] = useState("");

  const saveNewEducation = () => {
    if (degree && subject && institute && result && year) {
      setEmpty(false);
      const allDegrees: any[] = [];
      educations.forEach((item: EducationDataType) =>
        allDegrees.push({
          id: item.id,
          degree_type: item.degree_type?.id,
          degree_group: item.degree_group?.id,
          institute: item.institute,
          result: item.result,
          year_of_completion: item.year_of_completion,
          view_in_cv: item.view_in_cv,
          view_in_profile: item.view_in_profile,
        })
      );
      allDegrees.push({
        id: 0,
        degree_type: degree,
        degree_group: subject,
        institute: institute,
        result: result,
        year_of_completion: year,
      });
      if (user) {
        const formData = new FormData();
        formData.append(
          "job_seeker_profile",
          JSON.stringify({ degrees: allDegrees })
        );
        coreAxios
          .patch(`/users/job-seekers/${user.id}/`, formData)
          .then((res) => {
            if (res.status === 200) {
              userUpdate();
              closeAddForm();
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } else {
      setEmpty(true);
    }
  };

  useEffect(() => {
    coreAxios
      .get(`/settings/degree-types/`)
      .then((res) => {
        if (res.data.status === 200) {
          setDegrees(res.data.data);
        }
      })
      .catch((e: any) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    coreAxios
      .get(`/settings/degree-groups/`)
      .then((res) => {
        if (res.data.status === 200) {
          setSubjects(res.data.data);
        }
      })
      .catch((e: any) => {
        console.log(e);
      });
  }, []);

  return (
    <MainCard>
      <Grid container>
        <Grid item xs={6} sx={{ marginBottom: 1 }}>
          <Typography variant="h4"> {langString("addnewdegree")}</Typography>
        </Grid>
        <Grid item xs={6} textAlign="right" sx={{ marginTop: 1 }}>
          <Button
            size="small"
            color="inherit"
            variant="contained"
            onClick={() => closeAddForm()}
          >
            {langString("close")}
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={3} lg={3} marginY={{ xs: 1 }}>
          <FormControl fullWidth>
            <InputLabel id="degree-select-label">
              {`${langString("degree")}*`}
            </InputLabel>
            <Select
              labelId="degree-select-label"
              id="degree-select"
              value={degree}
              label={`${langString("degree")}*`}
              onChange={(event: any) => setDegree(event.target.value)}
            >
              <MenuItem value={0}>
                {langString("select")} {langString("degree")}
              </MenuItem>
              {degrees.length &&
                degrees.map((item: any) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2} lg={2} marginY={{ xs: 1 }}>
          <FormControl fullWidth>
            <InputLabel id="subject-select-label">{`${langString(
              "subject"
            )}*`}</InputLabel>
            <Select
              labelId="subject-select-label"
              id="subject-select"
              value={subject}
              label={`${langString("subject")}*`}
              onChange={(event: any) => setSubject(event.target.value)}
            >
              <MenuItem value={0}>
                {langString("select")} {langString("subject")}
              </MenuItem>
              {subjects.length &&
                subjects.map(
                  (item: any) =>
                    degree === item.degree_type && (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    )
                )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3} lg={3} marginY={{ xs: 1 }}>
          <FormControl>
            <TextField
              id="institute"
              name="institute"
              label={`${langString("institute")}*`}
              onChange={(event: any) => setInstitute(event.target.value)}
              value={institute}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2} lg={2} marginY={{ xs: 1 }}>
          <FormControl>
            <TextField
              id="result"
              name="result"
              label={`${langString("result")}*`}
              onChange={(event: any) => setResult(event.target.value)}
              value={result}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2} lg={2} marginY={{ xs: 1, sm: 1 }}>
          <FormControl>
            <TextField
              id="year"
              name="year"
              type="number"
              label={`${langString("year")}*`}
              onChange={(event: any) => setYear(event.target.value)}
              value={year}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} textAlign="right" sx={{ marginTop: 1 }}>
          <Button
            size="small"
            color="success"
            variant="contained"
            onClick={() => saveNewEducation()}
          >
            {langString("save")}
          </Button>
        </Grid>
      </Grid>
      {empty && (
        <Grid container>
          <Grid item xs={12} textAlign="center" sx={{ marginTop: 1 }}>
            <strong style={{ color: "red" }}>
              {langString("pleasefillallthefields")}
            </strong>
          </Grid>
        </Grid>
      )}
    </MainCard>
  );
}
