import langString from "utils/langString";

export const educationsCol = [
  {
    Header: langString("degree"),
    accessor: "degree",
  },
  {
    Header: langString("subject"),
    accessor: "subject",
  },
  {
    Header: langString("institute"),
    accessor: "institute",
  },
  {
    Header: langString("result"),
    accessor: "result",
  },
  {
    Header: langString("yearOfCompletion"),
    accessor: "yearOfCompletion",
  },
];
export const yesCertificationCol = [
  {
    Header: langString("courseName"),
    accessor: "courseName",
  },
  {
    Header: langString("program"),
    accessor: "program",
  },
  {
    Header: langString("date"),
    accessor: "date",
  },
];
export const otherCertificationsCol = [
  {
    Header: langString("courseName"),
    accessor: "courseName",
  },
  {
    Header: langString("organization"),
    accessor: "organization",
  },
  {
    Header: langString("date"),
    accessor: "date",
  },
];
export const experienceCol = [
  {
    Header: langString("company"),
    accessor: "company",
  },
  {
    Header: langString("position"),
    accessor: "position",
  },
  {
    Header: langString("duration"),
    accessor: "duration",
  },
  {
    Header: langString("year"),
    accessor: "year",
  },
];
