import {
  Avatar,
  Button,
  CardProps,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import React from "react";
import { NavigateFunction } from "react-router-dom";
import MainCard from "ui-component/cards/MainCard";
import AnimateButton from "ui-component/extended/AnimateButton";
import getAge from "utils/getAge";
import langString from "utils/langString";
import setPicturePath from "utils/setPictureUrl";
import { personalDetailsT } from ".";
import { submitRecommendation } from "./submitRecommendation";

export interface BodyProps extends CardProps {
  handleClose: () => void;
  classes?: any;
  navigate: NavigateFunction;
  recommendation: string;
  setRecommendation: any;
  jobSeekerId: string;
  personalDetails: personalDetailsT;
  setRecommendations: any;
  dispatch: any;
}

export const Body = React.forwardRef(
  (
    {
      handleClose,
      recommendation,
      setRecommendation,
      personalDetails,
      jobSeekerId,
      setRecommendations,
      dispatch,
    }: BodyProps,
    ref: React.Ref<HTMLDivElement>
  ) => (
    <div ref={ref} tabIndex={-1}>
      <MainCard
        sx={{
          position: "absolute",
          width: { xs: 280, sm: 480, lg: 650 },
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div>
          <Grid container spacing={3} xs={12}>
            <Grid item xs={5} display={{ xs: "none", sm: "block" }}>
              <Grid container item xs={12} alignItems="center">
                <Grid item xs={12}>
                  <Avatar
                    src={
                      personalDetails.picture && personalDetails.picture !== ""
                        ? setPicturePath(personalDetails.picture)
                        : ""
                    }
                    alt="company img"
                    sx={{ width: 128, height: 128 }}
                  />
                </Grid>
                <Grid item xs={12} sx={{ marginLeft: "10px" }}>
                  <Typography
                    variant="body1"
                    className="textCap"
                    sx={{ fontSize: "18px", marginTop: "10px" }}
                  >
                    {langString("name")}: {personalDetails.name}
                  </Typography>
                  <Typography
                    variant="body1"
                    className="textCap"
                    sx={{ fontSize: "18px" }}
                  >
                    {langString("Gender")}: {personalDetails.gender}
                  </Typography>
                  <Typography
                    variant="body1"
                    className="textCap"
                    sx={{ fontSize: "18px" }}
                  >
                    {langString("Age")}:{" "}
                    {getAge(personalDetails.age ? personalDetails.age : "")}
                  </Typography>
                  <Typography
                    variant="body1"
                    className="textCap"
                    sx={{ fontSize: "18px" }}
                  >
                    {langString("Phone")}:{" "}
                    {personalDetails.phone ? personalDetails.phone : ""}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: "18px" }}>
                    {langString("email")}:{" "}
                    {personalDetails.email ? personalDetails.email : ""}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={7}>
              <Typography variant="h3">
                Write a recommendation for {personalDetails.name} below..
              </Typography>
              <Grid item xs={12}>
                <TextField
                  required
                  id="outlined-textarea"
                  rows={4}
                  fullWidth
                  multiline
                  value={recommendation}
                  name="recommendation"
                  onChange={(e) => setRecommendation(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} margin={"5px 0px"}>
                <Grid container justifyContent="end" spacing={3}>
                  <Grid item>
                    <Button
                      variant="contained"
                      type="button"
                      onClick={handleClose}
                    >
                      {langString("Cancel")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <AnimateButton>
                      <Button
                        variant="contained"
                        type="button"
                        onClick={() =>
                          submitRecommendation(
                            recommendation,
                            jobSeekerId,
                            setRecommendations,
                            dispatch,
                            handleClose
                          )
                        }
                      >
                        {langString("OK")}
                      </Button>
                    </AnimateButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </MainCard>
    </div>
  )
);
