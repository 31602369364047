import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  Avatar, Button, FormControl,
  Grid,
  Input,
  InputLabel, MenuItem, Select,
  TextField
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import coreAxios from "utils/axios";
import langString from "utils/langString";
import setPicturePath from "utils/setPictureUrl";
import { PersonalInfoType } from "./PersonalInfo";

export type EditPersonalInfoPropsType = {
  info: PersonalInfoType;
  cancelEdit: () => void;
  userUpdate: () => void;
};

export default function EditPersonalInfo({
  info,
  cancelEdit,
  userUpdate,
}: EditPersonalInfoPropsType) {
  const classes = useStyle();
  const { user } = useSelector((status: RootState) => status.account);

  const { name, gender, phone, picture, dob } = info;
  const [photo, setPhoto] = useState("");
  const [newName, setNewName] = useState("");
  const [newGender, setNewGender] = useState("");
  const [birthDay, setBirthDay] = useState<Date | null>(null);
  const [newPhone, setNewPhone] = useState(0);

  const updatePersonalInfo = () => {
    const formData = new FormData();
    const job_seeker_profile: any = {
      name: newName ? newName : name,
      phone: newPhone ? newPhone : phone,
      gender: newGender ? newGender : gender,
    };
    if (birthDay) {
      job_seeker_profile.dob = dayjs(birthDay).format("YYYY-MM-DD");
    }
    if (photo) {
      formData.append("picture", photo);
    }
    formData.append("job_seeker_profile", JSON.stringify(job_seeker_profile));
    if (user) {
      coreAxios
        .patch(`/users/job-seekers/${user.id}/`, formData)
        .then((res) => {
          if (res.status === 200) {
            userUpdate();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const handleDateChange = (value: Date | null) => {
    if (value) {
      setBirthDay(value);
    } else {
      setBirthDay(new Date("2014-08-18"));
    }
  };

  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={5}>
          <Avatar
            src={picture && picture !== "" ? setPicturePath(picture) : ""}
            alt="company img"
            className={classes.avatar}
          />
          <FormControl fullWidth className={classes.fieldItem}>
            <Input
              type="file"
              className={classes.fieldItem}
              onChange={(event: any) => {
                setPhoto(event.target.files[0]);
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={7}>
          <FormControl fullWidth className={classes.fieldItem}>
            <TextField
              fullWidth
              id="name"
              name="name"
              label={langString("name")}
              onChange={(event: any) => setNewName(event.target.value)}
              value={newName ? newName : name}
            />
          </FormControl>
          <FormControl fullWidth className={classes.fieldItem}>
            <InputLabel id="gender-select-label">
              {langString("gender")}
            </InputLabel>
            <Select
              labelId="gender-select-label"
              id="gender-select"
              value={newGender ? newGender : gender}
              label={langString("gender")}
              onChange={(event: any) => setNewGender(event.target.value)}
            >
              <MenuItem value="male">{langString("male")}</MenuItem>
              <MenuItem value="female">{langString("female")}</MenuItem>
              <MenuItem value="other">{langString("other")}</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth className={classes.fieldItem}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label={langString("dateofbirth")}
                inputFormat="MM/dd/yyyy"
                value={
                  birthDay
                    ? birthDay
                    : dob
                    ? dayjs(dob).format("YYYY-MM-DD")
                    : new Date("2014-08-18")
                }
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl fullWidth className={classes.fieldItem}>
            <TextField
              fullWidth
              id="phone"
              name="phone"
              label={langString("phone")}
              onChange={(event: any) => setNewPhone(event.target.value)}
              value={newPhone ? newPhone : phone}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container className={classes.mt30}>
        <Grid item xs={5}>
          <Button
            color="inherit"
            variant="contained"
            className={classes.mt10}
            onClick={cancelEdit}
          >
            {langString("cancel")}
          </Button>
        </Grid>
        <Grid item xs={7}>
          <FormControl fullWidth className={classes.fieldItem}>
            <Button
              color="success"
              variant="contained"
              onClick={() => updatePersonalInfo()}
            >
              {langString("update")}
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}

const useStyle = makeStyles({
  avatar: { width: 128, height: 128 },
  fieldItem: { width: "90%", marginTop: 10 },
  mt30: { marginTop: 30 },
  mt10: { marginTop: 10 },
});
