import axios from "axios";
import { Dispatch } from "redux";
import { LOGIN, LOGOUT, SET_USER, SNACKBAR_OPEN } from "store/actions";
import coreAxios from "utils/axios";
import storage from "utils/storage";

export const login = (username: string, password: string) => (
  dispatch: Dispatch
) => {
  axios
    .post(`${process.env.REACT_APP_SERVICE_URL}accounts/api-token-auth/`, { username, password })
    .then((res) => {
      if (res.status === 200 && res.data.data.token) {
        storage.set("token", res.data.data.token);
        const firebaseToken = storage.get("firebase-token");
        if (firebaseToken) {
          coreAxios
            .post(`/message-management/devices/`, {
              registration_id: firebaseToken.toString(),
            })
            .then((res) => {
              console.log("request successful");
            })
            .catch((e) => {
              console.log(e);
            });
        }

        coreAxios
          .get("/users/profile/")
          .then((res) => {
            if (res.status === 200) {
              const user = res.data.data;
              dispatch({
                type: SET_USER,
                payload: {
                  user: user,
                  isLoggedIn: true,
                },
              });
            }
          })
          .catch((e) => {
            dispatch({
              type: LOGIN,
              payload: {
                user: null,
                isLoggedIn: false,
              },
            });
          });
      }
    })
    .catch((e) => {
      dispatch({
        type: LOGIN,
        payload: {
          user: null,
          isLoggedIn: false,
        },
      });
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: "Check username or password!",
        variant: "alert",
        alertSeverity: "error",
      });
    });
};

export const loadUser = () => (dispatch: Dispatch) => {
  coreAxios
    .get("/users/profile/")
    .then((res) => {
      if (res.status === 200) {
        const user = res.data.data;
        dispatch({
          type: SET_USER,
          payload: {
            user: user,
            isLoggedIn: true,
          },
        });
      }
    })
    .catch((e) => {
      dispatch({
        type: LOGIN,
        payload: {
          user: null,
          isLoggedIn: false,
        },
      });
    });
};

export const logOut = () => (dispatch: Dispatch) => {
  storage.remove("token");
  dispatch({
    type: LOGOUT,
  });
};
