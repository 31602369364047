import { Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import MainCard from "ui-component/cards/MainCard";
import EducationList from "./List";
import EditEducations from "./Edit";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AddDegree from "./AddDegree";
import langString from "utils/langString";

export type EducationDataType = {
  id: number;
  degree_type: { id: number; name: string };
  degree_group: { id: 1; name: string };
  institute: string;
  result: string | null;
  year_of_completion: string | null;
  view_in_profile?: boolean;
  view_in_cv?: boolean;
};

type EducationsPropsType = {
  educations: EducationDataType[];
  userUpdate: () => void;
};

export default function Educations({
  educations,
  userUpdate,
}: EducationsPropsType) {
  const [enableEdit, setEnableEdit] = useState(false);
  const [addForm, setAddForm] = useState(false);

  return (
    <>
      <MainCard>
        <Grid container>
          <Grid item xs={9}>
            <Typography variant="h4" sx={{ marginBottom: "10px" }}>
              {langString("education")}
            </Typography>
          </Grid>
          <Grid item xs={3} textAlign="right">
            {!enableEdit && (
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={() => setEnableEdit(true)}
              >
                {langString("edit")}
              </Button>
            )}
          </Grid>
        </Grid>
        {enableEdit ? (
          <EditEducations userUpdate={userUpdate} educations={educations} />
        ) : (
          <EducationList educations={educations} />
        )}
        {addForm && (
          <Grid container>
            <Grid item xs={12}>
              <AddDegree
                educations={educations}
                closeAddForm={() => setAddForm(false)}
                userUpdate={() => userUpdate()}
              />
            </Grid>
          </Grid>
        )}

        <Grid container>
          <Grid item xs={12} textAlign="right">
            {enableEdit && (
              <Button
                size="small"
                color="success"
                variant="contained"
                onClick={() => setEnableEdit(false)}
              >
                {langString("done")}
              </Button>
            )}
            {!addForm && !enableEdit && (
              <Button
                size="small"
                color="info"
                variant="outlined"
                onClick={() => setAddForm(true)}
              >
                <AddBoxIcon />
              </Button>
            )}
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
}
