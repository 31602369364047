import { Grid, Skeleton, Typography } from "@mui/material";
import useAuth from "hooks/useAuth";
import React, { useEffect, useState } from "react";
import { employerT } from "types/employer";
import coreAxios from "utils/axios";
import langString from "utils/langString";
import CompanyDetails from "./CompanyDetails";
import Dashboard from "./Dashboard";

export default function EmployerProfile() {
  const [userInfo, setUserInfo] = useState<employerT>();
  const { user } = useAuth();
  useEffect(() => {
    coreAxios
      .get(`/users/employers/${user?.id!}`)
      .then((res) => {
        setUserInfo(res.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [user?.id]);

  return (
    <>
      <Typography variant="h1" margin="20px">
        {langString("CompanyProfile")}
      </Typography>
      <Grid container spacing={3} xs={12}>
        <Grid item xs={12} sm={8}>
          {userInfo?.company_profile ? (
            <CompanyDetails userInfo={userInfo} />
          ) : (
            <Skeleton
              animation="wave"
              variant="rectangular"
              sx={{
                borderRadius: "10px",
                minHeight: "75vh",
                maxHeight: "100vh",
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          {userInfo?.company_profile ? (
            <Dashboard
              job_posts={userInfo.company_profile.job_posts}
              current_applicants={userInfo.company_profile.current_applicants}
              selected_applicants={userInfo.company_profile.selected_applicants}
            />
          ) : (
            <Skeleton
              animation="wave"
              variant="rectangular"
              sx={{
                borderRadius: "10px",
                minHeight: "75vh",
                maxHeight: "100vh",
              }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
