// material-ui
import { useTheme } from "@mui/material/styles";
import { Avatar, Box, ButtonBase } from "@mui/material";

// project imports
import LogoSection from "../LogoSection";
// import SearchSection from './SearchSection';
// import LocalizationSection from "./LocalizationSection";
import MobileSection from "./MobileSection";
import ProfileSection from "./ProfileSection";
import NotificationSection from "./NotificationSection";

// assets
import { IconMenu2 } from "@tabler/icons";
import useAuth from "hooks/useAuth";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

export interface HeaderProps {
  handleLeftDrawerToggle: () => void;
}

const Header = ({ handleLeftDrawerToggle }: HeaderProps) => {
  const theme = useTheme();
  const { isLoggedIn } = useAuth();
  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <Box
          component="span"
          sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}
        >
          <LogoSection />
        </Box>
        <ButtonBase sx={{ borderRadius: "12px", overflow: "hidden" }}>
          {isLoggedIn && (
            <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                transition: "all .2s ease-in-out",
                background:
                  theme.palette.mode === "dark"
                    ? theme.palette.dark.main
                    : theme.palette.secondary.light,
                color:
                  theme.palette.mode === "dark"
                    ? theme.palette.secondary.main
                    : theme.palette.secondary.dark,
                "&:hover": {
                  background:
                    theme.palette.mode === "dark"
                      ? theme.palette.secondary.main
                      : theme.palette.secondary.dark,
                  color:
                    theme.palette.mode === "dark"
                      ? theme.palette.secondary.light
                      : theme.palette.secondary.light,
                },
              }}
              onClick={handleLeftDrawerToggle}
              color="inherit"
            >
              <IconMenu2 stroke={1.5} size="1.3rem" />
            </Avatar>
          )}
        </ButtonBase>
      </Box>

      {/* header search */}
      {/* <SearchSection /> */}
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      {/* live customization & localization */}
      {/* <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <LocalizationSection />
      </Box> */}

      {/* notification & profile */}
      {isLoggedIn && (
        <>
          <NotificationSection />
          <ProfileSection />
        </>
      )}

      {/* mobile header */}
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        <MobileSection />
      </Box>
    </>
  );
};

export default Header;
