import { Button, CardProps, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import MainCard from "ui-component/cards/MainCard";
import AnimateButton from "ui-component/extended/AnimateButton";
import langString from "utils/langString";


export interface BodyProps extends CardProps {
  handleClose: () => void;
  formik: any;
}

export const Create = React.forwardRef(
  ({ handleClose, formik }: BodyProps, ref: React.Ref<HTMLDivElement>) => (
    <div ref={ref} tabIndex={-1}>
      <MainCard
        sx={{
          position: "absolute",
          width: { xs: 300, sx: 400, lg: 650 },
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3} xs={12}>
            <Grid item xs={12}>
              <Typography variant="h3" textAlign={"center"}>
                {formik.values.id
                  ? langString("UpdateEVENT")
                  : langString("CreateEVENT")}
              </Typography>
            </Grid>
            <Grid container item xs={12}>
              <Grid container item xs={12} alignItems={"center"}>
                <Grid item xs={6}>
                  <Typography variant="h3">
                    {langString("SelectDate")}<Typography component="span" color="red"> *</Typography>:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="date"
                    size="small"
                    id="date"
                    rows={4}
                    fullWidth
                    value={formik.values.date}
                    name="date"
                    onChange={formik.handleChange}
                    error={formik.touched.date && Boolean(formik.errors.date)}
                    helperText={formik.touched.date && formik.errors.date}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                alignItems={"center"}
                sx={{ marginY: "10px" }}
              >
                <Grid item xs={6}>
                  <Typography variant="h3">{langString("From")}<Typography component="span" color="red"> *</Typography>:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="time"
                    size="small"
                    id="fromTime"
                    rows={4}
                    fullWidth
                    value={formik.values.fromTime}
                    name="fromTime"
                    onChange={formik.handleChange}
                    error={
                      formik.touched.fromTime && Boolean(formik.errors.fromTime)
                    }
                    helperText={
                      formik.touched.fromTime && formik.errors.fromTime
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                alignItems={"center"}
                sx={{ marginY: "10px" }}
              >
                <Grid item xs={6}>
                  <Typography variant="h3">{langString("To")}<Typography component="span" color="red"> *</Typography>:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="time"
                    size="small"
                    id="toTime"
                    rows={4}
                    fullWidth
                    value={formik.values.toTime}
                    name="toTime"
                    onChange={formik.handleChange}
                    error={
                      formik.touched.toTime && Boolean(formik.errors.toTime)
                    }
                    helperText={formik.touched.toTime && formik.errors.toTime}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <TextField
                id="message-textarea"
                placeholder="Type here.."
                rows={6}
                fullWidth
                multiline
                value={formik.values.message}
                name="message"
                onChange={formik.handleChange}
                error={formik.touched.message && Boolean(formik.errors.message)}
                helperText={formik.touched.message && formik.errors.message}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item>
                  <Button
                    variant="contained"
                    type="button"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    {langString("Cancel")}
                  </Button>
                </Grid>
                <Grid item>
                  <AnimateButton>
                    <Button variant="contained" type="submit">
                      {langString("OK")}
                    </Button>
                  </AnimateButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </MainCard>
    </div>
  )
);
