import { Avatar, Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import MainCard from "ui-component/cards/MainCard";
import setPicturePath from "utils/setPictureUrl";
import { useParams } from "react-router";
import coreAxios from "utils/axios";
import { useNavigate } from "react-router-dom";
import { checkNullData } from "utils/dataHelper";
import langString from "utils/langString";

export default function CompanyProfile() {
  const [company, setCompany] = useState<any>(null);
  const { id } = useParams();
  const navigate = useNavigate();

  // server request
  useEffect(() => {
    coreAxios
      .get(`/users/employers/${id}`)
      .then((res) => {
        if (res.data.status === 200) {
          setCompany(res.data.data.company_profile);
        }
      })
      .catch((e: any) => {
        console.log(e);
      });
  }, [id]);

  return (
    <>
      <MainCard>
        <Typography variant="h2" margin="10px 0px">
          {langString("company")} {langString("details")}
        </Typography>
        {company ? (
          <Grid container xs={12}>
            <Grid
              item
              xs={12}
              sm={6}
              justifyItems="center"
              display={{ xs: "inline", sm: "none" }}
            >
              {company.logo && (
                <Avatar
                  src={setPicturePath(company.logo)}
                  alt="company img"
                  sx={{ width: 150, height: 150, margin: "auto" }}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="body1"
                fontSize={18}
                margin="10px 0px"
                className="textCap"
              >
                {langString("name")}: {checkNullData(company.name)}
              </Typography>
              <Typography variant="body1" fontSize={18} margin="10px 0px">
                {langString("yearofestablishment")}:{" "}
                {checkNullData(company.year_of_establishment)}
              </Typography>
              <Typography
                variant="body1"
                fontSize={18}
                margin="10px 0px"
                className="textCap"
              >
                {langString("location")}: {checkNullData(company.address?.name)}
              </Typography>
              <Typography
                variant="body1"
                fontSize={18}
                margin="10px 0px"
                className="textCap"
              >
                {langString("industry")} {langString("type")}:{" "}
                {checkNullData(company.industry_type?.name)}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              justifyItems="center"
              display={{ xs: "none", sm: "inline" }}
            >
              {company.logo && (
                <Avatar
                  src={setPicturePath(company.logo)}
                  alt="company img"
                  sx={{ width: 150, height: 150, margin: "auto" }}
                />
              )}
            </Grid>
            <Grid container xs={12}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h2" margin="10px 0px">
                  {langString("contact")} {langString("info")}
                </Typography>
                <Typography
                  variant="body1"
                  fontSize={18}
                  margin="10px 0px"
                  className="textCap"
                >
                  {langString("name")}:{" "}
                  {checkNullData(company.contact_person[0]?.name)}
                </Typography>
                <Typography
                  variant="body1"
                  fontSize={18}
                  margin="10px 0px"
                  className="textCap"
                >
                  {langString("designation")}:{" "}
                  {checkNullData(company.contact_person[0]?.designation)}
                </Typography>
                <Typography variant="body1" fontSize={18} margin="10px 0px">
                  {langString("email")}:{" "}
                  {checkNullData(company.contact_person[0]?.email)}
                </Typography>
                <Typography variant="body1" fontSize={18} margin="10px 0px">
                  {langString("phone")}:{" "}
                  {checkNullData(company.contact_person[0]?.phone)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Button
                  sx={{ marginTop: 4 }}
                  color="success"
                  onClick={() => navigate(`/job-seeker/companies`)}
                >
                  {langString("backtocompanylist")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <>
            <Typography variant="h4" sx={{ margin: 3, textAlign: "center" }}>
              {langString("datanotfound")}
            </Typography>
            <Typography
              variant="h5"
              sx={{
                marginLeft: 3,
                color: "green",
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={() => navigate(`/job-seeker/companies`)}
            >
              {langString("backtocompanylist")}
            </Typography>
          </>
        )}
      </MainCard>
    </>
  );
}
