import React from "react";

// material-ui
import {
  Button,
  IconButton,
  Modal,
  Typography,
  CardProps,
  Stack,
} from "@mui/material";

// project imports
import MainCard from "ui-component/cards/MainCard";

// assets
import CloseIcon from "@mui/icons-material/Close";
import AnimateButton from "ui-component/extended/AnimateButton";
import { makeStyles } from "@mui/styles";
import { NavigateFunction, useNavigate } from "react-router-dom";

// generate random
function rand() {
  return Math.round(Math.random() * 20) - 10;
}

// modal position
function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export interface BodyProps extends CardProps {
  modalStyle: React.CSSProperties;
  handleClose: () => void;
  classes: any;
  navigate: NavigateFunction;
}

const Body = React.forwardRef(
  (
    { modalStyle, handleClose, classes, navigate }: BodyProps,
    ref: React.Ref<HTMLDivElement>
  ) => (
    <div ref={ref} tabIndex={-1}>
      {/**
       * style={modalStyle}
       * Property 'style' does not exist on type 'IntrinsicAttributes & MainCardProps & RefAttributes<HTMLDivElement>
       */}
      <MainCard
        style={modalStyle}
        sx={{
          position: "absolute",
          width: { xs: 280, lg: 450 },
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        title=" "
        secondary={
          <IconButton onClick={handleClose} size="large">
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      >
        <div className={classes.textCenter}>
          <Typography variant="h3">
            To post a Job circular, you need to signup/login to the system.
          </Typography>
          <div className={classes.itemCenter}>
            <Stack direction="column">
              <AnimateButton>
                <Button
                  variant="contained"
                  type="button"
                  onClick={() => {
                    navigate("/sign-up");
                  }}
                >
                  SIGN UP
                </Button>
              </AnimateButton>
            </Stack>
            <Stack direction="column">
              <AnimateButton>
                <Button
                  variant="contained"
                  type="button"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  LOG IN
                </Button>
              </AnimateButton>
            </Stack>
          </div>
        </div>
      </MainCard>
    </div>
  )
);

// ==============================|| SIMPLE MODAL ||============================== //

export default function JobSeekerModal() {
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const classes = useStyle();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="contained" type="button" onClick={handleOpen}>
        Apply Job
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Body
          modalStyle={modalStyle}
          handleClose={handleClose}
          classes={classes}
          navigate={navigate}
        />
      </Modal>
    </>
  );
}

const useStyle = makeStyles({
  textCenter: {
    textAlign: "center",
    padding: 10,
  },
  itemCenter: {
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: 20,
  },
});
