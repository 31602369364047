import { EducationDataType } from "./index";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import coreAxios from "utils/axios";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Button } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useSelector } from "react-redux";
import { RootState } from "store";
import langString from "utils/langString";

type EditEduPropsType = {
  educations: EducationDataType[];
  userUpdate: () => void;
};

export default function EditEducations({
  educations,
  userUpdate,
}: EditEduPropsType) {
  const { user } = useSelector((status: RootState) => status.account);

  const updateEducation = (id: number, key: string) => {
    let degrees: any[] = [...educations];
    if (key === "view_in_cv") {
      degrees.forEach((item: EducationDataType, index: number) => {
        if (item.id === id) {
          degrees[index].view_in_cv = !degrees[index].view_in_cv;
        }
        degrees[index].degree_type = degrees[index].degree_type?.id;
        degrees[index].degree_group = degrees[index].degree_group?.id;
      });
    }
    if (key === "view_in_profile") {
      degrees.forEach((item: EducationDataType, index: number) => {
        if (item.id === id) {
          degrees[index].view_in_profile = !degrees[index].view_in_profile;
        }
        degrees[index].degree_type = degrees[index].degree_type?.id;
        degrees[index].degree_group = degrees[index].degree_group?.id;
      });
    }
    if (user) {
      const formData = new FormData();
      formData.append(
        "job_seeker_profile",
        JSON.stringify({ degrees: degrees })
      );
      coreAxios
        .patch(`/users/job-seekers/${user.id}/`, formData)
        .then((res) => {
          if (res.status === 200) {
            userUpdate();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const deleteDegree = (id: number) => {
    let degrees: EducationDataType[] = [...educations];

    const filteredDegrees: any[] = [];
    degrees.forEach(
      (item: EducationDataType) =>
        item.id !== id &&
        filteredDegrees.push({
          id: item.id,
          degree_type: item.degree_type?.id,
          degree_group: item.degree_group?.id,
          institute: item.institute,
          result: item.result,
          year_of_completion: item.year_of_completion,
          view_in_cv: item.view_in_cv,
          view_in_profile: item.view_in_profile,
        })
    );
    const formData = new FormData();
    formData.append(
      "job_seeker_profile",
      JSON.stringify({ degrees: filteredDegrees })
    );
    coreAxios
      .patch(`/users/job-seekers/${user?.id}/`, formData)
      .then((res) => {
        if (res.status === 200) {
          userUpdate();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{langString("degree")}</TableCell>
            <TableCell>{langString("subject")}</TableCell>
            <TableCell>{langString("institute")}</TableCell>
            <TableCell>{langString("result")}</TableCell>
            <TableCell>{langString("yearofcompletion")}</TableCell>
            <TableCell align="right">{langString("update")}</TableCell>
            <TableCell align="right">{langString("delete")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {educations.map((row) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.degree_type?.name}
              </TableCell>
              <TableCell>{row.degree_group?.name}</TableCell>
              <TableCell>{row.institute}</TableCell>
              <TableCell>{row.result}</TableCell>
              <TableCell>{row.year_of_completion}</TableCell>
              <TableCell align="right">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={row.view_in_cv}
                        onChange={() => updateEducation(row.id, "view_in_cv")}
                      />
                    }
                    label={langString("viewincv")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={row.view_in_profile}
                        onChange={() =>
                          updateEducation(row.id, "view_in_profile")
                        }
                      />
                    }
                    label={langString("viewinprofile")}
                  />
                </FormGroup>
              </TableCell>
              <TableCell align="right">
                <Button
                  size="small"
                  color="error"
                  variant="outlined"
                  onClick={() => deleteDegree(row.id)}
                >
                  <DeleteForeverIcon />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
