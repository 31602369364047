import { Typography } from "@mui/material";
import BasicDataTable from "components/BasicDataTable";
import { useEffect, useMemo, useState } from "react";
import MainCard from "ui-component/cards/MainCard";
import coreAxios from "utils/axios";
import { checkNullData } from "utils/dataHelper";
import dateFormate from "utils/dateFormate";
import langString from "utils/langString";
import AllJobsFilter from "./FilterForm";

const AllJobs = () => {
  const [jobPosts, setJobPosts] = useState<any[]>([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [category, setCategory] = useState(0);
  const [jobType, setJobType] = useState(0);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [location, setLocation] = useState("");
  const [search, setSearch] = useState("");
  const [company, setCompany] = useState("");
  const [application, setApplication] = useState("");

  // server request
  useEffect(() => {
    const params: any = {
      limit: rowsPerPage,
      offset: page * 10,
    };
    if (category) params.category = category;
    if (jobType) params.type = jobType;
    if (startDate) params.start_date = startDate;
    if (endDate) params.end_date = endDate;
    if (location) params.location = location;
    if (search) params.search = search;
    if (application) params.application_status = application;
    if (company) params.company = company;

    coreAxios
      .get("/jobs/job-posts/", {
        params: params,
      })
      .then((res) => {
        if (res.data.status === 200) {
          setCount(res.data.data.count);
          setJobPosts(res.data.data.results);
        }
      })
      .catch((e: any) => {
        console.log(e);
      });
  }, [
    page,
    rowsPerPage,
    category,
    jobType,
    startDate,
    endDate,
    location,
    search,
    application,
    company,
  ]);

  const columns = useMemo(
    () => [
      {
        Header: langString("sl"),
        accessor: "sl",
      },
      {
        Header: langString("company"),
        accessor: "company",
      },
      {
        Header: langString("job") + " " + langString("id"),
        accessor: "job_id",
      },
      {
        Header: langString("job") + " " + langString("category"),
        accessor: "job_category",
      },
      {
        Header: langString("job") + " " + langString("type"),
        accessor: "job_type",
      },
      {
        Header: langString("location"),
        accessor: "company_address",
      },
      {
        Header: langString("posting") + " " + langString("date"),
        accessor: "created_at",
      },
      {
        Header: langString("deadline"),
        accessor: "deadline",
      },
      {
        Header: langString("status"),
        accessor: "application_status",
      },
      {
        Header: langString("vacancy"),
        accessor: "vacancy",
      },
    ],
    []
  );

  const rowsData = (data: any) => {
    const processedData: any[] = [];
    data.forEach((row: any, index: number) =>
      processedData.push({
        sl: index + 1,
        company: row["company"] && checkNullData(row["company"]["name"]),
        id: row["id"],
        job_id: row["jobId"],
        job_category: row["category"] && checkNullData(row["category"]["name"]),
        job_type: row["type"] && checkNullData(row["type"]["name"]),
        company_address: checkNullData(row["company_address"]),
        created_at: checkNullData(dateFormate(row["created_at"])),
        deadline: checkNullData(dateFormate(row["deadline"])),
        application_status: checkNullData(row["application_status"]),
        vacancy: checkNullData(row["vacancy"]),
      })
    );
    return processedData;
  };

  return (
    <>
      <AllJobsFilter
        category={category}
        setCategory={(value: number) => setCategory(value)}
        jobType={jobType}
        setJobType={(value: number) => setJobType(value)}
        startDate={startDate}
        setStartDate={(value: Date | null) => setStartDate(value)}
        endDate={endDate}
        setEndDate={(value: Date | null) => setEndDate(value)}
        location={location}
        setLocation={(value: string) => setLocation(value)}
        search={search}
        setSearch={(value: string) => setSearch(value)}
        company={company}
        setCompany={(value: string) => setCompany(value)}
        application={application}
        setApplication={(value: string) => setApplication(value)}
      />
      {jobPosts ? (
        <BasicDataTable
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={(value: number) => setPage(value)}
          setRowsPerPage={(value: number) => setRowsPerPage(value)}
          columns={columns}
          rows={rowsData(jobPosts)}
          link="/job-market/"
          linkID="id"
        />
      ) : (
        <MainCard>
          <Typography variant="h4" sx={{ margin: 3, textAlign: "center" }}>
            "Loading..."
          </Typography>
        </MainCard>
      )}
    </>
  );
};

export default AllJobs;
