import { UserProfileType } from "../../types/UserProfile";
import { useEffect, useState } from "react";
import RequirementsCard from "./RequirementsCard";
import langString from "utils/langString";

export type RequirementsType = {
  name: string;
  isMatch: boolean;
};
type BasicRequirementsPropsType = {
  technical_skills: any[];
  personal_skills: any[];
  profile?: UserProfileType | null;
};

const BasicRequirements = ({
  personal_skills,
  technical_skills,
  profile,
}: BasicRequirementsPropsType) => {
  const [requirements, setRequirements] = useState<RequirementsType[]>([]);

  useEffect(() => {
    const allRequirements: RequirementsType[] = [];
    if (technical_skills && profile) {
      const profileTechSkills = profile.technical_skills?.map(
        (item: any) => item.id
      );
      technical_skills.forEach((element: any) => {
        if (profileTechSkills?.includes(element.id)) {
          allRequirements.push({ name: element.name, isMatch: true });
        } else {
          allRequirements.push({ name: element.name, isMatch: false });
        }
      });
    }
    if (personal_skills && profile) {
      const profilePerSkills = profile.personal_skills?.map(
        (item: any) => item.id
      );
      personal_skills.forEach((element: any) => {
        if (profilePerSkills?.includes(element.id)) {
          allRequirements.push({ name: element.name, isMatch: true });
        } else {
          allRequirements.push({ name: element.name, isMatch: false });
        }
      });
    }

    setRequirements(allRequirements);
  }, [profile, personal_skills, technical_skills]);

  return (
    <RequirementsCard
      title={langString("basicrequirements")}
      requirements={requirements}
    />
  );
};

export default BasicRequirements;
