import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Loading from "components/Loading";
import dayjs from "dayjs";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import AnimateButton from "ui-component/extended/AnimateButton";
import coreAxios from "utils/axios";
import { getDate, getTime, getTimeAMOrPM } from "utils/dateFormate";
import langString from "utils/langString";
import * as yup from "yup";
import { Create } from "./Create";
import handleOnSubmit from "./handleOnSubmit";
import MenuButton from "./MenuButton";

export type eventT = {
  id: number;
  start_datetime: string;
  end_datetime: string;
  message: string;
};

const validationSchema = yup.object({
  date: yup
    .date()
    .required("Date is required")
    .test("date", "Please choose a valid date", (value) => {
      return !dayjs().isAfter(value, "date");
    }),
  fromTime: yup.string().required("From time field is required"),
  toTime: yup
    .string()
    .required("end time cannot be empty")
    .test("is-greater", "To time should be greater", function (value) {
      const { fromTime, date } = this.parent;
      return dayjs(`${dayjs(date).format("YYYY-M-D")}T${value}`, "H:m").isAfter(
        dayjs(`${dayjs(date).format("YYYY-M-D")}T${fromTime}`, "H:m")
      );
    }),
  message: yup.string().min(3, "Too Short").required("Message is required"),
});

const Calender = () => {
  const [open, setOpen] = useState(false);
  const [next, setNext] = useState<string>("");
  const [total, setTotal] = useState<number>(0);

  const [event, setEvent] = useState({
    id: 0,
    date: "",
    fromTime: "",
    toTime: "",
    message: "",
  });

  const [events, setEvents] = useState<eventT[]>([]);
  const [menuValue, setMenuValue] = useState<string>("all");
  const classes = useStyle();
  const dispatch = useDispatch();
  const { eventId } = useParams();

  // server request
  const getEvents = async (filter: string = "") => {
    let res;
    if (filter !== "") {
      res = await coreAxios.get(`/calendar/events/?filter=${filter}`);
    } else if (eventId) {
      res = await coreAxios.get(`/calendar/events/${eventId}`);
    } else {
      res = await coreAxios.get("/calendar/events/");
    }

    if (res?.status === 200) {
      if (eventId) {
        setEvents([res?.data.data]);
      } else {
        setEvents(res?.data.data.results);
      }
      setNext(res?.data.data.next);
      setTotal(res?.data.data.count);
    }
  };

  useEffect(() => {
    if (menuValue === "upcoming" || menuValue === "completed") {
      getEvents(menuValue);
    } else {
      getEvents();
    }
    // eslint-disable-next-line
  }, [menuValue, eventId]);

  // modal open and close
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
    setEvent({
      id: 0,
      date: "",
      fromTime: "",
      toTime: "",
      message: "",
    });
  };
  // submit and update
  const handleOnSubmitAndUpdate = (values: any) =>
    handleOnSubmit(values, events, setEvents, setEvent, handleClose, dispatch);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...event },
    validationSchema: validationSchema,
    onSubmit: handleOnSubmitAndUpdate,
  });

  const fetchMoreEvents = async () => {
    const res = await coreAxios.get(next);
    setEvents((prevState) => [...prevState, ...res.data.data.results]);
    setNext(res.data.data.next);
  };

  return (
    <>
      <Grid container xs={12} sm={12} md={12} lg={12} justifyContent={"center"}>
        <Grid container item xs={12} sm={6} md={6} lg={6}>
          <Grid item xs={12}>
            <Typography variant="h3">{langString("EVENTS")}</Typography>
          </Grid>
          {!eventId && (
            <>
              <Grid item xs={12}>
                <MenuButton
                  menuValue={langString(menuValue)}
                  handleOnClick={setMenuValue}
                  menuItems={[
                    {
                      value: "all",
                      name: langString("all"),
                    },
                    {
                      value: "upcoming",
                      name: langString("upcoming"),
                    },
                    {
                      value: "completed",
                      name: langString("completed"),
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  className="textCap"
                  color={"gray"}
                >
                  {langString(menuValue)} {langString("event")}
                </Typography>
              </Grid>
            </>
          )}

          <div className={classes.scrollBar} id="events">
            <InfiniteScroll
              dataLength={events.length}
              next={fetchMoreEvents}
              hasMore={events.length !== total}
              loader={<Loading position={"center"} />}
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>{langString("Youhaveseenitall")}</b>
                </p>
              }
              scrollableTarget="events"
            >
              {events.map((event) => {
                return (
                  <Grid
                    container
                    key={event.id}
                    item
                    xs={12}
                    sx={{ marginY: "5px", width: "100vh" }}
                  >
                    <Grid container item xs={12} margin={"5px 0px"}>
                      <Typography
                        variant="h4"
                        className="textCap"
                        color={"gray"}
                      >
                        {getDate(event.start_datetime)}
                      </Typography>
                    </Grid>

                    <Grid container item xs={4} sm={4} md={4} lg={3}>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle1"
                          className="textCap"
                          color={"gray"}
                        >
                          {getTimeAMOrPM(event.start_datetime)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle1"
                          className="textCap"
                          color={"gray"}
                        >
                          {getTimeAMOrPM(event.end_datetime)}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      xs={7}
                      sm={7}
                      md={7}
                      lg={8}
                      className={classes.border}
                      sx={{ marginX: "10px" }}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography
                        variant="subtitle1"
                        className="textCap"
                        color={"gray"}
                      >
                        {event.message}
                      </Typography>
                      <IconButton
                        size="small"
                        onClick={() => {
                          setEvent((prevState) => ({
                            ...prevState,
                            id: event.id,
                            date: event.start_datetime.split("T")[0],
                            fromTime: getTime(event.start_datetime),
                            toTime: getTime(event.end_datetime),
                            message: event.message,
                          }));
                          handleOpen();
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                );
              })}
            </InfiniteScroll>
          </div>
          <>
            <Grid item xs={12} textAlign={"center"}>
              <Stack direction="column">
                <AnimateButton>
                  <Button
                    variant="contained"
                    type="button"
                    onClick={handleOpen}
                  >
                    {langString("NEWEVENT")}
                  </Button>
                </AnimateButton>
              </Stack>
              <Modal
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                <Create handleClose={handleClose} formik={formik}/>
              </Modal>
            </Grid>
          </>
        </Grid>
      </Grid>
    </>
  );
};

export default Calender;

const useStyle = makeStyles({
  scrollBar: {
    height: "55vh",
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      width: "0em",
    },
    "&::-webkit-scrollbar-track ": {
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
    },
  },
  border: {
    border: "1px solid lightgray",
    borderRadius: "10px",
    padding: "10px",
    backgroundColor: "white",
  },
});
