import {
  Avatar,
  Button,
  CardProps,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import SingleSelect from "components/FormFields/SingleSelect";
import React from "react";
import { NavigateFunction } from "react-router-dom";
import MainCard from "ui-component/cards/MainCard";
import AnimateButton from "ui-component/extended/AnimateButton";
import langString from "utils/langString";
import setPicturePath from "utils/setPictureUrl";
import { personalDetailsT } from ".";


export interface BodyProps extends CardProps {
  handleClose: () => void;
  classes?: any;
  navigate: NavigateFunction;
  recommendation: string;
  setRecommendation: any;
  jobAppliedId: string;
  personalDetails: personalDetailsT;
  setRecommendations: any;
  location: string;
  date: any;
  setDate: any;
  setLocation: any;
  thanas: any;
  handleOnSubmit: any;
}

export const Body = React.forwardRef(
  (
    {
      handleClose,
      personalDetails,
      date,
      setDate,
      location,
      setLocation,
      thanas,
      handleOnSubmit,
    }: BodyProps,
    ref: React.Ref<HTMLDivElement>
  ) => (
    <div ref={ref} tabIndex={-1}>
      <MainCard
        sx={{
          position: "absolute",
          width: { xs: 300, sm: 480, lg: 650 },
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <form onSubmit={handleOnSubmit}>
          <Grid container spacing={3} xs={12}>
            <Grid container item xs={12} sm={5}>
              <Grid container xs={12} alignItems="center">
                <Avatar
                  src={
                    personalDetails.picture && personalDetails.picture !== ""
                      ? setPicturePath(personalDetails.picture)
                      : ""
                  }
                  alt="company img"
                  sx={{ width: 128, height: 128, marginY: "auto" }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={7}>
              <Typography variant="h3" color="green" textAlign={"center"}>
                <span className="textCap">{personalDetails.name}</span> is
                Selected for the Interview!
              </Typography>
              <Grid
                container
                item
                xs={12}
                alignItems={"center"}
                sx={{ marginY: "10px" }}
              >
                <Grid item xs={6}>
                  <Typography variant="h3">
                    {langString("Interviewon")}:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    type="date"
                    size="small"
                    id="date"
                    rows={4}
                    fullWidth
                    value={date}
                    name="date"
                    onChange={(e) => setDate(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                alignItems={"center"}
                sx={{ marginY: "10px" }}
              >
                <Grid item xs={6}>
                  <Typography variant="h3">
                  {langString("Location")}:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <SingleSelect
                    required={true}
                    name="location"
                    label="Location"
                    value={location}
                    handleChange={(value: any) => setLocation(value)}
                    values={thanas}
                    valueKey="id"
                    nameKey="name"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item>
                  <Button
                    variant="contained"
                    type="button"
                    onClick={handleClose}
                  >
                    {langString("Cancel")}
                  </Button>
                </Grid>
                <Grid item>
                  <AnimateButton>
                    <Button variant="contained" type="submit">
                      {langString("OK")}
                    </Button>
                  </AnimateButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </MainCard>
    </div>
  )
);
