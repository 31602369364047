import { makeStyles } from "@mui/styles";

const useStyle = makeStyles({
    h: {
    },
    mt:{
      marginTop: "20px"
    },
    mx: {
      marginLeft: "5px",
      marginRight: "5px"
    }
  });
  

  export default useStyle;