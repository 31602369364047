// material-ui
import { ButtonBase, Grid, Typography } from "@mui/material";
import logo from "assets/images/icons/favicon.png";
// project imports
import config from "config";
import { Link } from "react-router-dom";
import langString from "utils/langString";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
  <ButtonBase disableRipple component={Link} to={config.defaultPath}>
    <Grid container spacing={1} alignItems="center">
      <Grid item>
        <img src={logo} width={80} alt="" />
      </Grid>
      <Grid item marginTop={1}>
        <Typography variant="h4" color="initial" noWrap>
          {langString("chakrisheba")}
        </Typography>
      </Grid>
    </Grid>
  </ButtonBase>
);

export default LogoSection;
