import checkLocationSelected from "utils/checkLocationSelected";

export const updateForEmployerDataFormat = (res: any) => {
    return {
          name: res.data.data.company_profile.name,
          year_of_establishment:
            res.data.data.company_profile.year_of_establishment,
          employee_volume: res.data.data.company_profile.employee_volume,
          logo: res.data.data.company_profile.logo,
          industry_type: res.data.data.company_profile.industry_type.id,
          contactPersonName: res.data.data.company_profile.contact_person[0].name,
          designation:
            res.data.data.company_profile.contact_person[0].designation,
          email: res.data.data.company_profile.contact_person[0].email,
          phone: res.data.data.company_profile.contact_person[0].phone,
          username: res.data.data.username,
          password: "******",
          confirmPassword: "******",
          division: "",
          district: "",
          // subDistrict: "",
          thana: "",
          address: res.data.data.company_profile.address.id,
          contact_person_id: res.data.data.company_profile.contact_person[0].id,
    }
}

export const submitForEmployerDataFormat = (values: any, isId: boolean) => {
    const address = checkLocationSelected(values);
    let contact_person = {};
    if (isId) {
        contact_person = {
          id: values.contact_person_id,
          name: values.contactPersonName,
          designation: values.designation,
          phone: values.phone,
        };
      } else {
        contact_person = {
          name: values.contactPersonName,
          designation: values.designation,
          email: values.email,
          phone: values.phone,
        };
      }
      return {
        contact_person: contact_person,
        name: values.name,
        year_of_establishment: values.year_of_establishment,
        phone: values.phone,
        employee_volume: values.employee_volume,
        address: address[address.length - 1],
        industry_type: values.industry_type,
      };
}