import { Dispatch } from "redux";
import { CREATE_JOB, CLEAR_JOB } from "../../reducer/createJobReducer";


export const saveJob = (job: any) => (dispatch:Dispatch) => {
    dispatch({
        type: CREATE_JOB,
        payload: {
            job
        }
    });
};



export const clearJob = () => (dispatch:Dispatch) => {
    dispatch({
        type: CLEAR_JOB,
    });
};