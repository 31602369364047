const initialState = {
    name: "",
    year_of_establishment: "",
    employee_volume: "",
    logo: "" as any,
    industry_type: "",
    contactPersonName: "",
    designation: "",
    email: "",
    phone: "",
    username: "",
    password: "",
    confirmPassword: "",
    division: "",
    district: "",
    // subDistrict: "",
    thana: "",
    address: "",
    contact_person_id: "",
  }

  export default initialState;