// third-party
import { IconBrandChrome } from "@tabler/icons";
import { FormattedMessage } from "react-intl";
import langString from "utils/langString";

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

export const employer = {
  id: "employer",
  type: "group",
  children: [
    {
      id: "home-page",
      title: <FormattedMessage id={langString("homepage")} />,
      type: "item",
      url: "/employer/home",
      breadcrumbs: false,
    },
    {
      id: "profile",
      title: <FormattedMessage id={langString("profile")} />,
      type: "item",
      url: "/employer/profile",
      breadcrumbs: false,
    },
    {
      id: "post-jobs",
      title: (
        <FormattedMessage id={`${langString("post")} ${langString("jobs")}`} />
      ),
      type: "item",
      url: "/employer/post-jobs",
      breadcrumbs: false,
    },
    {
      id: "job-seekers",
      title: (
        <FormattedMessage
          id={`${langString("job")} ${langString("seekers")}`}
        />
      ),
      type: "item",
      url: "/employer/job-seekers",
      breadcrumbs: false,
    },
    {
      id: "events",
      title: <FormattedMessage id={`${langString("events")}`} />,
      type: "item",
      url: "/events",
      breadcrumbs: false,
    },
    {
      id: "feedback",
      title: <FormattedMessage id={`${langString("feedback")}`} />,
      type: "item",
      url: "/feedback",
      breadcrumbs: false,
    },
    {
      id: "messages",
      title: <FormattedMessage id={`${langString("messages")}`} />,
      type: "item",
      url: "/messages",
      breadcrumbs: false,
    },
  ],
};

export const jobSeeker = {
  id: "job-seeker",
  type: "group",
  children: [
    {
      id: "home-page",
      title: <FormattedMessage id={langString("homepage")} />,
      type: "item",
      url: "job-seeker/home",
      breadcrumbs: false,
    },
    {
      id: "profile",
      title: <FormattedMessage id={langString("profile")} />,
      type: "item",
      url: "job-seeker/profile",
      breadcrumbs: false,
    },
    {
      id: "job-market",
      title: (
        <FormattedMessage id={`${langString("find")} ${langString("jobs")}`} />
      ),
      type: "item",
      url: "job-market",
      breadcrumbs: false,
    },
    {
      id: "companies",
      title: <FormattedMessage id={langString("companies")} />,
      type: "item",
      url: "job-seeker/companies",
      breadcrumbs: false,
    },
    {
      id: "events",
      title: <FormattedMessage id={langString("events")} />,
      type: "item",
      url: "/events",
      breadcrumbs: false,
    },
    {
      id: "feedback",
      title: <FormattedMessage id={langString("feedback")} />,
      type: "item",
      url: "/feedback",
      breadcrumbs: false,
    },
    {
      id: "messages",
      title: <FormattedMessage id={langString("messages")} />,
      type: "item",
      url: "/messages",
      breadcrumbs: false,
    },
  ],
};
export const systemAdmin = {
  id: "system-admin",
  type: "group",
  children: [
    {
      id: "reports",
      title: <FormattedMessage id={langString("reports")} />,
      type: "collapse",
      icon: IconBrandChrome,
      children: [
        {
          id: "employers",
          title: <FormattedMessage id={langString("employers")} />,
          type: "item",
          url: "reports/employers",
          breadcrumbs: false,
        },
        {
          id: "jobseekers",
          title: <FormattedMessage id={langString("jobseekers")} />,
          type: "item",
          url: "reports/jobseekers",
          breadcrumbs: false,
        },
      ],
    },
  ],
};
