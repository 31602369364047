import axios from "axios";
import { Dispatch } from "redux";
import { CONFIG } from "store/reducer/configReducer";

export const config = () => async (dispatch: Dispatch) => {
  try {
    const allConfigData = await axios.all([
      axios.get(`${process.env.REACT_APP_SERVICE_URL}settings/locations/`),
      axios.get(`${process.env.REACT_APP_SERVICE_URL}settings/industries/`),
      axios.get(`${process.env.REACT_APP_SERVICE_URL}settings/degree-types/`),
      axios.get(`${process.env.REACT_APP_SERVICE_URL}settings/degree-groups/`),
      axios.get(`${process.env.REACT_APP_SERVICE_URL}settings/personal-skills/`),
      axios.get(`${process.env.REACT_APP_SERVICE_URL}settings/technical-skills/`),
      axios.get(`${process.env.REACT_APP_SERVICE_URL}settings/yes-courses/`),
      axios.get(`${process.env.REACT_APP_SERVICE_URL}settings/yes-trainings/`),
      axios.get(`${process.env.REACT_APP_SERVICE_URL}jobs/job-types/`),
      axios.get(`${process.env.REACT_APP_SERVICE_URL}jobs/job-categories/`),
      axios.get(`${process.env.REACT_APP_SERVICE_URL}settings/companies/`),
    ]);
    dispatch({
      type: CONFIG,
      payload: {
        location: allConfigData[0]?.data?.data,
        industries: allConfigData[1]?.data?.data,
        degreeType: allConfigData[2]?.data?.data,
        degreeGroups: allConfigData[3]?.data?.data,
        personalSkills: allConfigData[4]?.data?.data,
        technicalSkills: allConfigData[5]?.data?.data,
        yesCourses: allConfigData[6]?.data?.data,
        yesTrainings: allConfigData[7]?.data?.data,
        jobType: allConfigData[8]?.data?.data,
        jobCategory: allConfigData[9]?.data?.data,
        company: allConfigData[10]?.data?.data,
      },
    });
  } catch (e) {
    console.log(e);
  }
};
