import { Button, Grid, Modal, Stack, Typography } from "@mui/material";
import Badges from "components/Badges";
import Recommendations from "components/Card/Recommendations";
import Skills from "components/Card/Skills";
import PersonalDetails from "components/PersonalDetails";
import Table, { dataT } from "components/Table";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AnimateButton from "ui-component/extended/AnimateButton";
import coreAxios from "utils/axios";
import langString from "utils/langString";
import { Body } from "./Body";
import {
  educationsCol,
  experienceCol,
  otherCertificationsCol,
  yesCertificationCol
} from "./columns";
import { personalDetail } from "./defaultData";
import { getJobSeekerData } from "./getJobSeekerData";

// type
export type personalDetailsT = {
  picture: string;
  name: string;
  age: string;
  gender: string;
  phone: string;
  email: string;
};

export type recommendationsT = {
  id: number;
  message: string;
  view_in_cv: boolean;
  view_in_profile: boolean;
  name: string;
  position: string;
  company: string;
  year: string;
};

type skillT = {
  id: number;
  name: string;
};

export default function EmployerSeeJobSeekerProfile() {
  const [open, setOpen] = useState(false);
  const [recommendation, setRecommendation] = useState<string>("");
  const [personalDetails, setPersonalDetails] = useState<personalDetailsT>(
    personalDetail
  );
  const [recommendations, setRecommendations] = useState<recommendationsT[]>(
    []
  );
  const [personalSkills, setPersonalSkills] = useState<skillT[]>([]);
  const [technicalSkills, setTechnicalSkills] = useState<skillT[]>([]);
  const [educationsRaw, setEducationsRaw] = useState<dataT[]>([]);
  const [yesCertificationRaw, setYesCertificationRaw] = useState<dataT[]>([]);
  const [otherCertificationsRaw, setOtherCertificationsRaw] = useState<dataT[]>(
    []
  );
  const [experienceRaw, setExperienceRaw] = useState<dataT[]>([]);
  const [badges, setBadges] = useState<any[]>([]);
  const { jobSeekerId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getJobSeekerData(
      jobSeekerId,
      setPersonalDetails,
      setRecommendations,
      setPersonalSkills,
      setTechnicalSkills,
      setEducationsRaw,
      setYesCertificationRaw,
      setOtherCertificationsRaw,
      setExperienceRaw,
      setBadges
    );
  }, [jobSeekerId]);

  // columns table
  const educationsColumns = React.useMemo(() => educationsCol, []);
  const yesCertificationColumns = React.useMemo(() => yesCertificationCol, []);
  const otherCertificationsColumns = React.useMemo(
    () => otherCertificationsCol,
    []
  );
  const experienceColumns = React.useMemo(() => experienceCol, []);

  // raw table
  const educationsData = React.useMemo(() => educationsRaw, [educationsRaw]);
  const yesCertificationData = React.useMemo(() => yesCertificationRaw, [
    yesCertificationRaw,
  ]);
  const otherCertificationsData = React.useMemo(() => otherCertificationsRaw, [
    otherCertificationsRaw,
  ]);
  const experienceData = React.useMemo(() => experienceRaw, [experienceRaw]);

  // modal open and close
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // send message
  const sendMessage = async () => {
    try {
      const res = await coreAxios.post(
        `/message-management/windows/job-seeker/${jobSeekerId}/`,
        {}
      );
      if (res.status === 200) {
        navigate(`/messages/chat/?windowId=${res.data.data.id}`);
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <Typography variant="h2">{langString("JOBSEEKERPROFILE")}</Typography>
      <Grid container xs={12} spacing={3}>
        <Grid item xs={12} sm={8}>
          <PersonalDetails personalDetails={personalDetails} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Badges badges={badges}/>
        </Grid>
        <Grid item xs={12}>
          <Recommendations recommendations={recommendations} />
        </Grid>
        <Grid item xs={12}>
          <Table
            columns={educationsColumns}
            data={educationsData}
            title={langString("Education")}
          />
        </Grid>
        <Grid item xs={12}>
          <Skills
            title={langString("Skills")}
            personalSkills={personalSkills}
            technicalSkills={technicalSkills}
          />
        </Grid>
        <Grid item xs={12}>
          <Table
            columns={yesCertificationColumns}
            data={yesCertificationData}
            title={langString("YesCertifications")}
          />
        </Grid>
        <Grid item xs={12}>
          <Table
            columns={otherCertificationsColumns}
            data={otherCertificationsData}
            title={langString("OtherCertifications")}
          />
        </Grid>
        <Grid item xs={12}>
          <Table
            columns={experienceColumns}
            data={experienceData}
            title={langString("Experience")}
          />
        </Grid>

        <Grid container item xs={12} justifyContent="center" spacing={3}>
          <Grid item xs={12} sm={3}>
            <Stack direction="column">
              <AnimateButton>
                <Button
                  variant="contained"
                  type="button"
                  onClick={() => {
                    sendMessage();
                  }}
                >
                  {langString("SendMessage")}
                </Button>
              </AnimateButton>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Stack direction="column">
              <AnimateButton>
                <Button variant="contained" type="button" onClick={handleOpen}>
                  {langString("GiveRecommendation")}
                </Button>
              </AnimateButton>
            </Stack>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <Body
                dispatch={dispatch}
                handleClose={handleClose}
                navigate={navigate}
                recommendation={recommendation}
                personalDetails={personalDetails}
                setRecommendation={setRecommendation}
                jobSeekerId={jobSeekerId!}
                setRecommendations={setRecommendations}
              />
            </Modal>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
