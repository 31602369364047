import { Dispatch } from "redux";
import { SNACKBAR_OPEN } from "store/actions";
import coreAxios from "utils/axios";
import { eventT } from ".";

const handleOnSubmit = async (
  values: any,
  events: eventT[],
  setEvents: any,
  setEvent: any,
  handleClose: any,
  dispatch: Dispatch<any>
) => {
  try {
    if (values.id !== 0) {
      // update event
      const res = await coreAxios.patch(`/calendar/events/${values.id}/`, {
        id: values.id,
        start_datetime: `${values.date}T${values.fromTime}`,
        end_datetime: `${values.date}T${values.toTime}`,
        message: values.message,
      });

      if (res.status === 200) {
        const items = [...events];
        const itemIndex = items.findIndex(
          (value) => value.id === res.data.data.id
        );
        items[itemIndex] = {
          id: res.data.data.id,
          start_datetime: res.data.data.start_datetime,
          end_datetime: res.data.data.end_datetime,
          message: res.data.data.message,
        };
        setEvents(items);
        handleClose();
        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: "Update event successful",
          variant: "alert",
          alertSeverity: "success",
        });
      }
    } else {
      // create event
      const res = await coreAxios.post(`/calendar/events/`, {
        start_datetime: `${values.date}T${values.fromTime}`,
        end_datetime: `${values.date}T${values.toTime}`,
        message: values.message,
      });
      if (res.status === 201) {
        setEvents((prevStatus: any) => [
          ...prevStatus,
          {
            id: res.data.data.id,
            start_datetime: res.data.data.start_datetime,
            end_datetime: res.data.data.end_datetime,
            message: res.data.data.message,
          },
        ]);
        handleClose();
        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: "Created event successful",
          variant: "alert",
          alertSeverity: "success",
        });
      }
    }
  } catch (error: any) {
    if (error.response) {
      const message = JSON.parse(error.response.data.data).errorMessage;
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message,
        variant: "alert",
        alertSeverity: "error",
      });
    }
  }
};

export default handleOnSubmit;
