import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "store";
import MainCard from "ui-component/cards/MainCard";
import langString from "utils/langString";
import useStyle from "./style";

const TrainingOrCourse = ({ formik }: any) => {
  const classes = useStyle();
  const { yesCourses, yesTrainings } = useSelector(
    (state: RootState) => state.config
  );

  return (
    <>
      <Grid item xs={12} sm={6}>
        <MainCard className={classes.h}>
          <Typography variant="h4" gutterBottom>
            {langString("YESTraining/CourseRequirement")}
          </Typography>

          <Grid item>
            <Autocomplete
              multiple
              id="yes_trainings"
              size="small"
              options={yesTrainings ? yesTrainings : []}
              getOptionLabel={(option) => option.name}
              onChange={(e, values) =>
                formik?.setFieldValue("yes_trainings", values)
              }
              value={formik?.values?.yes_trainings}
              renderInput={(params) => (
                <TextField
                  sx={{ m: 1 }}
                  {...params}
                  variant="outlined"
                  label={langString("Trainings")}
                  placeholder={langString("Trainings")}
                  name="yes_trainings"
                />
              )}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              multiple
              id="yes_courses"
              size="small"
              options={yesCourses ? yesCourses : []}
              getOptionLabel={(option) => option.name}
              onChange={(e, values) =>
                formik?.setFieldValue("yes_courses", values)
              }
              value={formik?.values?.yes_courses}
              renderInput={(params) => (
                <TextField
                  sx={{ m: 1 }}
                  {...params}
                  variant="outlined"
                  label={langString("Courses")}
                  placeholder={langString("Courses")}
                  name="yes_courses"
                />
              )}
            />
          </Grid>
        </MainCard>
      </Grid>
    </>
  );
};

export default TrainingOrCourse;
