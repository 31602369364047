export const CONFIG = "CONFIG";

type configT = typeof CONFIG;

interface configPayloadI {
  location?: [];
  industries?: [];
  degreeGroups?: [];
  degreeType?: [];
  personalSkills?: [];
  technicalSkills?: [];
  yesCourses?: [];
  yesTrainings?: [];
  jobType?: [];
  jobCategory?: [];
  company?: [];
}

export interface configReducerActionProps {
  type: configT;
  payload?: configPayloadI;
}

const initialState: configPayloadI = {
  location: [],
  industries: [],
  degreeGroups: [],
  degreeType: [],
  personalSkills: [],
  technicalSkills: [],
  yesCourses: [],
  yesTrainings: [],
  jobType: [],
  jobCategory: [],
  company: [],
};

const configReducer = (
  state = initialState,
  action: configReducerActionProps
) => {
  switch (action.type) {
    case CONFIG: {
      const {
        location,
        industries,
        degreeType,
        degreeGroups,
        personalSkills,
        technicalSkills,
        yesCourses,
        yesTrainings,
        jobType,
        jobCategory,
        company,
      } = action.payload!;
      return {
        ...state,
        location,
        industries,
        degreeType,
        degreeGroups,
        personalSkills,
        technicalSkills,
        yesCourses,
        yesTrainings,
        jobType,
        jobCategory,
        company,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default configReducer;
